import React, {createRef, useEffect, useState} from 'react';
import '../../css/index.css';
import Breadcrumb from "../../components/menus/Breadcrumb";
import {Accordion, AccordionContainer} from "../../components/widgets";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import NoticeDialog1 from "../../components/templates/namyang/CommunicationManagement/NoticeDialog1";
import {useDispatch, useSelector} from "react-redux";
import {ImageAddButton, ImageAddButtonWrap} from "../../components/button";
import {DetailContentBox} from "../../components/detail";
import arrayMove from "array-move";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {loadingStatus} from "../../redux/modules/loading";
import {callApi} from "../../api";
import styled from "styled-components";
import {ImgLoad} from "../../components/icons/ImgLoad"

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideBox from "../../components/banner/SlideBox";

import {InputBox, InputText} from '../../components/inputs';

// 이미지 드래그 스타일
const SortableListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 10px);
    
     @media (max-width: 720px) {    
        width: 100%;
        
        & > div:nth-child(even) {
            margin: 0 0 20px 10px; 
        }
        
        & > div:nth-child(odd) {
            margin: 0 10px 20px 0; 
        }
    }
`;

const SortableItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    user-select: none;
    
    position: relative;
    margin: 10px;
    
    &:before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    width: calc(33.33% - 20px);

    @media (max-width: 720px) {    
        width: calc(50% - 20px);
    }

    @media (max-width: 576px) {    
        width: 100%;
        margin: 10px 0 !important;
    }
`;


const breadcrumbData = [
    {
        title: "배너",
        link: ""
    }
];


const Banner = (props) => {

    const dispatch = useDispatch();

    const modal = useSelector(state => state.modal, []);

    const [leftBanner, setLeftBanner] = useState([]);
    const [rightBanner, setRightBanner] = useState([]);
    const [centerBanner, setCenterBanner] = useState([]);
    const [linkText, setLinkText] = useState({});

    useEffect(() => {

        callBanner();

    }, []);

    const callBanner = () => {
        dispatch(loadingStatus(true));

        callApi('/banner', 'GET', {}).then((res) => {

            if (res.success) {
                const left = res.list.filter((list) => list.type === 'left');
                const right = res.list.filter((list) => list.type === 'youtube');
                const centerBanner = res.list.filter((list) => list.type === 'center');

                let links = [];
                res.list.forEach((item) => {
                    links[item.id] = item.link;
                });
                setLeftBanner(left);
                setRightBanner(right);
                setCenterBanner(centerBanner);
                setLinkText(links);
            }
            dispatch(loadingStatus(false));
        }).catch((msg) => {
            console.log(msg);
            dispatch(loadingStatus(false));
        });
    }

    const [imageList, setImageList] = useState([]);

    // 이미지 드래그 (Sortable)
    const onSortStart = () => {
        document.body.style.cursor = 'grabbing';
    };

    const onSortEnd = (oldIndex) => {
        document.body.style.cursor = 'default';
    };

    const shouldCancelStart = (event) => {
        // 메뉴 버튼 클릭 시 드래그 이벤트 취소
        if ((event.target.className).indexOf('sortablemenu') !== -1) {
            return true;
        }

        // 메뉴 수정, 삭제 버튼 클릭 시 드래그 이벤트 취소
        if ((event.target.className).indexOf('menu-item') !== -1) {
            return true;
        }
    };

    // 이미지 파일 form
    // this.inputOpenFileRef = React.createRef()
    const [inputOpenFile1, setInputOpenFile1] = useState(() => createRef());
    const [inputOpenFile2, setInputOpenFile2] = useState(() => createRef());
    const [inputOpenFile3, setInputOpenFile3] = useState(() => createRef());
    const [fileMod, setFileMod] = useState(() => createRef());
    const [fileModId, setFileModId] = useState(null);
    // 이미지 추가
    const handleClickImageAdd1 = () => {
        inputOpenFile1.current.click();
    };
    const handleClickImageAdd2 = () => {
        inputOpenFile2.current.click();
    };
    const handleClickImageAdd3 = () => {
        inputOpenFile3.current.click();
    };
    const fileModClick = (id) => {
        setFileModId(id);
        fileMod.current.click();
    };

    const changeLink = (e, listId) => {
        let value = e.target.value;
        setLinkText((prevState) => {
            return {...prevState, [listId]: value}
        });
    };

    const fileDelClick = (id) => {
        dispatch(loadingStatus(true));

        callApi('/banner/' + id, 'DELETE', {}, true).then((res) => {
            callBanner();
            dispatch(loadingStatus(false));
        }).catch((err) => {
            dispatch(loadingStatus(false));
            console.log(err);
        });
    };


    // 이미지 파일 업로드
    const handleChangeImageFile = (event, type) => {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.files.length > 0) {
            dispatch(loadingStatus(true));
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('type', type);

            callApi('/banner', 'POST', formData, true).then((res) => {
                callBanner();

                dispatch(loadingStatus(false));
            }).catch((err) => {
                dispatch(loadingStatus(false));
                console.log(err);
            });
        }
    };

    const modImageFile = (event) => {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.files.length > 0) {
            dispatch(loadingStatus(true));
            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            callApi('/banner/' + fileModId, 'POST', formData, true).then((res) => {
                callBanner();

                dispatch(loadingStatus(false));
            }).catch((err) => {
                dispatch(loadingStatus(false));
                console.log(err);
            });
        }
    };

    const modLink = (event, modId) => {

        event.stopPropagation();
        event.preventDefault();

        if (linkText[modId] !== undefined) {
            dispatch(loadingStatus(true));
            const formData = new FormData();
            formData.append('link', linkText[modId]);

            callApi('/banner/' + modId + '/link', 'POST', formData, true).then((res) => {
                callBanner();

                dispatch(loadingStatus(false));
            }).catch((err) => {
                dispatch(loadingStatus(false));
                console.log(err);
            });
        }
    };

    const handleClickMenu = (event) => {
        // setShowMenu(showMenu ? false : true);

        // 열려있는 메뉴 닫기
        // let allShowClass = document.getElementsByClassName('showmenu');
        // document.getElementsByClassName('showmenu').style.display = "none"
        // let length = allShowClass.length;
        // document.getElementById(event.target.id).style.display = "flex";

        // let clickId = event.target.id.replace('sortablemenu', 'showmenu');
        // for (let i = 0; i < length; i++) {
        //
        //     if (allShowClass[i].id === clickId) {
        //         // 열려있는 메뉴 클릭 시 닫기
        //         if (document.getElementById(clickId).style.display === 'flex') {
        //             allShowClass[i].style.display = "none";
        //         } else {
        //             document.getElementById(clickId).style.display = "flex";
        //         }
        //     } else {
        //         allShowClass[i].style.display = "none";
        //     }
        // }
    };

    const PrevArrowButton = (props) => {
        const {className, style, onClick} = props;
        return (
            <div className={className} style={{...style, left: 25, zIndex: 2}} onClick={onClick}/>
        );
    };

    const NextArrowButton = (props) => {
        const {className, style, onClick} = props;
        return (
            <div className={className} style={{...style, right: 25, zIndex: 2}} onClick={onClick}/>
        );
    };

    const slideSettings = {
        infinite: true,
        speed: 500,
        // autoplay: true,
        // autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (idx) => {
        },
        beforeChange: (current, next) => {
        },
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    };

    return (
        <DetailContentBox>
            <Breadcrumb data={breadcrumbData}/>
            <AccordionContainer>
                <Accordion title="배너관리" boxCount={1}>
                    <DetailContentBox style={{display: 'flex'}}>
                        <div style={{width: '100%'}}>
                            <div className="title">상단 배너 추가</div>
                            <ImageAddButtonWrap>
                                <input ref={inputOpenFile3} type="file" style={{display: "none"}}
                                       onChange={(e) => handleChangeImageFile(e, 'center')}/>
                                <ImageAddButton style={{width: 200}} onClick={handleClickImageAdd3}/>
                            </ImageAddButtonWrap>
                        </div>
                    </DetailContentBox>
                    <DetailContentBox style={{display: 'flex'}}>
                        <div style={{width: '60%'}}>
                            <div className="title">왼쪽 배너 추가</div>
                            <ImageAddButtonWrap>
                                <input ref={inputOpenFile1} type="file" style={{display: "none"}}
                                       onChange={(e) => handleChangeImageFile(e, 'left')}/>
                                <ImageAddButton style={{width: 200}} onClick={handleClickImageAdd1}/>
                            </ImageAddButtonWrap>
                        </div>
                        <div style={{width: '40%'}}>
                            <div className="title">유튜브 배너</div>
                            <div>
                                아래에 유튜브 링크를 입력하세요.
                            </div>
                            {/*<ImageAddButtonWrap>*/}
                            {/*    <input ref={inputOpenFile2} type="file" style={{display: "none"}}*/}
                            {/*           onChange={(e) => handleChangeImageFile(e, 'right')}/>*/}
                            {/*    <ImageAddButton style={{width: 200}} onClick={handleClickImageAdd2}/>*/}
                            {/*</ImageAddButtonWrap>*/}
                        </div>
                    </DetailContentBox>
                    <CenterBanner>
                        <StyledSlider3 {...slideSettings}>
                            {centerBanner.map((list, idx) => {
                                return (
                                    <div key={list.id}>
                                        <SlideBox id={list.id} idx={idx} total={centerBanner.length}
                                                  imgStyle={{objectFit:'cover', maxHeight: 300}}
                                                  image_url={list.image_url}
                                                  handleClickMenu={handleClickMenu} fileModClick={fileModClick}
                                                  fileDelClick={fileDelClick}/>
                                        <InputBox title={'링크 URL'} btnTitle={'적용'} click={(e) => modLink(e, list.id)}>
                                            <input type={'text'} name={list.id} value={linkText[list.id] ?? ""}
                                                   onChange={(e) => changeLink(e, list.id)}/>
                                        </InputBox>
                                    </div>
                                )
                            })}
                        </StyledSlider3>
                    </CenterBanner>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <StyledSlider1 {...slideSettings}>
                            {leftBanner.map((list, idx) => {
                                return (
                                    <div key={list.id}>
                                        <SlideBox id={list.id} idx={idx} total={leftBanner.length}
                                                  imgStyle={{width: '100%', maxHeight: 200, objectFit:'cover'}}
                                                  image_url={list.image_url}
                                                  handleClickMenu={handleClickMenu} fileModClick={fileModClick}
                                                  fileDelClick={fileDelClick}/>
                                        <InputBox title={'링크 URL'} btnTitle={'적용'} click={(e) => modLink(e, list.id)}>
                                            <input type={'text'} name={list.id} value={linkText[list.id] ?? ""}
                                                   onChange={(e) => changeLink(e, list.id)}/>
                                        </InputBox>
                                    </div>
                                )
                            })}
                        </StyledSlider1>
                        <StyledSlider2 {...slideSettings}>
                            {rightBanner.map((list, idx) => {
                                return (
                                    <div key={list.id}>
                                        {/*<SlideBox key={list.id} id={list.id} idx={idx} total={rightBanner.length}*/}
                                        {/*          image_url={list.image_url}*/}
                                        {/*          handleClickMenu={handleClickMenu} fileModClick={fileModClick}*/}
                                        {/*          fileDelClick={fileDelClick}/>*/}
                                        <InputBox title={'유튜브 URL'} btnTitle={'적용'} click={(e) => modLink(e, list.id)}>
                                            <input type={'text'} name={list.id} value={linkText[list.id] ?? ""}
                                                   onChange={(e) => changeLink(e, list.id)}/>
                                        </InputBox>
                                    </div>
                                )
                            })}
                        </StyledSlider2>
                    </div>
                    <input ref={fileMod} type="file" style={{display: "none"}}
                           onChange={modImageFile}/>
                </Accordion>
            </AccordionContainer>
        </DetailContentBox>
    );
};

const CenterBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px auto;

`;

const StyledSlider1 = styled(Slider)`
                            width: 50%;
                            height: 100%;
                            padding: 5px;
                            `;
const StyledSlider2 = styled.div`
                            width: 50%;
                            height: 100%;
                            padding: 5px;
                            `;

const StyledSlider3 = styled(Slider)`
                            width: 100%;
                            height: 100%;
                            padding: 5px;
                            `;

export default Banner;