import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {InputBox, InputText} from '../components/inputs';
import {MemberWrap} from '../components/member';
import {callApi} from "../api";
import {Cookie} from "../common/Cookie";

const Login = (props) => {

    const [userId, setUserId] = useState(Cookie.get('userId'));
    const [password, setPassword] = useState('');

    // 아이디 저장
    const [isRemember, setIsRemember] = useState(false);
    // 자동로그인 저장
    const [isAllow, setIsAllow] = useState(false);

    const handleChangeAllow = () => {

    }

    const handleClickLogin = () => {

        if (isRemember) {
            Cookie.set('userId', userId, {maxAge: 8640000}); // 100일
        } else {
            Cookie.remove('userId');
        }

        if (userId !== '' && password.length > 3) {
            const request = {
                email: userId,
                password: password
            };

            callApi('/member/login', 'POST', request).then((res) => {
                Cookie.set('token', res.token, {maxAge: 8640000});
                Cookie.set('admin_user', {userId: res.user_id, userName: res.user_name}, {maxAge: 8640000});

                window.location.href="/";
            }).catch((err) => {
                console.log(err);
            });
        } else {
            alert('아이디와 비밀번호 4자리 이상 입력하세요.');
            return false;
        }

        console.log('Login');
    }

    const handleClickGoBack = () => {
        props.history.goBack();
    }


    useEffect(() => {
        if (Cookie.get('userId') !== undefined) {
            // cookies.set('userId', userId, {maxAge: 8640000})
            setIsRemember(true);
            // passwordInput.current.focus();
        }
    }, []);

    const handleChangeSave = (event) => {
        setIsRemember(event.target.checked);
        if (event.target.checked) {
            Cookie.set('userId', userId, {maxAge: 8640000}); // 100일
        } else {
            Cookie.remove('userId');
        }
    }

    const pwdChange = (event) => {
        setPassword(event.target.value);
    }

    // password input 참조
    const passwordInput = useRef(null);

    return (
        <div style={{
            position: "fixed",
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <MemberWrap>
                <h2>로그인</h2>
                <StyledHr/>
                <InputBox title="아이디" firstBox>
                    <InputText
                        inputValue={userId}
                        onChange={setUserId}
                    />
                </InputBox>
                <InputBox title="비밀번호">
                    <StyledPasswordInput type="password" value={password} onChange={pwdChange}
                                         ref={passwordInput}/>
                </InputBox>
                <StyledOptionBox>
                    <StyledNumberSave>
                        <label>
                            <input type="checkbox" onChange={handleChangeSave} checked={isRemember}/>
                            <span>
                                <svg width="18px" height="13px">
                                    <image x="0px" y="0px" width="18px" height="13px"
                                           xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRERs/R464AAAAUElEQVQY04XOQQ7AIAhE0c+k9z9IL0kX1kgUhJWYB4y9XMsB1JM78vlQTzD1BJ6/tZqsc16TmMkrAgqHPCdjkx3btoxKYh+9ku99JAS3isAHXFsOyLbVnkkAAAAASUVORK5CYII="/>
                                </svg>
                                <svg width="18px" height="13px">
                                    <image x="0px" y="0px" width="18px" height="13px"
                                           xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRFCGEPKNPAAAASklEQVQY04XOQQ7AMAgDwTXK/7/sXtoGpQW4IY20lmnPADGTHr2RmAmKmcC6X9Vk51yTvMkVgUgh/xOQz9iHPDl1ZG9STfJwVQQulQsQGuIqCVIAAAAASUVORK5CYII="/>
                                </svg>
                            </span>
                            아이디 저장

                        </label>
                        {/*<label>*/}
                        {/*    <input type="checkbox" onChange={handleChangeAllow} checked={isAllow}/>*/}
                        {/*    <span>*/}
                        {/*        <svg width="18px" height="13px">*/}
                        {/*            <image x="0px" y="0px" width="18px" height="13px"*/}
                        {/*                   xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRERs/R464AAAAUElEQVQY04XOQQ7AIAhE0c+k9z9IL0kX1kgUhJWYB4y9XMsB1JM78vlQTzD1BJ6/tZqsc16TmMkrAgqHPCdjkx3btoxKYh+9ku99JAS3isAHXFsOyLbVnkkAAAAASUVORK5CYII="/>*/}
                        {/*        </svg>*/}
                        {/*        <svg width="18px" height="13px">*/}
                        {/*            <image x="0px" y="0px" width="18px" height="13px"*/}
                        {/*                   xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRFCGEPKNPAAAASklEQVQY04XOQQ7AMAgDwTXK/7/sXtoGpQW4IY20lmnPADGTHr2RmAmKmcC6X9Vk51yTvMkVgUgh/xOQz9iHPDl1ZG9STfJwVQQulQsQGuIqCVIAAAAASUVORK5CYII="/>*/}
                        {/*        </svg>*/}
                        {/*    </span>*/}
                        {/*    자동로그인*/}
                        {/*</label>*/}
                    </StyledNumberSave>
                </StyledOptionBox>
                <div>
                    <StyledButton onClick={handleClickLogin}>로그인</StyledButton>
                </div>
            </MemberWrap>
        </div>
    );
}

const StyledOptionBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
`;

const StyledPasswordInput = styled.input`
    width: 50%;
    border: none;
    outline: none;
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #5fc148;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
`;

const StyledHr = styled.hr`
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #ccc;
    margin: 30px 0;
    padding: 0;
`;

const StyledJoin = styled.div`
    font-size: 18px;
    color: #5fc148;
    
    span {
        border-bottom: 1px solid #5fc148;
    }
`;

const StyledPasswordSearch = styled.div`
    font-size: 18px;
    color: #000;
    text-align: right;
    
    span {
        border-bottom: 1px solid #000;
    }
`;

const StyledNumberSave = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    label {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 30px;
        position: relative;
        padding-left: 35px;
        user-select: none;
        cursor: pointer;
        margin-right:20px;
        
        input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }
        
        input:checked ~ span {
            background-color: #5fc148;
        }
        
        input:checked ~ span > svg:last-child {
            display: block;
        }
        
        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background-color: #dfdfdf;
        }
        
        span > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        span > svg:last-child {
            display: none;
        }
    }
`;

export default Login;