import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledIconButton = styled(Button)`
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 60px;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    
    &:hover {
        background-color: #f2f2f2;
    }
`;

const StyledIcon = styled.img`
    margin-right: 10px;
`

const IconButton = (props) => {
    const iconList = {
        'handshake': {
            'src': '001.png',
            'width': '30px'
        },
        'lock': {
            'src': '030.png',
            'width': '20px'
        }
    };

    const iconPath = '/images/icon/' + iconList[props.icon].src;
    const iconWidth = iconList[props.icon].width;

    return (
        <StyledIconButton>
            <StyledIcon src={iconPath} width={iconWidth} /> {props.title}
        </StyledIconButton>
    );
};

IconButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.oneOf(['handshake', 'lock']),
};

export default IconButton;