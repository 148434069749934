import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import Sortable from '../interactions/Sortable';
import DragInfoText from '../texts/DragInfoText';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "JSON 코드",
        link: ""
    }
];

const AccordionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "A",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "B",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

const codeNameList = [
    {
        id: 1,
        order: 1,
        title: "A-1",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "A-2",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "A-3",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "A-4",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

// JSON 코드
const Template4 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" boxCount={2}>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="JSON 코드이름" boxCount={2}>
                    <Select optionList={firstCategory} />
                    <AddButton title="JSON 코드추가" />
                    <Sortable itemList={codeNameList} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template4;