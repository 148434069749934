import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../menus/Breadcrumb';
import {Accordion, AccordionContainer} from '../../../widgets';
import {AddButton} from '../../../button';
import {Select} from '../../../inputs';
import Sortable from '../../../interactions/Sortable';
import DragInfoText from '../../../texts/DragInfoText';
import {useDispatch} from "react-redux";
import {actionCreator as modalAction} from "../../../../redux/modules/modal";
import BasicCodeDialog1 from "./BasicCodeDialog1";
import {callApi} from "../../../../api";
import {actionCreator as menuAction} from "../../../../redux/modules/menus";
import {actionCreator as userAction} from "../../../../redux/modules/user";

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "기초코드",
        link: ""
    }
];

const firstCategory11 = [
    {
        id: 1,
        order: 1,
        title: "직급",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "부서",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "진행상황",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "재직여부",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 5,
        order: 5,
        title: "경고문구",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

const codeNameList = [
    {
        id: 1,
        order: 1,
        title: "기획팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "운영팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "개발팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "디자인팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 5,
        order: 5,
        title: "영업팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 6,
        order: 6,
        title: "촬영팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

// 기초코드
const BasicCode = () => {

    const dispatch = useDispatch();

    const [firstCategory, setFirstCategory] = useState([]);
    const [codeNameList, setCodeNameList] = useState([]);
    const [defaultValue, setDefaultValue] = useState(firstCategory.length > 0 ? firstCategory[0].id : 0);

    useEffect(() => {
        callApi('/default/basic_code', 'GET', [], '').then((res) => {
            setFirstCategory(res.defaultCode);
        });

        return () => {
            console.log('cleanup');
            console.log(firstCategory);
        };
    }, []);

    // 1차 분류 셀렉트 입력시 하위 기초코드 가져오기
    const onChange = (item) => {

        callApi('/default/basic_code', 'GET', [], '').then((res) => {

        });

        setDefaultValue(item);
    }

    // 1차 분류 인설트
    const addCode1 = (props) => {
        console.log(props);
    }


    // 1차 분류 입력창 모달 팝업
    const showModal = () => {
        dispatch(modalAction.addModal({
            id: 'basiccode1',
            title: '1차 분류 추가',
            component: <BasicCodeDialog1 onClick={addCode1}/>
        }));
    }


    return (
        <div>
            <Breadcrumb data={breadcrumbData}/>
            <AccordionContainer>
                <Accordion title="1차 분류" boxCount={2}>
                    <AddButton title="1차 분류추가" onClick={showModal}/>
                    {
                        firstCategory.length > 0 && (
                            <Sortable itemList={firstCategory}/>
                        )
                    }
                    <DragInfoText/>
                </Accordion>

                <Accordion title="코드이름" boxCount={2}>
                    <Select defaultValue={defaultValue} optionList={firstCategory} onChange={onChange}/>
                    <AddButton title="코드추가"/>
                    {
                        codeNameList.length > 0 && (
                            <Sortable itemList={codeNameList}/>
                        )
                    }
                    <DragInfoText/>
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default BasicCode;