import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledToggle = styled.label`
    position: relative;
    display: inline-block;
    width: 70px;
    height: 38px;
    
    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    & span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fcebeb;
        transition: 0.4s;
        border-radius: 34px;
        color: #fff;
    }
    
    & span:before {
        position: absolute;
        content: ${props => props.toggleOffText};
        width: 30px;
        height: 30px;
        left: 4px;
        bottom: 4px;
        background-color: #f44336;
        transition: 0.4s;
        border-radius: 50%;
        transform: translateX(30px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    & input:checked + span {
        background-color: #ebf7fc;
    }
    
    & input:checked + span:before {
        content: ${props => props.toggleOnText};
        transform: translateX(0);
        background-color: #03a9f4;
        color: #fff;
    }
`;

const InputToggle = (props) => {

    const handleChange = () => {
        // turn off
        props.toggleChecked ? props.onChange(false) : props.onChange(true);
    };

    return (
        <StyledToggle toggleOnText={props.toggleOnText} toggleOffText={props.toggleOffText}>
            <input type="checkbox" checked={props.toggleChecked} onChange={handleChange}/>
            <span></span>
        </StyledToggle>
    );
};

InputToggle.propTypes = {
    toggleOnText: PropTypes.string,
    toggleOffText: PropTypes.string,
    toggleChecked: PropTypes.bool,
    onChange: PropTypes.func
};

InputToggle.defaultProps = {
    toggleOnText: "'on'",
    toggleOffText: "'off'",
    toggleChecked: false
};

export default InputToggle;