import React, {useState} from "react";
import styled from "styled-components";

const SortableItemInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: grab;
    z-index:5;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`;

const SortableMenuButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    
    & span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3e475c;
        display: inline-block;
        margin: 0 1.5px;
        z-index: 3;
        pointer-events: none;
    }
`;

const SortableMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50px;
    right: 20px;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    
    & .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      z-index: 5;
      user-select: none;
    }
    
    & .menu-item:hover {
      background-color: #dfdfdf !important;
    }
`;

const SlideBox = (props) => {
    const [menu, setMenu] = useState(0);

    const showMenu = (event) => {
        if (menu) {
            setMenu(0);
        } else {
            setMenu(1);
        }
    };

    return (
        <div className="slide-wrap" key={props.id}
             style={{position: 'relative', width: '100%'}}>
            <div style={{
                position: 'absolute',
                margin: 5,
                zIndex: 2,
            }}>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#F0F0F0',
                    opacity: 0.5,
                    borderRadius: '10px',
                }}></div>
                <div style={{padding: '5px 10px 5px 10px', color: '#FFF', fontSize: 12}}>
                    {(props.idx + 1)} / {props.total}
                </div>
            </div>
            <SortableItemInner>
                <SortableMenuButton onClick={showMenu}
                                    className="sortablemenu" id={"sortablemenu" + props.id}>
                    <span></span>
                    <span></span>
                    <span></span>
                </SortableMenuButton>
                {menu ? (
                    <SortableMenu className="showmenu" id={"showmenu" + props.id}>
                        <div className="menu-item"
                             onClick={() => props.fileModClick(props.id)}>수정
                        </div>
                        <div className="menu-item"
                             onClick={() => props.fileDelClick(props.id)}>삭제
                        </div>
                    </SortableMenu>
                ) : null}
            </SortableItemInner>
            <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <img style={props.imgStyle}
                     src={props.image_url}/>
            </div>
        </div>
    );
}

export default SlideBox;