import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const StyledBreadcrumb = styled.div`
    padding: 0 10px;  
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    line-height: 11px;
    
    & > div {
        margin-left: 17px;
        transition: 0.4s;
    }
    
    & a {
        color: inherit;
        text-decoration: none;
    }
    
    @media (max-width: 576px) {
        & > div {
            margin-left: 5px;
        }
    }
`;

const Arrow = () => {
    return (
        <svg
            width="11px" height="17px">
            <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                  d="M10.153,8.568 L2.375,16.346 L0.961,14.932 L7.642,8.250 L0.961,1.567 L2.375,0.153 L10.153,7.931 L9.835,8.250 L10.153,8.568 Z"/>
        </svg>
    );
};

const Breadcrumb = ({data}) => {
    const rowLength = data.length;

    return (
        <StyledBreadcrumb>
            {data.map((row, index) => {
                if (rowLength === index + 1) {
                    return <div key={index}><Link to={row.link}>{row.title}</Link></div>;
                } else {
                    return (
                        <React.Fragment key={index}>
                            <div><Link to={row.link}>{row.title}</Link></div>
                            <div><Arrow /></div>
                        </React.Fragment>
                    );
                }
            })}
        </StyledBreadcrumb>
    );
};

Breadcrumb.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string
        })
    )
};

export default Breadcrumb;