import React, {useEffect, useMemo, useState} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {TextField} from "@material-ui/core";
import styled from "styled-components";

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import {red} from '@material-ui/core/colors';
import Divider from "@material-ui/core/Divider";
import {InputToggle} from "../inputs";
import {Box} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        minWidth: 280,
        marginBottom: 20
    },
    title: {
        textAlign: 'center',
        marginBottom: 20
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        marginBottom: 20
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const Fdiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;


export const ItemList = (props) => {
    return (
        <TableContainer style={{marginTop: 30, marginBottom: 30}}>
            <Table>
                <colgroup>
                    <col width="6%"/>
                    <col width="18%"/>
                    <col width="24%"/>
                    <col width="*"/>
                    <col width="10%"/>
                    <col width="8%"/>
                    <col width="15%"/>
                </colgroup>
                <TableHead>
                    <TableRow>

                        <StyledTableCell>
                            <Checkbox
                                disableRipple
                                color="default"
                                onClick={(e) => props.allCheckList(e)}
                            />
                        </StyledTableCell>
                        {props.tableHeaderValues.map((item, key) => {
                            return (
                                <StyledTableCell key={key}>
                                    <Fdiv>
                                        {item}
                                    </Fdiv>
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tableBodyValues.map((items, key) => {
                        return (
                            <TableRow key={'listItem_' + key}>
                                <StyledTableCell align={'center'}>
                                    <Checkbox
                                        disableRipple
                                        color="default"
                                        checked={props.checkList.indexOf(items.id) > -1 ? true : false}
                                        onClick={() => props.setCheck(items.id)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align={'center'}>
                                    {/*<UpDown orderChange={props.orderChange} orderChangeValue={props.orderChangeValue}*/}
                                    {/*        id={items.id} order={items.order}/>*/}

                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            width: '20%'
                                        }}>
                                            <ArrowDropUpIcon onClick={() => {
                                                props.orderChange(items.id, items.order + 1)
                                            }}/>
                                            <ArrowDropDownIcon onClick={() => {
                                                props.orderChange(items.id, items.order - 1)
                                            }}/>
                                        </div>

                                        {items.order}
                                        {/*<input type="number" defaultValue={items.order} style={{'width' : 50}}/>*/}
                                        <TextField type="number"
                                                   style={{ marginLeft:5}}
                                                   placeholder={"변경값"}
                                                   onKeyPress={(e) => props.orderChangeValue(e, items.id, items.order)}/>
                                    </div>
                                </StyledTableCell>
                                {/*<StyledTableCell align={'left'}>{items.type}</StyledTableCell>*/}
                                <StyledTableCell align={'left'} style={{cursor: 'pointer'}}
                                                 onClick={() => props.onClick(items.id)}>{items.companyName}</StyledTableCell>
                                <StyledTableCell align={'left'}>
                                    <Box
                                        style={{width: 300, whiteSpace: 'nowrap'}}
                                        component="div"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        bgcolor="background.paper"
                                        title={items.homepage}
                                    >
                                        {items.homepage}
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align={'left'}>{items.status}</StyledTableCell>
                                <StyledTableCell align={'left'}>{items.isOpen}</StyledTableCell>
                                <StyledTableCell align={'left'}>{items.insertDt}</StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const ItemCardList = (props) => {
    const classes = useStyles();

    return (
        <div style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'space-around',
            marginTop: 30,
            marginBottom: 30
        }}>
            {props.tableBodyValues.map((item, key) => {
                return (
                    <Card className={classes.root} key={'cardItemList_' + key}>
                        <CardMedia
                            className={classes.media}
                            alt="썸네일"
                            image={item.thumb[0] ? item.thumb[0] : '/images/pensionnara/logo.png'}
                            title="Paella dish"
                        />
                        <Typography className={classes.title} variant="h5" component="h2"
                                    onClick={() => props.onClick(item.id)}>
                            {item.companyName}
                        </Typography>
                        <Divider/>
                        <CardContent>
                            {/*<Fdiv style={{marginBottom: 15}}>*/}
                            {/*    <Typography color="textSecondary">*/}
                            {/*        분류*/}
                            {/*    </Typography>*/}
                            {/*    <Typography variant="body1" component="h5">*/}
                            {/*        {item.type}*/}
                            {/*    </Typography>*/}
                            {/*</Fdiv>*/}
                            <Fdiv style={{marginBottom: 15}}>
                                <Typography color="textSecondary">
                                    웹사이트
                                </Typography>
                                <Box
                                    style={{
                                        width: 160,
                                        whiteSpace: 'nowrap',
                                        textAlign: 'right'
                                    }}
                                    component="div"
                                    my={2}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    bgcolor="background.paper"
                                    title={item.homepage}
                                >
                                    {item.homepage}
                                </Box>
                            </Fdiv>
                            <Fdiv style={{marginBottom: 15}}>
                                <Typography color="textSecondary">
                                    상태
                                </Typography>
                                <Typography variant="body1" component="h5">
                                    {item.status}
                                </Typography>
                            </Fdiv>
                            <Fdiv>
                                <Typography color="textSecondary">
                                    사용여부
                                </Typography>
                                <InputToggle toggleChecked={item.isOpen ? true : false}/>
                            </Fdiv>
                        </CardContent>
                    </Card>
                );
            })}
        </div>
    );
}

const UpDown = (props) => {

    // const [order, setOrder] = useState(props.order);


    // if(order === null) {
    //     return false;
    // }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '40%'}}>
                <ArrowDropUpIcon onClick={() => {
                    props.orderChange(props.id, props.order + 1)
                }}/>
                <ArrowDropDownIcon onClick={() => {
                    props.orderChange(props.id, props.order - 1)
                }}/>
            </div>
            <TextField type="number"
                       defaultValue={props.order}
                       style={{width: '60%'}}
                       onKeyPress={(e) => props.orderChangeValue(e, props.id, props.order)}/>
        </div>
    );
}