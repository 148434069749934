// actions
const SET_PENSION = "SET_PENSION";

// action creators
const setPensionList = (pensionList) => {
    return {
        type: SET_PENSION,
        pensionList
    };
};

// api actions
const getPensionList = (pensionList) => {

    return (dispatch, getState) => {
        // dispatch(setPensionList(pensionList));
        dispatch(setPensionList([
            {
                id: 1,
                isOpen: 1,
                name: '펜션1',
                desc: '설명1',
                address: '주소1'
            },
            {
                id: 2,
                isOpen: 1,
                name: '펜션2',
                desc: '설명2',
                address: '주소2'
            },
            {
                id: 3,
                isOpen: 1,
                name: '펜션3',
                desc: '설명3',
                address: '주소3'
            },
            {
                id: 4,
                isOpen: 0,
                name: '펜션4',
                desc: '설명4',
                address: '주소4'
            }
        ]));
    };
};

// Initial State

const initialState = {
    pensionList: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PENSION:
            return applySetPensionList(state.pensionList, action);
        default:
            return state;
    }
};

// Reducer Funcitons

const applySetPensionList = (state, action) => {
    const {pensionList} = action;

    return {
        ...state,
        pensionList
    };
};

// exports
const actionCreator = {
    getPensionList
};

export {actionCreator};

export default reducer;