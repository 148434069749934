import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledImageAddButton = styled.div`
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: #aaa;
    border: 3px dashed #aaa;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition: all 0.4s;
    box-sizing: border-box;
    margin: 10px;
    
    &:before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    width: calc(33.33% - 20px);
    
    @media (max-width: 720px) {    
        width: calc(50% - 20px);
        margin: 0 0 20px 10px !important;
    }
    
    @media (max-width: 576px) {    
        width: 100%;
        margin: 10px 0 !important;
    }
`;

const ImageAddButton = (props) => {
    return (
        <StyledImageAddButton onClick={props.onClick} style={{...props.style}}>
            <div>
                <p>클릭 후 이미지를<br/>추가해주세요</p>
            </div>
        </StyledImageAddButton>
    );
};

ImageAddButton.propTypes = {
    onClick: PropTypes.func
};

export default ImageAddButton;

export const ImageAddButtonWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    
    @media (max-width: 720px) {
        margin-left: 0;
    
        & > div:nth-child(odd) {
            margin-left: 0;
        }
        
        & > div:nth-child(even) {
            margin-right: 0;
        }
    }
    
    @media (max-width: 576px) {
        margin-left: 0;
    }
`;
