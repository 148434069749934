import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DetailBody, DetailContentBox} from '../../../detail';
import {ColorButton} from '../../../button';
import {InputBox, InputText, InputDate} from '../../../inputs';

const BasicCodeDialog1 = props => {

    const [title, setTitle] = useState('');

    const saveData = () => {

        const data = {
            title: title
        }

        props.onClick(data);
    }

    return (
        <div>
            <DetailBody paddingTop="20px">
                <DetailContentBox>
                    <InputBox title="분류명" firstBox>
                        <InputText placeholder="입력해주세요" onChange={setTitle} inputValue={title}/>
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now />
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="저장" color="blue" onClick={saveData} size="70%" />
            <ColorButton title="삭제" color="bluejay" size="30%" />
        </div>
    );
};

BasicCodeDialog1.propTypes = {
    show: PropTypes.bool
};

export default BasicCodeDialog1;