import React, {useEffect, useState} from 'react';
import '../../css/index.css';
import styled from "styled-components";
import CompanyInfo from "../../components/templates/namyang/CompanyManagement/CompanyInfo";
const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;

const Company = (props) => {

    return (
        <MainContainer>
            <CompanyInfo/>
        </MainContainer>
    );
};

export default Company;