import React from 'react';
import PropTypes from 'prop-types';

const InputText = (props) => {

    const onChangeValue = e => {
        props.onChange(e.target.value);
    };

    return (
        <input type={props.type === undefined ? "text" : props.type} placeholder={props.placeholder} value={props.inputValue} style={props.style} onChange={onChangeValue} />
    );
};

InputText.defaultProps = {
    placeholder: '입력하세요',
    inputValue: '',
};

InputText.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func
};

export default InputText;