import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyleLabel = styled.label`
    background-color: ${props => props.checked ? '#5fc148' : '#fff'};
    color: ${props => props.checked ? '#fff' : '#000'};
    max-width: 20%;
    min-width: 20%;
    padding: 20px 0;
    height: 100%;
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #dfdfdf;
    border-bottom: solid 1px #dfdfdf;
    cursor: pointer;

    & > input {
        display: none;
    }
`;

const CheckBox = (props) => {

    return (
        <StyleLabel checked={props.checked}>
            <input type="checkbox" checked={props.checked} onChange={props.onChange} value={props.value} data-id={props.id} />
            {props.title}
        </StyleLabel>
    );
};

CheckBox.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

export default CheckBox;

export const CheckBoxContainer = styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #000;
    font-size: 18px;
    user-select: none;
    display: flex;
    align-items: center;
    flex-flow : row wrap;
    @media (max-width: 576px) {
        font-size: 14px;
    }
`;