import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import DragInfoText from '../texts/DragInfoText';
import Sortable from '../interactions/Sortable';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "보관함",
        link: ""
    },
    {
        title: "시설/서비스",
        link: ""
    }
];

const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "펜션 시설",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "펜션 서비스",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "객실 시설",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "객실 서비스",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    }
];

const secondCategory = [
    {
        id: 1,
        order: 1,
        title: "인피니티 수영장",
        isOn: true,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "자쿠지 스파",
        isOn: false,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "하늘정원",
        isOn: true,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "노래방",
        isOn: true,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
];

const thirdCategory = [
    {
        id: 1,
        order: 1,
        title: "바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션 바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션",
        isOn: true,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션 바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션",
        isOn: true,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션 바다를 마주하는 감성 여행 시원한 바닷바람이 불어오는 기분 좋은 펜션",
        isOn: false,
        thumbnail: "/images/test/thumbnail_1.jpg",
        isHeadquarters: true
    }
];

// 보관함 > 시설/서비스
const Template8 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" isHeadquarters>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="2차 분류" isHeadquarters>
                    <Select optionList={firstCategory} />
                    <AddButton title="2차 분류추가" />
                    <Sortable itemList={secondCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="시설 / 서비스" isHeadquarters>
                    <Select optionList={secondCategory} />
                    <AddButton title="시설 / 서비스 추가" />
                    <Sortable itemList={thirdCategory} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template8;