import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import DragInfoText from '../texts/DragInfoText';
import Sortable from '../interactions/Sortable';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "보관함",
        link: ""
    },
    {
        title: "메세지",
        link: ""
    }
];

const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "SMS",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "kakao",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "이메일",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "푸쉬",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    }
];

const secondCategory = [
    {
        id: 1,
        order: 1,
        title: "회원가입",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "예약대기",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "예약완료",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "예약취소",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    },
];

const thirdCategory = [
    {
        id: 1,
        order: 1,
        title: "고객발송",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "채널발송",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "펜션발송",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "본사발송",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    }
];

// 보관함 > 메세지
const Template12 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" isHeadquarters>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="2차 분류" isHeadquarters>
                    <Select optionList={firstCategory} />
                    <AddButton title="2차 분류추가" />
                    <Sortable itemList={secondCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="알림설정" isHeadquarters>
                    <Select optionList={secondCategory} />
                    <AddButton title="알림추가" />
                    <Sortable itemList={thirdCategory} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template12;