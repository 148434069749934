import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
    width: 100%;
    height: 400px;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px;
    outline: none;
    font-size: 18px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    line-height: 28px;
    
    &.is-file-false {
        border-bottom: 1px solid #dfdfdf;
    }
`;

const EditableDiv = (props) => {
    return (
        <StyledDiv dangerouslySetInnerHTML={{__html: props.contents}} contentEditable={true} className={props.isAttachedFile ? '' : 'is-file-false'}></StyledDiv>
    );
};

EditableDiv.propTypes = {
    contents: PropTypes.string,
    isAttachedFile: PropTypes.bool
};

export default EditableDiv;