import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// 파일첨부
const StyledInputFile = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    
    & > div {
        font-size: 18px;
        color: #aaa;
    }
    
    & .file-button { 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 58px;
        border-left: 1px solid #dfdfdf;
        border-right: none;
        border-top: none;
        border-bottom: none;
        outline: none;
        cursor: pointer;
        margin-right: -20px;
        font-size: 18px;
        color: #fff;
        background-color: #16a0ff;
        transition: 0.4s;
    } 
    
    & input[type="file"] { 
        position: absolute; 
        width: 1px; 
        height: 1px; 
        padding: 0; 
        margin: -1px; 
        overflow: hidden; 
        clip:rect(0,0,0,0); 
        border: 0; 
    }
    
    & .pickfile {
        color: #000;
        position: relative;
        margin-right: 41px;
    }
    
    & span {
        display: none;
    }
    
    & .pickfile > div {
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
    }
    
    & .pickfile span {
        display: flex;
        width: 16px;
        color: #000;
        margin-left: 10px;
        border: 2px solid #000;
        cursor: pointer;
        position: absolute;
        text-align: center;
        top: 4px;
        right: -25px;
        height: 16px;
        font-size: 14px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
    }
    
    @media (max-width: 800px) {
        & .pickfile > div {
            max-width: 110px;
        }
    }
    
    @media (max-width: 576px) {
        & .file-button {
            width: 88px;
        }
    }
    
    @media (max-width: 450px) {
        & .pickfile > div {
            width: 59px;
        }
        
        & > div > div {
            max-width: 100px;
        }
    }
`;

const InputFile = React.forwardRef((props, ref) => {
    return (
        <StyledInputFile>
            <div className={props.file ? "pickfile" : ""}>
                <div>{props.file ? props.file : "파일을 등록해주세요"}</div>
                <span onClick={props.onClick}>x</span>
            </div>
            <label className="file-button">
                파일첨부
                <input type="file" onChange={props.onChange} ref={ref} />
            </label>
        </StyledInputFile>
    );
});

InputFile.propTypes = {
    file: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func
};

export default InputFile;