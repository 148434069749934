import React, {useState} from 'react';
import MaterialSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import DownArrowIcon from '../icons/DownArrowIcon';
import PropTypes from 'prop-types';
import HeadquartersTitle from '../texts/HeadquartersTitle';

const StyledSelect = styled(MaterialSelect)`
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 60px;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    margin-bottom: 20px;
    
    & > div {
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
    }
    
    & > div:focus {
        background: none;
    }
    
    &:before, &:after {
        display: none;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    width: 100%;
    left: 0;
`;

const Select = ({optionList, defaultValue, style, onChange}) => {

    if(optionList.length < 1 || !optionList) {
        return (
            <StyledSelect
                IconComponent={() => (<DownArrowIcon/>)}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    disableScrollLock: true
                }}
                value={'항목을 추가하세요.'}
                style={style}
            >
                <StyledMenuItem value={'항목을 추가하세요.'}>
                    항목을 추가하세요.
                </StyledMenuItem>
            </StyledSelect>
        );
    }

    const defaultOption = defaultValue ? optionList.filter((item) => item.id == defaultValue) : [{
        title: optionList[0].title,
        value: optionList[0].value
    }];

    const handleChange = (event) => {
        const {itemId} = event.currentTarget.dataset;
        onChange(itemId);
    };

    return (
        <StyledSelect
            IconComponent={() => (<DownArrowIcon/>)}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                disableScrollLock: true
            }}
            onChange={handleChange}
            value={defaultOption[0].title}
            style={style}
        >
            {
                optionList.map((option, idx) => {
                    return (<StyledMenuItem key={'oItem_'+option.id} value={option.title} data-item-id={option.id}
                                            disableRipple>
                        {option.isHeadquarters ? <HeadquartersTitle/> : ''}
                        {option.title}
                    </StyledMenuItem>)
                })
            }
        </StyledSelect>
    );
}

Select.propTypes = {
    optionList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            order: PropTypes.number,
            title: PropTypes.string,
            isOn: PropTypes.bool,
            thumbnail: PropTypes.string,
            isHeadquarters: PropTypes.bool
        }).isRequired,
    )
};

export default Select;

const StyledNumberViewSelect = styled(StyledSelect)`
    width: 180px;
    box-shadow: none;
`;

export const NumberViewSelect = ({optionList}) => {
    const [id, setId] = useState(optionList[0].id);

    const handleChange = (event) => {
        setId(event.target.value);
    };

    return (
        <StyledNumberViewSelect
            IconComponent={() => (<DownArrowIcon/>)}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                disableScrollLock: true
            }}
            onChange={handleChange}
            value={id}
        >
            {
                optionList.map((option, idx) => {
                    return (<StyledMenuItem key={option.id} value={option.value} disableRipple>
                        {option.isHeadquarters ? <HeadquartersTitle/> : ''}
                        {option.title}
                    </StyledMenuItem>)
                })
            }
        </StyledNumberViewSelect>
    );
};