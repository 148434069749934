import React, {useEffect, useContext, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {actionCreator as modalAction} from '../../redux/modules/modal';
import DetailHeader from "../detail/DetailHeader";
import DetailContainer from "../detail/DetailContainer";

const Modal = ({id, title, children}) => {
    const [isOpen, setOpen] = useState(true);
    const dispatch = useDispatch();

    const modalClose = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve("success");
            }, 500);
        });
    };

    const closeModal = () => {
        setOpen(false);
        modalClose().then(res => {
            if (res === 'success') {
                setOpen(true);
                dispatch(modalAction.delModal(id));
            }
        });
    }

    useEffect(() => {

        return () => {
            console.log('모달 오픈');
            setOpen(true);
            // setOpen(true);
        }
    }, []);

    return (
        <DetailContainer className={isOpen ? 'show' : ''}>
            <DetailHeader onClick={closeModal} title={title}/>
            {React.cloneElement(children, {closeModal: closeModal})}
        </DetailContainer>
    );
}

const ModalView = (props) => {

    const {modal, router} = useSelector(store => store);

    let modalStyle = {position: 'fixed', top: 0, left: 0, overflowY: 'scroll'}
    if (modal.modal.length > 0) {
        modalStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1200,
            overflowY: 'scroll'
        };
    }

    return (
        <div style={modalStyle}>
            {modal.modal.length > 0 && (
                <div>
                    {
                        modal.modal.map((m) => {
                            return <Modal key={m.id} title={m.title}>{m.component}</Modal>;
                        })
                    }
                </div>
            )}
        </div>
    );
}

export default ModalView;