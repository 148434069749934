import React, {useEffect, useState} from 'react';
import {DetailContainer, DetailHeader, DetailBody, DetailContentBox} from '../detail';
import {InputBox, InputText, InputToggle, InputDate} from '../inputs';
import {ColorButton} from '../button';
import {stateToHTML} from "draft-js-export-html";
import {callApi} from "../../api";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import {useDispatch} from "react-redux";
import {Cookie} from "../../common/Cookie";

import DatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.min.css";
import moment from "moment";

import {Cancel} from "@material-ui/icons";

const options = {
    inlineStyleFn: (styles) => {
        let key = 'color-';
        let color = styles.filter((value) => value.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: color.replace(key, ''),
                },
            };
        }
    },
};

const PopupDialog = (props) => {

    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [image, setImage] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    // 파일 임시 저장.
    const [sdate, setSdate] = useState(new Date());
    const [edate, setEdate] = useState(new Date());
    const [sdateOpen, setSdateOpen] = useState(false);
    const [edateOpen, setEdateOpen] = useState(false);
    const [size, setSize] = useState(330);
    const [link, setLink] = useState('');

    const fileHandler = (e) => {
        const newFiles = e.target.files[0];
        setImage(newFiles);
    }

    const saveData = () => {

        if (title === '') {
            alert('제목을 입력하세요.');
            return false;
        }

        if (props.popupId === null && image === null) {
            alert('첨부파일을 입력하세요.');
            return false;
        }

        const formData = new FormData();
        if (image !== null)
            formData.append('file', image);

        formData.append('title', title);
        formData.append('open', isOpen ? 1 : 0);
        formData.append('sdate', moment(sdate).format("yyyy-MM-DD"));
        formData.append('edate', moment(edate).format("yyyy-MM-DD"));
        formData.append('size', size);
        formData.append('link', link);

        let method = "POST";
        let url = props.popupId !== null ? "/popup/" + props.popupId : "/popup";

        callApi(url, method, formData, true).then(function (res) {
            if (res.success) {
                window.alert((props.popupId !== null ? '수정' : '저장') + '되었습니다.');
                dispatch(modalAction.delModal('popup'));
            }
        }).catch((msg) => {
            console.log(msg);
            alert(msg);
        });
    }

    const deleteData = () => {
        if (!props.popupId)
            return false;

        if (window.confirm('삭제 하시면 복구가 불가능합니다.\n삭제 하시겠습니까?')) {
            let url = "/popup/" + props.popupId;

            callApi(url, "DELETE").then(function (res) {
                if (res.success) {
                    window.alert('삭제 되었습니다.');
                    dispatch(modalAction.delModal('popup'));
                }
            }).catch((msg) => {
                console.log(msg);
                alert(msg);
            });
        }

    }

    useEffect(() => {
        if (props.popupId !== null) {
            callApi('/popup/' + props.popupId, 'GET', {}).then((res) => {

                console.log(res.data);
                setTitle(res.data.title);
                setLink(res.data.link);
                setIsOpen(res.data.open == "1" ? true : false);
                setSdate(new Date(res.data.sdate));
                setEdate(new Date(res.data.edate));
                setSize(res.data.width);
                setImgUrl(res.data.image);
            }).catch((e) => {

            });
        }
    }, []);

    return (
        <div>
            <DetailBody paddingTop="20px">
                <DetailContentBox>
                    <InputBox title="제목" firstBox>
                        <InputText placeholder="입력해주세요" inputValue={title} onChange={setTitle}/>
                    </InputBox>

                    <InputBox title="시작일">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            // onInputClick={()=> setSdateOpen(true)}
                            onFocus={() => {
                                setSdateOpen(true)
                            }}
                            onClickOutside={() => setSdateOpen(false)}
                            open={sdateOpen}
                            selected={sdate}
                            startDate={moment().format('yyyy-MM-dd')}
                            onChange={(date) => {
                                setSdate(date);
                                setSdateOpen(false);
                            }}
                            locale={ko}
                            isClearable={true}
                        />
                    </InputBox>
                    <InputBox title="종료일">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            onFocus={() => {
                                setEdateOpen(true)
                            }}
                            onClickOutside={() => setEdateOpen(false)}
                            open={edateOpen}
                            selected={edate}
                            startDate={sdate}
                            onChange={(date) => {
                                setEdate(date);
                                setEdateOpen(false);
                            }}
                            locale={ko}
                            isClearable={true}
                        />
                    </InputBox>
                    <InputBox title={'첨부파일'}>
                        <input type="file" multiple onChange={fileHandler}/>
                    </InputBox>
                    {imgUrl && (
                            <div style={{
                                padding: 50,
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                            }}>
                                <div style={{
                                    backgroundColor: '#FFF',
                                    marginRight: 10,
                                    marginBottom: 10,
                                    // minWidth: 360,
                                    padding: 25
                                }}>
                                    <img src={imgUrl} width={size}/>
                                    <div style={{display: 'flex', margin: '25px -25px -25px -25px'}}>
                                        <button style={{width: '50%'}}>오늘 하루 닫기</button>
                                        <button style={{width: '50%'}}>닫기</button>
                                    </div>
                                </div>
                            </div>
                    )}
                    <InputBox title="가로사이즈">
                        <InputText placeholder="입력해주세요" inputValue={size} onChange={setSize}/>
                    </InputBox>
                    <InputBox title="링크">
                        <InputText placeholder="http:// 또는 https:// 포함하여 입력하세요." inputValue={link} onChange={setLink}/>
                    </InputBox>
                    <InputBox title="사용여부" toggle>
                        <InputToggle toggleChecked={isOpen} onChange={setIsOpen}/>
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now/>
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title={props.popupId !== null ? '수정' : '작성하기'} color="blue"
                         size={props.popupId !== null ? "70%" : "100%"} onClick={saveData}/>
            {
                props.popupId !== null && (<ColorButton title="삭제" color="bluejay" size="30%" onClick={deleteData}/>)
            }

        </div>
    );
};


export default PopupDialog;