import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import Sortable from '../interactions/Sortable';
import DragInfoText from '../texts/DragInfoText';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "약관설정",
        link: ""
    }
];

const AccordionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "개인정보 수집 및 이용 동의",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "개인정보 제3자 정보제공동의",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "드림시큐리티 본인확인이용 동의",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "개인정보 수집 및 이용 동의",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 5,
        order: 5,
        title: "고유식별정보 처리 동의",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    }
];

// 약관설정
const Template5 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="약관설정" boxCount={1}>
                    <AddButton title="약관추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template5;