import React, {useState} from 'react';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {Home, People, Forum, Assignment, DynamicFeed, ViewCarousel} from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

import {AdminSubMenu} from "./AdminSubMenu";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        '&$focused': {
            color: 'yellow',
        },
    },
    listItem: {
        backgroundColor: '#3E475C',
        '& span': {
            color: '#F0F0F0'
        },
        '&:hover': {
            background: "#585d71",
        },
    },

});


export const AdminMenu = (props) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleSubmenu = () => {
        setOpen(open ? false : true);
    }

    if (props.menu === undefined || typeof props.menu !== "object") {
        return (
            <div id="menu">
                메뉴 없음.
            </div>
        );
    }

    const setIcon = (icon) => {
        switch (icon) {
            case 'company':
                return (
                    <Home style={{color: '#FFF'}}/>
                );
            case 'member':
                return (
                    <People style={{color: '#FFF'}}/>
                );
            case 'community':
                return (
                    <Forum style={{color: '#FFF'}}/>
                );
            case 'info':
                return (
                    <Assignment style={{color: '#FFF'}}/>
                );
            case 'popup':
                return (
                    <DynamicFeed style={{color: '#FFF'}}/>
                );
            case 'banner':
                return (
                    <ViewCarousel style={{color: '#FFF'}}/>
                );
            default:
                return (
                    <Home style={{color: '#FFF'}}/>
                );
        }
    }

    return (
        <div className={classes.root}>
            <ListItem
                className={classes.listItem}
                button
                onClick={handleSubmenu}
                component={props.menu.link !== "" ? Link : "div"}
                to={props.menu.link}
            >
                <ListItemIcon>
                    {setIcon(props.menu.icon)}
                </ListItemIcon>
                <ListItemText primary={props.menu.text}/>
                {
                    props.menu.submenu.length > 0 && (
                        <div>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </div>
                    )
                }
            </ListItem>
            <AdminSubMenu submenu={props.menu.submenu} open={open}/>
        </div>
    );
};