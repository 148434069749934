import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
    background-color: #3e475c;
    color: #fff;
    font-size: 16px;
    width: 60px;
    min-width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    margin-right: 20px;
`;

const HeadquartersTitle = (props) => {
    return <StyledDiv>{props.title}</StyledDiv>;
};

HeadquartersTitle.propTypes = {
    title: PropTypes.string
};

HeadquartersTitle.defaultProps = {
    title: "오션넷"
};

export default HeadquartersTitle;