import React, {useEffect, useState} from 'react';
import '../../css/index.css';
import styled from "styled-components";
import MemberList from "../../components/member/MemberList";

const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;

const Member = (props) => {

    return (
        <MainContainer>
            <MemberList/>
        </MainContainer>
    );
};

export default Member;