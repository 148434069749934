import React, {useState} from 'react';
import Breadcrumb from '../menus/Breadcrumb';
import styled from 'styled-components';
import Accordion, {SearchAccordion} from '../widgets/Accordion';
import ColorButton from '../button/ColorButton';
import InputBox from '../inputs/InputBox';
import InputSelect from '../inputs/InputSelect';
import InputText from '../inputs/InputText';
import InputNumber from '../inputs/InputNumber';
import TextLink from '../inputs/TextLink';
import {NumberViewSelect} from '../inputs/Select';

const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const InputBoxContainer = styled.div`
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
`

const InputBoxWrap = styled.div`
    flex: 1;
    padding: 10px;
`

const ColorButtonWrap = styled.div`
    margin-left: -20px;
    margin-right: -21px;
    margin-bottom: -20px;
`

const breadcrumbData = [
    {
        title: "계정관리",
        link: ""
    },
    {
        title: "멤버",
        link: ""
    }
];

const partnerCompanyList = [
    {
        id: 1,
        order: 1,
        value: "네이버",
        title: "네이버",
        isOn: true,
    },
    {
        id: 2,
        order: 2,
        value: "떠나요",
        title: "떠나요",
        isOn: true,
    },
    {
        id: 3,
        order: 3,
        value: "여기어때",
        title: "여기어때",
        isOn: true,
    }
];

const genderList = [
    {
        id: 1,
        order: 1,
        value: "전체",
        title: "전체",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "남자",
        title: "남자",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "여자",
        title: "여자",
        isOn: true
    }
];

const accountStateList = [
    {
        id: 1,
        order: 1,
        value: "전체",
        title: "전체",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "on",
        title: "on",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "off",
        title: "off",
        isOn: true
    }
];

// 검색결과 갯수보기
const numberViewList = [
    {
        id: 1,
        order: 1,
        value: 10,
        title: "10개씩 보기",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: 20,
        title: "20개씩 보기",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: 30,
        title: "30개씩 보기",
        isOn: true
    },
    {
        id: 4,
        order: 4,
        value: 40,
        title: "40개씩 보기",
        isOn: true
    },
    {
        id: 5,
        order: 5,
        value: 50,
        title: "50개씩 보기",
        isOn: true
    }
];

const AccordionPanelWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const PageButtonWrap = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    
    @media (max-width: 500px) {
        & {
            margin-bottom: 20px;
        }
    }
`

const PageButton = styled.button`
    width: 60px;
    height: 60px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
`

const PageInput = styled.input`
    width: 60px;
    height: 60px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #dfdfdf;
    outline: none;
`

const PageTotal = styled.div`
    width: 60px;
    height: 60px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
`

// 계정관리 > 멤버
const Template13 = () => {
    // 제휴사
    const [partnerCompany, setPartnerCompany] = useState("");
    const handleChangePartnerCompany = (event) => {
        setPartnerCompany(event.target.value);
    };

    // 성별
    const [gender, setGender] = useState("전체");
    const handleChangeGender = (event) => {
        setGender(event.target.value);
    };

    // 사용여부
    const [accountState, setAccountState] = useState("전체");
    const handleChangeAccountState = (event) => {
        setAccountState(event.target.value);
    };

    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="계정검색" boxCount={2}>
                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="제휴사">
                                <InputSelect optionList={partnerCompanyList} placeholder="선택" value={partnerCompany} onSelectChange={handleChangePartnerCompany} />
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="사업자">
                                <InputText placeholder="입력해주세요" />
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="이름">
                                <InputText placeholder="입력해주세요" />
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="성별">
                                <InputSelect optionList={genderList} value={gender} onSelectChange={handleChangeGender} />
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="핸드폰 번호">
                                <InputNumber subType="phone" />
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="생년월일">
                                <TextLink text="81.07.01 - 88.06.01" />
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="가입일">
                                <TextLink text="99.07.01 - 21.06.01" />
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="퇴사일">
                                <TextLink text="99.07.01 - 21.07.11" />
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer style={{marginBottom: "50px"}}>
                        <InputBoxWrap>
                            <InputBox title="사용여부">
                                <InputSelect optionList={accountStateList} value={accountState} onSelectChange={handleChangeAccountState} />
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="등록일">
                                <TextLink text="20.06.09 - 20.06.10" />
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <ColorButtonWrap>
                        <ColorButton title="검색" size="70%" color="blue" />
                        <ColorButton title="초기화" size="30%" color="bluejay" />
                    </ColorButtonWrap>
                </Accordion>

                <SearchAccordion title="검색결과 6건" boxCount={2}>
                    <AccordionPanelWrap>
                        <div>
                            <NumberViewSelect optionList={numberViewList} />
                        </div>

                        <PageButtonWrap>
                            <PageButton>
                                <svg
                                    width="11px" height="17px">
                                    <path fillRule="evenodd"  fill="rgb(62, 71, 92)"
                                          d="M10.164,14.932 L8.750,16.346 L0.972,8.568 L1.290,8.250 L0.972,7.932 L8.750,0.153 L10.164,1.568 L3.482,8.250 L10.164,14.932 Z"/>
                                </svg>
                            </PageButton>
                            <PageInput type="text" defaultValue={1} />
                            <PageTotal>/ 140</PageTotal>
                            <PageButton>
                                <svg
                                    width="11px" height="17px">
                                    <path fillRule="evenodd"  fill="rgb(62, 71, 92)"
                                          d="M10.153,8.567 L2.374,16.345 L0.960,14.931 L7.642,8.249 L0.960,1.567 L2.374,0.153 L10.153,7.931 L9.835,8.249 L10.153,8.567 Z"/>
                                </svg>
                            </PageButton>
                        </PageButtonWrap>
                    </AccordionPanelWrap>

                    <hr />

                    <table>
                        <thead>
                            <tr>
                                <th>제휴사</th>
                                <th>사업자</th>
                                <th>이름</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>네이버</td>
                                <td>네이버</td>
                                <td>홍길동</td>
                            </tr>
                        </tbody>
                    </table>

                    <ColorButtonWrap>
                        <ColorButton title="다음페이지" size="70%" color="blue" arrowIcon />
                        <ColorButton title="멤버초대" size="30%" color="bluejay" />
                    </ColorButtonWrap>
                </SearchAccordion>
            </AccordionContainer>
        </div>
    );
};

export default Template13;