import React, {useEffect, useState, createRef} from 'react';
import {DetailBody, DetailContainer, DetailContentBox, DetailHeader} from '../detail';
import PropTypes from 'prop-types';
import {ColorButton, ImageAddButtonWrap, ImageAddButton, AddButton} from '../button';
import {
    InputBox,
    InputEmail,
    InputNumber,
    InputSelect,
    InputText,
    InputTextarea,
    TextBox,
    InputToggle,
    InputDate,
    CheckBox,
    CheckBoxContainer
} from '../inputs';
import styled from 'styled-components';
import {callApi} from "../../api";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import {useDispatch} from "react-redux";
import {loadingStatus} from "../../redux/modules/loading";
import {GetApp} from "@material-ui/icons";
import {Button} from "@material-ui/core";

const SortableItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    user-select: none;
    
    position: relative;
    margin: 10px;
    
    &:before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    width: calc(33.33% - 20px);

    @media (max-width: 720px) {    
        width: calc(50% - 20px);
    }

    @media (max-width: 576px) {    
        width: 100%;
        margin: 10px 0 !important;
    }
`;

const SortableItemInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: grab;
    overflow:hidden;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`;

const SortableMenuButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    
    & span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3e475c;
        display: inline-block;
        margin: 0 1.5px;
        z-index: 3;
        pointer-events: none;
    }
`;

const SortableMenu = styled.div`
    display: none;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50px;
    right: 20px;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    
    & .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      z-index: 5;
      user-select: none;
    }
    
    & .menu-item:hover {
      background-color: #dfdfdf !important;
    }
`;

const options = {
    inlineStyleFn: (styles) => {
        let key = 'color-';
        let color = styles.filter((value) => value.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: color.replace(key, ''),
                },
            };
        }
    },
};

const MemberView = (props) => {

    const dispatch = useDispatch();

    // 모바일일 경우 이미지 pressDelay 값 설정
    const [pressDelay, setPressDealy] = useState(0);
    const [file, setFile] = useState(null);
    const [user, setUser] = useState({
        id: '',
        user_id: '',
        user_pwd: '',
        user_name: '',
        company: '',
        level: '',
        phone: '',
        status: '',
        business_file: '',
        business_number: '',
        insert_dt: ''
    });
    useEffect(() => {
        if (window.innerWidth < 577) {
            setPressDealy(200);
        }

        if (props.memberId !== null) {
            callApi('/member/' + props.memberId, 'GET', {}).then((res) => {
                if (res.success) {
                    setUser(res.user);
                } else {
                    window.alert("불러오기 실패");
                }
            }).catch((err) => {
                window.alert(err.msg);
                dispatch(modalAction.delModal('memberInfo'));
            });
        }
    }, []);

    // 전송
    const saveData = () => {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('email', user.user_id);
        formData.append('name', user.user_name);
        formData.append('pension', user.company);
        formData.append('password', user.user_pwd);
        formData.append('phone', user.phone);
        formData.append('status', user.status ? 1 : 0);
        formData.append('business_number', user.business_number);

        let url = props.memberId !== null ? "/member/" + props.memberId : "/member";

        dispatch(loadingStatus(true));

        callApi(url, "POST", formData, true).then(function (res) {
            if (res.success) {
                window.alert((props.memberId !== null ? '수정' : '저장') + '이 완료되었습니다.');
                dispatch(modalAction.delModal('memberInfo'));
            }

            dispatch(loadingStatus(false));
        }).catch((msg) => {
            // alert(msg);
            dispatch(loadingStatus(false));
        });
    }

    const deleteData = () => {
        if (!props.memberId)
            return false;

        if (window.confirm('삭제 하시면 복구가 불가능합니다.\n삭제 하시겠습니까?')) {
            let url = "/member/" + props.memberId;

            callApi(url, "DELETE").then(function (res) {
                if (res.success) {
                    window.alert('삭제 되었습니다.');
                    dispatch(modalAction.delModal('memberInfo'));
                }
            }).catch((msg) => {
                // alert(msg);
            });
        }
    }

    return (
        <div>
            <DetailBody>
                <DetailContentBox>
                    <div className="title">기본정보</div>

                    <InputBox title="아이디" firstBox>
                        <InputText inputValue={user.user_id} onChange={(value) => setUser({...user, user_id: value})}/>
                    </InputBox>
                    <InputBox title="비밀번호">
                        {props.memberId !== null ? (
                            <InputText type="password" placeholder="비밀번호 변경시에만 입력하세요." inputValue={user.user_pwd}
                                       onChange={(value) => setUser({...user, user_pwd: value})}/>
                        ) : (
                            <InputText type="password" placeholder="비밀번호를 입력하세요." inputValue={user.user_pwd}
                                       onChange={(value) => setUser({...user, user_pwd: value})}/>
                        )}
                    </InputBox>
                    <InputBox title="성명">
                        <InputText placeholder="입력해주세요" inputValue={user.user_name}
                                   onChange={(value) => setUser({...user, user_name: value})}/>
                    </InputBox>
                    <InputBox title="업체명">
                        <InputText placeholder="입력해주세요" inputValue={user.company}
                                   onChange={(value) => setUser({...user, company: value})}/>
                    </InputBox>
                    <InputBox title="사업자등록번호">
                        <InputText placeholder="입력해주세요" inputValue={user.business_number}
                                   onChange={(value) => setUser({...user, business_number: value})}/>
                    </InputBox>

                    <InputBox title="사업자등록증">
                            {user.business_file !== "" && (
                                <Button variant="outlined"
                                        color="primary"
                                        startIcon={<GetApp/>}
                                        style={{marginRight:20}}
                                        href={user.business_file}>
                                    사업자등록증
                                </Button>
                            )}
                            <input type="file" placeholder="사업자등록증 변경시에만 첨부하세요."
                                   onChange={(e) => setFile(e.target.files[0])}/>
                    </InputBox>
                    <InputBox title="전화번호">
                        <InputText placeholder="입력해주세요" inputValue={user.phone}
                                   onChange={(value) => setUser({...user, phone: value})}/>
                    </InputBox>


                </DetailContentBox>


                <DetailContentBox>
                    <div className="title">상태정보</div>
                    <InputBox title="승인">
                        <InputToggle
                            toggleChecked={user.status === true ? true : false}
                            onChange={(value) => {
                                console.log(value);
                                setUser({...user, status: value})
                            }}/>
                    </InputBox>
                    <InputBox title="등록일">
                        {/*<InputDate now/>*/}
                        {user.insert_dt}
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title={props.memberId !== null ? '수정' : '저장'} color="blue" size="70%" onClick={saveData}/>
            <ColorButton title="삭제" color="bluejay" size="30%" onClick={deleteData}/>
        </div>
    );
};

export default MemberView;