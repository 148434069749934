import React, {useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import Clock from 'react-live-clock';
import styled from 'styled-components';

const StyledClock = styled.div`
    font-size: 18px;
    color: #000;
`;

const InputDate = (props) => {
    const [selectedDate, setSelectedDate] = useState();

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    if (props.now) {
        return (
            <StyledClock>
                <Clock
                    format={'YYYY년 MM월 DD일 / HH시 mm분 ss초'}
                    ticking={true}
                    timezone={'Asia/Seoul'}
                />
            </StyledClock>
        );
    }

    if (props.readonly) {
        let temp = props.value.split(/[- :]/);

        return (
            <StyledClock>
                {temp[0]}년 {temp[1]}월 {temp[2]}일 / {temp[3]}시 {temp[4]}분 {temp[5]}초
            </StyledClock>
        )
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date'
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

InputDate.propTypes = {
    now: PropTypes.bool
};

export default InputDate;