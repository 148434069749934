import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const InputNumber = (props) => {
    // const [inputValue, setInputValue] = useState();
    const [format, setFormat] = useState();

    useEffect(() => {
        // 타입 phone, 기본값 존재 시
        if (props.value != null && props.subType === "phone") {
            let temp = props.value.replace(/-/g, "");
            // setInputValue(temp);
            props.onChange && props.onChange(temp);
        }
    }, []);

    const onChangeValue = (values) => {
        let value = values.value;
        // setInputValue(value);
        props.onChange(value);

        // 전화번호 자릿수
        let valueDgit = value.toString().length;

        // 지역번호 02
        const re1 = /^02/;
        let re1Result = re1.exec(value);
        if (re1Result != null) {
            if (valueDgit > 9) {
                setFormat("##-####-####");
            } else {
                setFormat("##-###-#####");
            }
        }

        // 지역번호 050x
        const re2 = /^050[0-9]/;
        let re2Result = re2.exec(value);
        if (re2Result != null) {
            if (valueDgit > 11) {
                setFormat("####-###-#####");
            } else {
                setFormat("####-####-####");
            }
        }

        // 지역번호 031, 032, 033, 041, 042, 043, 044, 051, 052, 053, 054, 055, 061, 062, 063, 064
        const re3 = /^0[3-9][0-9]/;
        let re3Result = re3.exec(value);
        if (re3Result != null) {
            if (valueDgit > 10) {
                setFormat("###-####-####");
            } else {
                setFormat("###-###-#####");
            }
        }

        // 휴대전화 01x
        const re4 = /^01[0-9]/;
        let re4Result = re4.exec(value);
        if (re4Result != null) {
            if (valueDgit > 10) {
                setFormat("###-####-####");
            } else {
                setFormat("###-###-#####");
            }
        }

        // 8자리 업체 번호 1xxx
        const re5 = /^1[0-9][0-9][0-9]/;
        let re5Result = re5.exec(value);
        if (re5Result != null) {
            setFormat("####-####");
        }
    };

    const onChangePercentageValue = (values) => {
        let value = values.value;
        // setInputValue(value);
        props.onChange(value);
    }

    let result = {};

    // 기본 숫자
    result['default'] = <NumberFormat
        placeholder={props.placeholder}
        value={props.inputValue}
        onValueChange={onChangeValue}
        allowNegative={false}
        allowLeadingZeros={true}
    />;

    // 사업자등록번호
    result['businessNumber'] = <NumberFormat
        format="###-##-####"
        mask="_"
        placeholder="___-__-___"
        value={props.inputValue}
        onValueChange={onChangeValue}
    />;

    // 전화번호
    result['phone'] = <NumberFormat
        placeholder={props.placeholder}
        value={props.inputValue}
        onValueChange={onChangeValue}
        allowNegative={false}
        allowLeadingZeros={true}
        format={format}
    />;

    // 백분율
    result['percentage'] = <NumberFormat
        mask="_"
        suffix=" %"
        placeholder="___ %"
        value={props.inputValue}
        onValueChange={onChangePercentageValue}
        allowNegative={false}
        allowLeadingZeros={false}
    />;

    return (
        <>
            {result[props.subType]}
        </>
    );
};

InputNumber.defaultProps = {
    subType: 'default',
    placeholder: '입력하세요'
};

InputNumber.propTypes = {
    value: PropTypes.any,
    subType: PropTypes.oneOf(['default', 'businessNumber', 'phone', 'percentage']),
    placeholder: PropTypes.string
};

export default InputNumber;