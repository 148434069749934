import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
    background: #fff;
    font-size: 18px;
    cursor: pointer;
    
    &.sortstart {
      border: 2px solid deepskyblue;
      background: rgba(135, 206, 250, 0.8);
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      cursor: grabbing;
      pointer-events: auto !important;
    }
    
    & > .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: inherit;  
        height: 60px;
        padding: 0 20px;
        border: 1px solid #dfdfdf;
        
        & > .question-title-wrap {
            display: flex;
            min-width: 0;
            
            & > .question-title {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            
            & > .replay-true {
                display: none;
            }
            
            & > .replay-false {
                display: flex;
                background-color: #3e475c;
                color: #fff;
                font-size: 16px;
                width: 60px;
                height: 20px;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                padding-top: 3px;
                flex-shrink: 0;
            }
        }
    }
    
    & > .answer {
        color: #17820d;
        height: 60px;
        border-top: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;        
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        & > .answer-title-wrap {
            display: flex;
            align-items: center;
            min-width: 0;
            
            & > .answer-icon {
                display: flex;
                align-items: center;
                flex-shrink: 0;
            
                & > svg {
                    margin-right: 20px;
                    margin-bottom: 10px;
                }
            }
            
            & > .answer-title {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        
        &.overlap-div {
            margin-top: -1px;
        }
    }
     
    & .date {
        font-size: 16px;
        color: #666;
        min-width: 90px;
        margin-left: 35px;
    }
    
    &.overlap-div {
        margin-top: -1px;
    }
`;

const NewList = (props) => {
    return (
        <StyledDiv className={props.firstBox ? '' : 'overlap-div'}>
            <div className="question">
                <div className="question-title-wrap">
                    <div className={'replay-false'}>{props.value.number}</div>
                    <div className="question-title">{props.value.title}</div>
                </div>
                <div style={{display:'flex'}}>
                    <div className="date">{props.value.date}</div>
                    <div className="date">{props.value.hit}</div>
                </div>
            </div>
        </StyledDiv>
    );
};

NewList.propTypes = {
    firstBox: PropTypes.bool,
    value: PropTypes.object
};

export default NewList;