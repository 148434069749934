import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {DetailHeader, DetailBody, DetailContentBox, DetailContainer} from '../../../detail';
import {
    InputBox,
    InputBoxNoLabel,
    InputText,
    InputNumber,
    InputEmail,
    InputDate,
    InputToggle,
    InputSelect,
    InputFile
} from '../../../inputs';
import {ColorButton} from '../../../button';
import {useDispatch, useSelector} from "react-redux";
import {callApi} from "../../../../api";

// 비즈니스 상태
const businessStateList = [
    {
        id: 1,
        order: 1,
        value: "대기",
        title: "대기",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "승인",
        title: "승인",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "보류",
        title: "보류",
        isOn: true
    }
];

// 사업자 관리 > 사업자 정보
const BusinessInfoDialog1 = (props) => {
    // const [show, setShow] = useState(true);
    //
    // const handleClick = () => {
    //     // 뒤로가기
    //     // props.history.goBack();
    //     setShow(false);
    // }
    //
    // useEffect(() => {
    //     setShow(props.show);
    // }, [props]);

    // const [formValue, setFormValue] = useState({
    //     businessNumber: "",
    //     mailOrderNumber: "",
    //     companyName: "",
    //     ceoName: "",
    //     address: "",
    //     addressDetail: "",
    //     condition: "",
    //     event: "",
    //     taxEmail: "",
    //     use: "",
    //     tempFileIds: {
    //         logoFileId: null,
    //         businessFileId: null,
    //         mailOrderFileId: null
    //     },
    //     status: "",
    // });

    const store = useSelector((store) => {
        return store;
    });

    // 사업자 번호
    const [businessNumber, setBusinessNumber] = useState("");
    // 통신판매 번호
    const [mailOrderNumber, setMailOrderNumber] = useState("");
    // 상호 (법인명)
    const [companyName, setCompanyName] = useState("");
    // 대표자 성명
    const [ceoName, setCeoName] = useState("");
    // 주소
    const [address, setAddress] = useState("");
    // 주소 디테일
    const [addressDetail, setAddressDetail] = useState("");
    // 업태
    const [condition, setCondition] = useState("");
    // 종목
    const [event, setEvent] = useState("");
    // 세금계산서 이메일
    const [taxEmail, setTaxEmail] = useState("");
    // 사용 여부
    const [use, setUse] = useState(false);

    const [tempFileIds, setTempFileIds] = useState({
        logoFileId: null,
        businessFileId: null,
        mailOrderFileId: null
    });


    // 비즈니스 상태
    const [businessState, setBusinessState] = useState("대기");
    const handleChangeBusinessState = (event) => {
        setBusinessState(event.target.value);
    };


    // 로고파일
    const logoFileInput = useRef(null);
    const [logoFile, setLogoFile] = useState(false);
    const handleChangeLogoFile = (event) => {

        if (event.target.files.length > 0) {

            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            callApi('/business/upload/logo_file', 'POST', formData, true).then((res) => {
                const newTempFiles = Object.assign(tempFileIds, {logoFileId: res.id});
                setTempFileIds(newTempFiles);
            });

            setLogoFile(event.target.files[0].name);
            // console.log('value:' + logoFileInput.current.value);
            // console.log('files:' + logoFileInput.current.files);
        }
    };
    const handleLogoFileDelete = () => {
        setLogoFile(false);
        logoFileInput.current.value = null;

        const newTempFiles = Object.assign(tempFileIds, {logoFileId: null});
        setTempFileIds(newTempFiles);
    };

    // 사업자등록증
    const businessRegistrationFileInput = useRef(null);
    const [businessRegistrationFile, setBusinessRegistrationFile] = useState(false);

    const handleChangeBusinessRegistrationFile = (event) => {
        if (event.target.files.length > 0) {

            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            callApi('/business/upload/business_file', 'POST', formData, true).then((res) => {
                const newTempFiles = Object.assign(tempFileIds, {businessFileId: res.id});
                setTempFileIds(newTempFiles);
            });

            setBusinessRegistrationFile(event.target.files[0].name);
        }
    };
    const handleBusinessRegistrationFileDelete = () => {
        setBusinessRegistrationFile(false);
        businessRegistrationFileInput.current.value = null;

        const newTempFiles = Object.assign(tempFileIds, {businessFileId: null});
        console.log(newTempFiles);
        setTempFileIds(newTempFiles);
    };

    // 통신판매신고증
    const mailOrderFileInput = useRef(null);
    const [mailOrderFile, setMailOrderFile] = useState(false);
    const handleChangeMailOrderFile = (event) => {
        if (event.target.files.length > 0) {

            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            callApi('/business/upload/mailorder_file', 'POST', formData, true).then((res) => {
                const newTempFiles = Object.assign(tempFileIds, {mailOrderFileId: res.id});
                setTempFileIds(newTempFiles);
            });

            setMailOrderFile(event.target.files[0].name);
        }
    };
    const handleMailOrderFileDelete = () => {
        setMailOrderFile(false);
        mailOrderFileInput.current.value = null;

        const newTempFiles = Object.assign(tempFileIds, {mailOrderFileId: null});
        setTempFileIds(newTempFiles);
    };

    const saveData = () => {
        const request = {
            businessNumber: businessNumber,
            mailOrderNumber: mailOrderNumber,
            companyName: companyName,
            ceoName: ceoName,
            address: address,
            addressDetail: addressDetail,
            condition: condition,
            event: event,
            taxEmail: taxEmail,
            status: businessState,
            use: use,
            tempFileIds: tempFileIds
        }

        callApi('/business', 'POST', request).then(function (res) {

        }).catch((msg) => {
            alert(msg);
        });
    }

    const deleteData = () => {
        // router.history.push('/business');
        props.closeModal();
    }

    console.log(props);

    return (
        <div>
            <DetailBody>
                <DetailContentBox>
                    <div className="title">사업자 정보</div>
                    <InputBox title="사업자번호" firstBox>
                        <InputNumber subType="businessNumber" inputValue={businessNumber} onChange={setBusinessNumber}/>
                    </InputBox>
                    <InputBox title="통신판매번호">
                        <InputText placeholder="___-__-___" inputValue={mailOrderNumber} onChange={setMailOrderNumber}/>
                    </InputBox>
                    <InputBox title="상호(법인명)">
                        <InputText inputValue={companyName} onChange={setCompanyName}/>
                    </InputBox>
                    <InputBox title="대표자 성명">
                        <InputText inputValue={ceoName} onChange={setCeoName}/>
                    </InputBox>
                    <InputBox title="주소">
                        <InputText inputValue={address} onChange={setAddress}/>
                    </InputBox>
                    <InputBox title="상세주소">
                        <InputText inputValue={addressDetail} onChange={setAddressDetail}/>
                    </InputBox>
                    <InputBox title="업태">
                        <InputText inputValue={condition} onChange={setCondition}/>
                    </InputBox>
                    <InputBox title="종목">
                        <InputText inputValue={event} onChange={setEvent}/>
                    </InputBox>
                    <InputBox title="계산서메일">
                        <InputEmail inputValue={taxEmail} onChange={setTaxEmail}/>
                    </InputBox>
                </DetailContentBox>
                <DetailContentBox>
                    <div className="title">파일첨부</div>
                    <InputBoxNoLabel title="로고파일">
                        <InputFile file={logoFile} onClick={handleLogoFileDelete} onChange={handleChangeLogoFile}
                                   ref={logoFileInput}/>
                    </InputBoxNoLabel>
                    <InputBoxNoLabel title="사업자등록증">
                        <InputFile file={businessRegistrationFile} onClick={handleBusinessRegistrationFileDelete}
                                   onChange={handleChangeBusinessRegistrationFile} ref={businessRegistrationFileInput}/>
                    </InputBoxNoLabel>
                    <InputBoxNoLabel title="통신판매신고증">
                        <InputFile file={mailOrderFile} onClick={handleMailOrderFileDelete}
                                   onChange={handleChangeMailOrderFile} ref={mailOrderFileInput}/>
                    </InputBoxNoLabel>
                </DetailContentBox>
                <DetailContentBox>
                    <div className="title">상태정보</div>
                    <InputBox title="상태" firstBox>
                        <InputSelect optionList={businessStateList} onSelectChange={handleChangeBusinessState}
                                     value={businessState}/>
                    </InputBox>
                    <InputBox title="사용여부" toggle>
                        <InputToggle toggleChecked={use} onChange={() => setUse(use ? false : true)}/>
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now/>
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="저장" color="blue" size="70%" onClick={saveData}/>
            <ColorButton title="삭제" color="bluejay" size="30%" onClick={deleteData}/>
        </div>
    );
};

BusinessInfoDialog1.propTypes = {
    show: PropTypes.bool
};

export default BusinessInfoDialog1;
