import styled from 'styled-components';

export const SearchOptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    
    & > div:first-child {
        margin-right: 20px;
    }

    @media (max-width: 576px) {
        & > div:first-child {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
`;

export const SearchOptionWrap = styled.div`
    width: calc(50% - 10px);
    transition: all 0.4s;
    
    @media (max-width: 576px) {
        width: 100%;
    }
`;