import React, {useState, useCallback, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 440px;
  padding-top: 75%;
  position: relative;
  user-select: none;
  margin: 0 auto 20px;
  
  @media (min-width: 480px) {
    padding-top: 330px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-height: 330px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #aaa;
  border: ${props => props.isSrc ? "" : "3px dashed #aaa"}; 
  outline: none;
  cursor: ${props => props.isSrc ? "default" : "pointer"};
  
  position: absolute;
  top: 0;
  left: 0;
  
  & p {
    text-align: center;
  }
  
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CircleMenuButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & span {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #3e475c;
    display: inline-block;
    margin: 0 1.5px;
  }
`;

const CircleMenu = styled.div`
    display: ${props => props.showMenu ? "flex" : "none"};
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50px;
    right: 20px;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    
    & .menu-item {
      display: flex;
      align-items: center;
      padding-left: 20px;
      height: 60px;
      cursor: pointer;
      z-index: 2;
      user-select: none;
    }
    
    & .menu-item:hover {
      background-color: #dfdfdf !important;
    }
    
    & .invisible{
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 0;
    }
`;


const ImageDropzone = () => {
    const [src, setSrc] = useState();
    const [isSrc, setIsSrc] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setSrc('empty');

        // Do something with the files
        acceptedFiles.forEach((file) => {
            console.log(file.path);
            console.log(file.name);
            console.log(file.size);

            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.onload = () => {
                // Do whatever you want with the file contents
                // const binaryStr = reader.result;
                // console.log(binaryStr);
                const imageSrc = reader.result;
                setSrc(imageSrc);
                setIsSrc(true);
            }
            // reader.readAsArrayBuffer(file);
            reader.readAsDataURL(file);

        });
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    const handleCircleMenuClick = () => {
        setShowMenu(showMenu ? false : true);
    };

    const handleModifyClick = () => {
        console.log('modify click');
        fileInput.current.click();

        setShowMenu(showMenu ? false : true);
    }

    const handleDeleteClick = () => {
        // 삭제 처리 api call

        // 컴포넌트 초기화
        setSrc(null);
        setIsSrc(false);
        setShowMenu(false);
    }

    const fileInput = useRef(null);

    return (
        <Container>
            <Content {...getRootProps()} isSrc={isSrc}>
                <CircleMenu showMenu={showMenu}>
                    <div onClick = {() => setShowMenu(false)} className="invisible"></div>
                    <div className="menu-item" onClick={handleModifyClick}>
                        수정
                        <input {...getInputProps()} ref={fileInput} />
                    </div>
                    <div className="menu-item" onClick={handleDeleteClick}>삭제</div>
                </CircleMenu>
                {
                    src ?
                        <CircleMenuButton onClick={handleCircleMenuClick}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </CircleMenuButton>
                        :
                        <input {...getInputProps()} />
                }
                {
                    src ?
                        <img src={src} alt="preview" />
                        :
                        <p>클릭 후 이미지를<br />추가해주세요</p>
                }
            </Content>
        </Container>
    )
};

export default ImageDropzone;