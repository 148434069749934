import React, {useEffect, useState} from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";

import {
    Drawer,
    List,
    IconButton,
    Tabs, Tab,
    Toolbar, AppBar
} from "@material-ui/core";

import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {AdminMenu} from "./menus/AdminMenu";
import {useDispatch, useSelector} from "react-redux";
import NoticeIcon from "./icons/NoticeIcon";

import {Cookie} from "../common/Cookie";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        minWidth: 480,
        backgroundColor: '#3E475C',
        '& span': {
            color: '#F0F0F0'
        }
    },
    tabs: {}
});

export const AdminContainer = React.memo((props) => {
    const classes = useStyles();

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, tab) => {
        setTab(tab);
    };

    const {menus, user, modalOpen} = useSelector((store) => {
        return store;
    });

    const dispatch = useDispatch();

    useEffect(() => {

        return () => {
            setTab(0);
        }
    }, []);


    if (menus.menus === undefined || !Array.isArray(menus.menus)) {
        return (
            <div id="menu">
                메뉴 없음.
            </div>
        );
    }


    return (
        <div>
            <AppBar
                position="fixed"
                className={clsx(props.classes.appBar, {
                    [props.classes.appBarShift]: props.menuOpen,
                    [props.classes.appBarTop]: modalOpen,
                })}
            >
                <Toolbar className={classes.root}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawer}
                        edge="start"
                        className={props.classes.menuButton}
                    >
                        {
                            props.menuOpen ? <ChevronLeftIcon/> : <MenuIcon/>
                        }
                    </IconButton>
                    {/*<Tabs*/}
                    {/*    className={classes.root}*/}
                    {/*    value={tab}*/}
                    {/*    onChange={handleTabChange}*/}
                    {/*    indicatorColor="primary"*/}
                    {/*    textColor="primary"*/}
                    {/*    lefted="true"*/}
                    {/*>*/}
                    {/*    <Tab label="홈" component={Link} to="/" value={0}/>*/}
                    {/*    <Tab label="예약목록" component={Link} to="/list" value={1}/>*/}
                    {/*    <Tab label="객실목록" component={Link} to="/list" value={2}/>*/}
                    {/*    <Tab label="정산목록" component={Link} to="/modal" value={3}/>*/}
                    {/*</Tabs>*/}
                </Toolbar>
            </AppBar>
            <Drawer
                className={props.classes.drawer}
                variant="persistent"
                anchor={'left'}
                open={props.menuOpen}
                classes={{
                    paper: props.classes.drawerPaper,
                }}
            >
                <div className={props.classes.drawerHeader}>
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <div style={{
                            display: 'flex',
                            flex: 4,
                        }}>
                            <img alt="펜션나라" src="/images/pensionnara/logo.png"/>
                        </div>
                        <div style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            // justifyContent: 'space-between'
                            justifyContent: 'flex-end'
                        }}>
                            {/*<NoticeIcon on/>*/}
                            <IconButton
                                onClick={()=>{
                                    Cookie.remove('token', {path: '/'});
                                    Cookie.remove('admin_user', {path: '/'});
                                    window.location.href = '/';
                                }}
                            >
                                <img alt="로그아웃" src="/images/icon/logout.png"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <List style={{padding: 0}}>
                    {
                        menus.menus.length > 0 && (
                            menus.menus.map((menu, idx) => (
                                // 해당 메뉴 사용자 레벨에 만족할경우에만.
                                menu.level <= user.user.userLevel && (
                                    <AdminMenu menu={menu} key={idx}/>
                                )
                            ))
                        )
                    }
                </List>
            </Drawer>
        </div>
    );
});