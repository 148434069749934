import React, {useState, useEffect} from 'react';
import {Route} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import {
    Main,
    BasicCode,
    Business,
    Company,
    Member,
    BoardNotice,
    BoardFree,
    BoardMember,
    BoardGuide,
    BoardData,
    BoardEvent,
    BoardGallery,
    Popup,
    Banner
} from '../pages';
import {AdminContainer} from "../components/AdminContainer";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";

import {Loading as LoadingView} from '../components/Loading';


// 리덕스
import {actionCreator as menuAction} from "../redux/modules/menus";
import {actionCreator as userAction} from "../redux/modules/user";
import {loadingStatus} from "../redux/modules/loading";

// 토스트
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 로딩 작업
import {useAsync} from '../api/useAsync';

// API
import {callApi} from "../api";
import Login from "../pages/Login";
import {Cookie} from "../common/Cookie";

let loadingToast = null;

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 1,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarTop: {
        height: 0,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        // backgroundColor:'#dfdfdf',
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        border: 0,
        width: drawerWidth,
        zIndex: 'auto'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // justifyContent: 'flex-end',
        justifyContent: 'space-between',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        width: '100%',
        minWidth: 360,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    },
}));

const App = () => {

    const admin_user = Cookie.get('admin_user');

    const dispatch = useDispatch();

    const classes = useStyles();
    const theme = useTheme();

    const [menuOpen, setMenuOpen] = useState(false);

    const handleDrawer = () => {
        setMenuOpen(menuOpen ? false : true);
    };

    useEffect(() => {

        if (admin_user) {

            // 초기 설정값 세팅.
            callApi('/init', 'GET', [], '').then((res) => {

                dispatch(menuAction.getMenus(res.adminMenus));
                dispatch(userAction.getUser(res.user));
                dispatch(loadingStatus(false));
            }).catch((err) => {
            });
        }

        return () => {
            dispatch(loadingStatus(true));
        }
    }, []);

    if (!admin_user) {
        return <Login/>;
    }

    // console.log(store);

    return (
        <BrowserRouter>

            <div className={classes.root}>
                <AdminContainer
                    classes={classes}
                    theme={theme}
                    menuOpen={menuOpen}
                    handleDrawer={handleDrawer}
                />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: menuOpen,
                    })}
                >
                    <div className={classes.drawerHeader}/>
                    <Route exact path="/" component={Company}/>
                    <Route path="/main" component={Main}/>
                    <Route path="/default/basic" component={BasicCode}/>
                    <Route exact path="/business" component={Business}/>
                    <Route exact path="/company" component={Company}/>
                    <Route exact path="/member" component={Member}/>
                    <Route path="/board/notice" component={BoardNotice}/>
                    <Route path="/board/guide" component={BoardGuide}/>
                    <Route path="/board/free" component={BoardFree}/>
                    <Route path="/board/event" component={BoardEvent}/>
                    <Route path="/board/member" component={BoardMember}/>
                    <Route path="/board/gallery" component={BoardGallery}/>
                    <Route path="/board/data" component={BoardData}/>
                    <Route path="/popup" component={Popup}/>
                    <Route path="/banner" component={Banner}/>
                </main>
            </div>
        </BrowserRouter>
    );
}

export default App;
