import styled from 'styled-components';

const DetailContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 360px;
    background-color: #dce1e7;
    margin-left: calc((100% * -2));
    transition: 0.4s;
    z-index: 2;
    
    &.show {
        margin-left: 0;
    }
`

export default DetailContainer;