import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import Sortable from '../interactions/Sortable';
import DragInfoText from '../texts/DragInfoText';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "업종분류",
        link: ""
    }
];

const AccordionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "국내여행",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "해외여행",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

const codeNameList = [
    {
        id: 1,
        order: 1,
        title: "펜션",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "풀빌라",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "캠핑",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "글램핑",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 5,
        order: 5,
        title: "리조트",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 6,
        order: 6,
        title: "콘도",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 7,
        order: 7,
        title: "레저",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 8,
        order: 8,
        title: "티켓",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    }
];

// 업종분류
const Template6 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" boxCount={2}>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="업종설정" boxCount={2}>
                    <Select optionList={firstCategory} />
                    <AddButton title="업종추가" />
                    <Sortable itemList={codeNameList} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template6;