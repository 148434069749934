import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import DragInfoText from '../texts/DragInfoText';
import Sortable from '../interactions/Sortable';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "보관함",
        link: ""
    },
    {
        title: "환불",
        link: ""
    }
];

const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "성수기",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "비수기",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "준성수기",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "극성수기",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    }
];

const secondCategory = [
    {
        id: 1,
        order: 1,
        title: "기본 : 85%",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "7일전 : 20%",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "2일전 : 50%",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "예약당일 : 100% (환불불가)",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    }
];

// 보관함 > 환불
const Template10 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" isHeadquarters boxCount={2}>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="환불률 설정" isHeadquarters boxCount={2}>
                    <Select optionList={firstCategory} />
                    <AddButton title="환불 추가" />
                    <Sortable itemList={secondCategory} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template10;