import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInputBox = styled.div`
    border: 1px solid #dfdfdf;
    background-color: #fff;
    width: 100%;
    height: ${props => props.textarea ? props.textareaLong ? "390px" : "180px" : "60px"};
    color: #000;
    display: flex;
    align-items: ${props => props.textarea ? "start" : "center"};
    padding: ${props => props.textarea ? "20px" : "0"} 20px;
    user-select: none;
    font-size: 18px;
    
    & > label {
        display: flex;
        align-items: ${props => props.textarea ? "start" : "center"};
        width: 100%;
        height: 100%;
    }
    
    & > label.toggle-label {
        width: auto;
    }
    
    & > label > .input-title {
        width: 110px;
        min-width: 110px;       
        font-size: 16px;
        color: #666; 
    }
    
    & input {
        font-size: 18px;
        width: 70%;
        border: none;
        outline: none;
    }
    
    & input::placeholder {
        color: #aaa;
    }
    
    & textarea::placeholder {
        color: #aaa;
    }
    
    &.overlap-div {
        margin-top: -1px;
    }
`;

const InputBox = (props) => {

    return (
        <StyledInputBox className={props.firstBox ? "" : "overlap-div"} textarea={props.textarea}
                        textareaLong={props.textareaLong} style={props.style}>
            <label className={props.toggle ? "toggle-label" : ""}>
                <div className="input-title">{props.title}</div>
                {props.children}
            </label>
            {props.click ? (
                <StyledButton onClick={props.click}>{props.btnTitle ? props.btnTitle : "확인"}</StyledButton>
            ) : null}
        </StyledInputBox>
    );
};
const StyledButton = styled.button`
    padding:10px;
    height: 60px;
    background-color: #5fc148;
    border: none;
    outline: none;
    white-space : nowrap;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
`;

InputBox.propTypes = {
    firstBox: PropTypes.bool,
    title: PropTypes.string,
    toggle: PropTypes.bool,
    textarea: PropTypes.bool,
    textareaLong: PropTypes.bool
};

export default InputBox;

const StyledInputBoxAutoHeight = styled(StyledInputBox)`
    height: auto;
    padding: 10px 20px;
    
    @media (max-width: 800px) {
        padding: 10px 20px 0;
    
        & > label {
            align-items: start;
        }  
    }
`;

export const InputBoxAutoHeight = (props) => {
    return (
        <StyledInputBoxAutoHeight className={props.firstBox ? "" : "overlap-div"}>
            <label className={props.toggle ? "toggle-label" : ""}>
                <div className="input-title">{props.title}</div>
                {props.children}
            </label>
        </StyledInputBoxAutoHeight>
    );
};

InputBoxAutoHeight.propTypes = {
    firstBox: PropTypes.bool,
    title: PropTypes.string,
    toggle: PropTypes.bool
};

const StyledInputBoxNoLabel = styled.div`
    border: 1px solid #dfdfdf;
    background-color: #fff;
    width: 100%;
    height: ${props => props.textarea ? props.textareaLong ? "390px" : "180px" : "60px"};
    color: #000;
    display: flex;
    align-items: ${props => props.textarea ? "start" : "center"};
    padding: ${props => props.textarea ? "20px" : "0"} 20px;
    user-select: none;
    font-size: 18px;
    
    & > div {
        display: flex;
        align-items: ${props => props.textarea ? "start" : "center"};
        width: 100%;
        height: 100%;
    }
    
    & > div.toggle-label {
        width: auto;
    }
    
    & > div > .input-title {
        width: 110px;
        min-width: 110px;       
        font-size: 16px;
        color: #666; 
    }
    
    & input {
        font-size: 18px;
        width: 70%;
        border: none;
        outline: none;
    }
    
    & input::placeholder {
        color: #aaa;
    }
    
    & textarea::placeholder {
        color: #aaa;
    }
    
    &.overlap-div {
        margin-top: -1px;
    }
`;

export const InputBoxNoLabel = (props) => {

    return (
        <StyledInputBoxNoLabel className={props.firstBox ? "" : "overlap-div"} textarea={props.textarea}
                               textareaLong={props.textareaLong}>
            <div className={props.toggle ? "toggle-label" : ""}>
                <div className="input-title">{props.title}</div>
                {props.children}
            </div>
        </StyledInputBoxNoLabel>
    );
};

InputBoxNoLabel.propTypes = {
    firstBox: PropTypes.bool,
    title: PropTypes.string,
    toggle: PropTypes.bool,
    textarea: PropTypes.bool,
    textareaLong: PropTypes.bool
};

export const InputBoxContainer = styled.div`
    margin: -10px -10px 10px;
    display: flex;
    flex-wrap: wrap;
`;

export const InputBoxWrap = styled.div`
    flex: 1;
    padding: 10px;
`;