// actions
const SET_MENUS = "SET_MENUS";
const SET_MENU_OPEN = "SET_MENU_OPEN";
const USE_MODAL = "USE_MODAL";

// action creators
const setMenus = (menus) => {
    return {
        type: SET_MENUS,
        menus
    };
};

const setMenuOpen = (menuOpen) => {
    return {
        type: SET_MENU_OPEN,
        menuOpen
    };
};

const useModal = (modalOpen) => {
    return {
        type: USE_MODAL,
        modalOpen
    };
};

// api actions
const toggleOpen = () => {
    return (dispatch, getState) => {
        dispatch(setMenuOpen(getState().menuOpen ? false : true));
    }
}

const toggleModal = () => {
    return (dispatch, getState) => {
        dispatch(useModal(getState().modalOpen ? false : true));
    }
}

const getMenus = (menus) => {
    return (dispatch, getState) => {
        dispatch(setMenus(menus));
    };
};

// Initial State

const initialState = {
    menus: [],
    menuOpen: false,
    modalOpen: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENUS:
            return applySetMenus(state.menus, action);
        case SET_MENU_OPEN:
            return applySetMenuOpen(state.menuOpen, action);
        case USE_MODAL:
            return applyUseModal(state.modalOpen, action);
        default:
            return state;
    }
};

// Reducer Funcitons

const applySetMenus = (state, action) => {
    const {menus} = action;

    return {
        ...state,
        menus
    };
};

const applySetMenuOpen = (state, action) => {
    const {menuOpen} = action;

    return {
        ...state,
        menuOpen
    };
};

const applyUseModal = (state, action) => {
    const {modalOpen} = action;

    return {
        ...state,
        modalOpen
    };
};

// exports
const actionCreator = {
    getMenus,
    toggleModal,
    toggleOpen
};

export {actionCreator};

export default reducer;