import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

const CalendarContainer = styled.div`
    
`

const CalendarBox = styled.div`
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    height: 575px;
    margin: 0 auto;
    padding: 0 40px;
`

const CalendarHeader = styled.div`
    font-size: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const CalendarControl = styled.div`
    height: 60px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CalendarControlButton = styled.button`
    border: none;
    outline: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 60px;
    height: 60px;
`

const CalendarTable = styled.table`
    font-size: 18px;

    & th {
        font-weight: normal;
        width: 60px;
        height: 60px;
    }
    
    & .sun {
        color: #f44336;
    }
    
    & .sat {
        color: #16a0ff;
    }
    
    & .today {
        color: #fff;
        background-color: #16a0ff;
    }
    
    & td {
        width: 60px;
        height: 60px;
        text-align: center;
        vertical-align: middle;
    }
    
    & td button {
        background: none;
        color: inherit;
        border: none;
        outline: none;
        font-size: inherit;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
`

const SelectedDate = styled.div`
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
    
    & > div:first-child {
        margin-bottom: 10px;
    }
`

const CalendarView = (props) => {
    return (
        <CalendarTable id="calendar">
            <thead>
                <tr>
                    <th className="sun">일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th className="sat">토</th>
                </tr>
            </thead>
            <tbody>
            {
                (props.rows.length > 0 && props.cells.length > 0) ? (
                    props.rows.map((r, rIdx) => {
                        return (
                            <tr key={r}>
                                {
                                    props.cells[r].map((c, cIdx) => {
                                        return (
                                            <td key={"cell_" + cIdx} className={(c.value && c.className)}>
                                                <button onClick={props.handleDateClick(c.date)}>{c.value}</button>
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>없음</td>
                    </tr>
                )
            }
            </tbody>
        </CalendarTable>
    );
};

const Calendar = (props) => {
    const nowDate = moment();

    const [currentYear, setCurrentYear] = useState(nowDate.year());
    const [currentMonth, setCurrentMonth] = useState(nowDate.month() + 1);

    const [pickYear, setPickYear] = useState(nowDate.year());
    const [pickMonth, setPickMonth] = useState(nowDate.month() + 1);
    const [pickDate, setPickDate] = useState(nowDate.date());

    const [baseDate, setBaseDate] = useState(nowDate.format('YYYY-MM-DD'));
    const [selectDate, setSelectDate] = useState(false);
    const [data, setData] = useState({rows: [], cells: []});

    const handlePrevClick = () => {
        let tempDate = moment(baseDate).subtract(1, 'months').format('YYYY-MM-DD');
        //한달 전
        setBaseDate(tempDate);
    };

    const handleNextClick = () => {
        let tempDate = moment(baseDate).add(1, 'months').format('YYYY-MM-DD');
        //한달 후
        setBaseDate(tempDate);
    };

    const handleDateClick = (date) => () => {
        console.log(date);

        // null 칸은 작동 안함
        if (date !== null) {
            setSelectDate(date);
            setPickYear(new Date(date).getFullYear());
            setPickMonth(new Date(date).getMonth()+1);
            setPickDate(new Date(date).getDate());
        }
    };

    useEffect(() => {
        setCurrentYear(moment(baseDate).year());
        setCurrentMonth(moment(baseDate).month() + 1);

        const buildCalendar = () => {

            const buildCalendarNowDate = moment();

            // 이번 달 1일의 요일
            const firstDay = moment(baseDate).startOf('month').day();

            // 이번 달 마지막 날
            const lastDate = moment(baseDate).endOf('month').date();

            let date = 1;
            let row = [];
            let cell = [];
            const row_count = Math.ceil(lastDate / 7) + 1;

            for (let i = 0; i < row_count; i++) {

                // row = calendarTbody.insertRow(i);
                cell[i] = [];
                row.push(i);

                // (0: 일, 1: 월, 2: 화, 3: 수, 4: 목, 5: 금, 6: 토)
                for (let j = 0; j < 7; j++) {

                    let className = null;
                    if (j === 0) {
                        className = 'sun';
                    } else if (j === 6) {
                        className = 'sat';
                    }

                    // 오늘 날짜 (년월 체크)
                    if (selectDate) {
                        // 날짜를 선택했다면.. 선택된 날짜에 today
                        className = moment(baseDate).date(date).format('YYYY-MM-DD') === selectDate ? 'today' : className;
                    } else if (baseDate === buildCalendarNowDate.format('YYYY-MM-DD')) {
                        // 날짜를 선택한게 없다면 디폴트 오늘날짜 today
                        className = moment(baseDate).date() === date ? 'today' : className;
                    }

                    // 달력 그릴때 기본 빈칸은 NULL
                    let value = null;

                    // 선택한 달의 첫날의 요일과 동일한 요일부터 칸 채움
                    if (i === 0) {
                        if (j >= firstDay) {
                            value = date;
                            date++;
                        }
                    } else {
                        if (date <= lastDate) {
                            value = date;
                            date++;
                        }
                    }

                    cell[i].push({
                        className: className,
                        value: value,
                        date: value ? moment(baseDate).date(value).format('YYYY-MM-DD') : value
                    });
                }
            }

            setData({
                rows: row,
                cells: cell
            });
        };

        buildCalendar();
    }, [baseDate, selectDate]);

    return (
        <CalendarContainer>
            <CalendarBox>
                <CalendarHeader>{props.title} 선택</CalendarHeader>
                <CalendarControl>
                    <CalendarControlButton onClick={handlePrevClick}>
                        <svg
                            width="11px" height="17px">
                            <path fillRule="evenodd" fill="rgb(62, 71, 92)"
                                  d="M10.164,14.932 L8.750,16.346 L0.972,8.568 L1.290,8.250 L0.972,7.932 L8.750,0.154 L10.164,1.568 L3.482,8.250 L10.164,14.932 Z"/>
                        </svg>
                    </CalendarControlButton>
                    <div>{currentYear}년 {currentMonth}월</div>
                    <CalendarControlButton onClick={handleNextClick}>
                        <svg
                            width="11px" height="17px">
                            <path fillRule="evenodd" fill="rgb(62, 71, 92)"
                                  d="M10.153,8.567 L2.375,16.346 L0.961,14.931 L7.643,8.249 L0.961,1.567 L2.375,0.153 L10.153,7.931 L9.835,8.249 L10.153,8.567 Z"/>
                        </svg>
                    </CalendarControlButton>
                </CalendarControl>
                <div>
                    <CalendarView
                        rows={data.rows}
                        cells={data.cells}
                        handleDateClick={handleDateClick}
                    />
                </div>
            </CalendarBox>

            <SelectedDate>
                <div>{props.title}</div>
                <div>{pickYear}년 {pickMonth}월 {pickDate}일</div>
            </SelectedDate>
        </CalendarContainer>
    );
};

Calendar.propTypes = {
    title: PropTypes.string
}

export default Calendar;