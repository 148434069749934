import React from 'react';
import MaterialSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import DownArrowIcon from '../icons/DownArrowIcon';
import PropTypes from 'prop-types';

const StyledSelect = styled(MaterialSelect)`
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 100%;
    font-size: 18px;
    
    & > div {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
    
    & > div:focus {
        background: none;
    }
    
    &:before, &:after {
        display: none;
    }
    
    & .placeholder {
        color: #9197a3;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    width: 100%;
    left: 0;
`;

const InputSelect = (props) => {
    return (
        <StyledSelect
            IconComponent={() => (<DownArrowIcon right="0"/>)}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                disableScrollLock: true
            }}
            onChange={props.onSelectChange}
            value={props.value}
            displayEmpty
        >
            {props.placeholder ?
                <MenuItem value="">
                    <div className="placeholder">
                        {props.placeholder}
                    </div>
                </MenuItem> :
                ""
            }
            {
                props.optionList.map((option, idx) => {
                    return <StyledMenuItem key={option.id} value={option.value}
                                           disableRipple>{option.title}</StyledMenuItem>
                })
            }
        </StyledSelect>
    );
};

InputSelect.propTypes = {
    optionList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            order: PropTypes.number,
            value: PropTypes.any,
            title: PropTypes.string,
            isOn: PropTypes.bool
        }).isRequired,
    ),
    placeholder: PropTypes.string,
    onSelectChange: PropTypes.func,
    value: PropTypes.any
};

export default InputSelect;