import React, {useEffect, useMemo, useState} from 'react';
import Breadcrumb from '../../../menus/Breadcrumb';
import {Accordion, AccordionContainer} from '../../../widgets';
import {
    InputBox,
    InputNumber,
    InputSelect,
    InputText,
    TextLink,
    InputBoxContainer,
    InputBoxWrap, InputToggle
} from '../../../inputs';
import {ColorButton, ColorButtonWrap} from '../../../button';
import Select from "../../../inputs/Select";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Divider from "@material-ui/core/Divider";
import {TextField, Button} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {GetApp} from '@material-ui/icons';
import {ItemCardList, ItemList} from "../../../list/ItemList";
import {useDispatch, useSelector} from "react-redux";
import {actionCreator as modalAction} from "../../../../redux/modules/modal";
import BusinessInfoDialog1 from "../BusinessManagement/BusinessInfoDialog1";
import {callApi} from "../../../../api";
import CompanyInfoDialog1 from "./CompanyInfoDialog1";
import {loadingStatus} from "../../../../redux/modules/loading";
import {CSVDownload, CSVLink} from "react-csv";
import CompanyListExcel from "../../../excel/CompanyListExcel";

const breadcrumbData = [
    {
        title: "업체관리",
        link: ""
    },
    {
        title: "업체정보",
        link: ""
    }
];

// 비즈니스 상태
const businessStateList = [
    {
        id: 2,
        order: 2,
        value: "대기",
        title: "대기",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "승인",
        title: "승인",
        isOn: true
    },
    {
        id: 4,
        order: 4,
        value: "보류",
        title: "보류",
        isOn: true
    }
];

// 사용여부
const onOffList = [
    {
        id: 2,
        order: 2,
        value: "on",
        title: "on",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "off",
        title: "off",
        isOn: true
    }
];
// 상위 12개
const useBestList = [
    {
        id: 1,
        order: 1,
        value: true,
        title: "상위12개 검색",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: false,
        title: "상위12개 제외",
        isOn: true
    }
];

// 지역명
//수동면
// 오남읍
// 조안면
// 진접읍
// 화도읍
const regionList = [
    {
        id: 2,
        order: 2,
        value: "수동",
        title: "수동면",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "오남",
        title: "오남읍",
        isOn: true
    },
    {
        id: 4,
        order: 4,
        value: "조안",
        title: "조안면",
        isOn: true
    },
    {
        id: 5,
        order: 5,
        value: "진접",
        title: "진접읍",
        isOn: true
    },
    {
        id: 6,
        order: 7,
        value: "화도",
        title: "화도읍",
        isOn: true
    }
];

const excelHeaders = [
    {label: "순번", key: "order"},
    {label: "업체명", key: "company_name"},
    {label: "펜션스타일", key: "category"},
    {label: "지역", key: "region"},
    {label: "홈페이지", key: "homepage"},
    // { label: "설명", key: "desc" },
    {label: "주소", key: "address"},
    {label: "상세주소", key: "address_detail"},
    {label: "연락처", key: "phone"},
    {label: "입실시간", key: "check_in"},
    {label: "퇴실시간", key: "check_out"},
    {label: "금액", key: "price"},
    {label: "할인금액", key: "sale_price"},
    // { label: "상세정보", key: "detail" },
    {label: "상태", key: "status"},
    {label: "사용여부", key: "use"},
    {label: "상위12개", key: "use_best"},
    {label: "등록일", key: "insert_dt"},
];


const tableHeaderValues = ['순서', '업체명', '홈페이지', '상태', '사용여부', '등록일'];


// 업체관리 > 업체정보
const CompanyInfo = () => {

    // 비즈니스 상태
    const [businessState, setBusinessState] = useState("");
    const handleChangeBusinessState = (event) => {
        setBusinessState(event.target.value);
    };

    // 사용여부
    const [onOff, setOnOff] = useState("");

    const handleChangeOnOff = (event) => {
        setOnOff(event.target.value);
    };

    const [useBest, setUseBest] = useState("");
    const handleChangeUseBest = (event) => {
        setUseBest(event.target.value);
    };

    // 지역명
    const [region, setRegion] = useState("");

    const handleChangeRegion = (event) => {
        setRegion(event.target.value);
    };

    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal, []);

    const showBusinessInfoDialog = () => {
        dispatch(modalAction.addModal({
            id: 'companyInfo1',
            title: '업체추가',
            component: <CompanyInfoDialog1 id="companyInfo1" companyId={null}/>
        }));
    }

    const [checkList, setCheckList] = useState([]);
    const [list, setList] = useState([]);

    const [selectedList, setSelectedList] = useState(new Array(list.length).fill(false));

    const [size, setSize] = useState(1);
    const [page, setPage] = useState(1);
    const [company_name, setCompany_name] = useState("");
    const [totalPage, setTotalPage] = useState(1);

    const [request, setRequest] = useState({
        page: 1,
        size: 12,
        company_name: '',
        status: '',
        is_open: '',
        region: '',
        use_best: ''
    })

    const pageCategory = [
        {id: 1, title: '12개씩 보기', value: 12},
        {id: 2, title: '24개씩 보기', value: 24},
        {id: 3, title: '48개씩 보기', value: 48},
        {id: 4, title: '90개씩 보기', value: 90},
        {id: 5, title: '180개씩 보기', value: 180}
    ];


    useEffect(() => {

        const req = {
            page: page,
            size: pageCategory.find(cate => cate.id == size).value,
            company_name: company_name,
        };

        if (modal.modal.length < 1) {
            dispatch(loadingStatus(true));

            callApi('/company/authlist', 'GET', req).then(function (res) {
                let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
                setCheckList([]);
                setList(res.list);
                setTotalPage(newTotalPage);

                dispatch(loadingStatus(false));
            }).catch((msg) => {
                // alert(msg);
                dispatch(loadingStatus(false));
            });
        }

        return () => {

        }
    }, [modal, size, page]);

    const [excelDownload, setExcelDownload] = useState(false);

    useEffect(() => {
        if (excelDownload) {
            setExcelDownload(false);
        }

    }, [excelDownload]);

    const insertPage = (insertPage) => {
        let newPage = insertPage <= totalPage && insertPage >= 1 ? insertPage : page;
        setCheckList([]);
        setPage(newPage);
    }

    const pageSelect = (type) => {

        let newPage = 0;
        if (type == 'right') {
            newPage = page >= totalPage ? page : page + 1;
        } else {
            newPage = page > 1 ? page - 1 : 1;
        }
        setCheckList([]);
        setPage(newPage);
    }

    const modifyCompany = (id) => {

        dispatch(modalAction.addModal({
            id: 'companyInfo1',
            title: '업체수정',
            component: <CompanyInfoDialog1 id="companyInfo1" companyId={id}/>
        }));
    }

    const deleteBusiness = () => {

        if (checkList.length > 0) {
            if (window.confirm('삭제시 복구가 불가능 합니다.\n삭제하시겠습니까?')) {
                // 선택 삭제
                callApi('/company', 'DELETE', {companyId: checkList}).then(function (delRes) {
                    if (delRes.success) {

                        let req = {
                            page: page,
                            size: pageCategory.find(cate => cate.id == size).value
                        };

                        callApi('/company/authlist', 'GET', req).then(function (res) {
                            let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
                            setList(res.list);
                            setCheckList([]);
                            setTotalPage(newTotalPage)
                        }).catch((msg) => {
                            // alert(msg);
                        });
                    }
                });
            }
        }
    }

    const orderChange = (id, order) => {
        callApi('/company/order_change/' + id + '/' + order, 'GET', {}).then(function (res) {

            if (!res.success)
                return;

            const pageSize = pageCategory.find(cate => cate.id == size).value

            let delList = list.filter((item) => item.id !== res.list[0].id && item.id !== res.list[1].id);
            let newList = delList.concat(res.list);

            // 정렬
            let sortNewList = newList.sort((a, b) => {
                return b.order - a.order;
            });

            if (sortNewList.length > pageSize) {
                if (res.type === 'down')
                    sortNewList.pop();
                else
                    sortNewList.shift();
            }

            setList(sortNewList);
        }).catch((msg) => {
            // alert(msg);
        });
    }

    const orderChangeValue = (e, id, orgOrder) => {

        const order = e.target.value;
        if (e.key === 'Enter') {

            e.target.value = "";

            const req = {
                page: page,
                size: pageCategory.find(cate => cate.id == size).value,
                company_name: company_name,
                status: businessState,
                is_open: onOff === "" ? "" : (onOff === "on" ? 1 : 0),
                region: region,
                use_best: useBest === "" ? "" : (useBest === "on" ? 1 : 0)
            };

            dispatch(loadingStatus(true));
            callApi('/company/order_change_value/' + id + '/' + order, 'GET', req).then(function (res) {

                if (res.success)
                    searchForm();

                // let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
                // setCheckList([]);
                // setList(res.list);
                // setTotalPage(newTotalPage);


                dispatch(loadingStatus(false));
            }).catch((msg) => {
                // alert(msg);
                dispatch(loadingStatus(false));
            });
        }

    }

    // 검색
    const searchForm = () => {
        console.log(onOff);
        console.log(useBest);
        const req = {
            page: page,
            size: pageCategory.find(cate => cate.id == size).value,
            company_name: company_name,
            status: businessState,
            is_open: onOff === "" ? "" : (onOff === "on" ? 1 : 0),
            region: region,
            use_best: useBest === "" ? "" : (useBest === true ? 1 : 0)
        };

        dispatch(loadingStatus(true));

        callApi('/company/authlist', 'GET', req).then(function (res) {
            let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
            setCheckList([]);
            setList(res.list);
            setTotalPage(newTotalPage);

            dispatch(loadingStatus(false));
        }).catch((msg) => {
            // alert(msg);
            dispatch(loadingStatus(false));
        });

        return () => {

        }
    }

    const resetForm = () => {
        setRegion("");
        setOnOff("");
        setUseBest("");
        setCompany_name("");
        setBusinessState("")
    }

    // 모두 체크
    const allCheckList = (e) => {
        if (e.target.checked) {
            const allList = list.map(item => item.id);
            setCheckList(allList);
        } else {
            setCheckList([]);
        }
    }

    // 단일 체크
    const setCheck = (id) => {
        if (checkList.indexOf(id) > -1) {
            const newCheckList = checkList.filter(list => list !== id);
            setCheckList(newCheckList);
        } else {
            setCheckList(checkList.concat(id));
        }
    }


    const companyList = useMemo(() => {

        return (
            <ItemList allCheckList={allCheckList} setCheck={setCheck} checkList={checkList}
                      orderChange={orderChange}
                      orderChangeValue={orderChangeValue}
                      tableHeaderValues={tableHeaderValues}
                      selectedList={selectedList} tableBodyValues={list} onClick={modifyCompany}/>
        )
    }, [selectedList, list, checkList]);

    return (
        <div>
            <Breadcrumb data={breadcrumbData}/>

            <AccordionContainer>
                <Accordion title="검색" boxCount={1}>
                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="업체명">
                                <InputText inputValue={company_name} onChange={setCompany_name} placeholder="입력해주세요"/>
                            </InputBox>
                        </InputBoxWrap>
                        <InputBoxWrap>
                            <InputBox title="상태">
                                <InputSelect optionList={businessStateList} value={businessState} placeholder={'전체'}
                                             onSelectChange={handleChangeBusinessState}/>
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="지역명">
                                <InputSelect optionList={regionList} value={region} placeholder={'전체'}
                                             onSelectChange={handleChangeRegion}/>
                            </InputBox>
                        </InputBoxWrap>
                        <InputBoxWrap>
                            <InputBox title="사용여부">
                                <InputSelect optionList={onOffList} value={onOff} placeholder={'전체'}
                                             onSelectChange={handleChangeOnOff}/>
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="상위 12개">
                                <InputSelect optionList={useBestList} value={useBest} placeholder={'전체'}
                                             onSelectChange={handleChangeUseBest}/>
                            </InputBox>
                        </InputBoxWrap>
                        <InputBoxWrap>
                            <InputBox title="엑셀">
                                {/*<CSVLink*/}
                                {/*    data={excelList}*/}
                                {/*    headers={excelHeaders}*/}
                                {/*    filename={"남양주협회_업체리스트.xls"}*/}
                                {/*    asyncOnClick={true}*/}
                                {/*    onClick={(event, done) => {*/}
                                {/*        readyExcel();*/}
                                {/*        done();*/}
                                {/*    }}>*/}
                                {/*    다운*/}
                                {/*</CSVLink>*/}
                                <Button variant="outlined"
                                        color="primary"
                                        fullWidth={true}
                                        startIcon={<GetApp/>}
                                        href={process.env.REACT_APP_API_HOST + '/v1/excel/company_list'}>
                                    다운로드
                                </Button>
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <ColorButtonWrap>
                        <ColorButton title="검색" size="70%" color="blue" onClick={() => searchForm()}/>
                        <ColorButton title="초기화" size="30%" color="bluejay" onClick={() => resetForm()}/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>

            <AccordionContainer>
                <Accordion title="검색결과" boxCount={1}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        marginBottom: 20
                    }}>
                        <Select optionList={pageCategory} defaultValue={size} onChange={setSize}
                                style={{marginBottom: 0, minWidth: '40%', maxWidth: 160}}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={(value) => insertPage(value)}
                                       style={{
                                           fontSize: 18,
                                           width: 60,
                                           height: 60,
                                           textAlign: 'center'
                                       }}/> / {totalPage}
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>
                    {/*{searchField}*/}
                    <Divider/>

                    {companyList}
                    {/*<ItemList allCheckList={allCheckList} setCheck={setCheck} checkList={checkList}*/}
                    {/*          orderChange={orderChange}*/}
                    {/*          tableHeaderValues={tableHeaderValues}*/}
                    {/*          selectedList={selectedList} tableBodyValues={list} onClick={modifyCompany}/>*/}

                    <Divider/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'top',
                        marginBottom: 30,
                        marginTop: 30
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={(value) => insertPage(value)}
                                       style={{
                                           fontSize: 18,
                                           width: 60,
                                           height: 60,
                                           textAlign: 'center'
                                       }}/> / {totalPage}
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>
                    <ColorButtonWrap>
                        <ColorButton title="삭제" size="50%" color="blue" onClick={() => deleteBusiness()}/>
                        <ColorButton title="업체추가" size="50%" color="bluejay" onClick={showBusinessInfoDialog}/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default CompanyInfo;