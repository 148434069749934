import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDetailBody = styled.div`
    padding: ${props => props.paddingTop} 20px 0;
    max-width: 1080px;
    margin: 0 auto;
`

const DetailBody = (props) => {
    return (
        <StyledDetailBody paddingTop={props.paddingTop}>
            {props.children}
        </StyledDetailBody>
    );
};

DetailBody.propTypes = {
    paddingTop: PropTypes.string
};

DetailBody.defaultProps = {
    paddingTop: "60px"
};

export default DetailBody;