import React, {useEffect, useState} from 'react';
import {DetailContainer, DetailHeader, DetailBody, DetailContentBox} from '../../../detail';
import {InputBox, InputText, InputToggle, InputDate} from '../../../inputs';
import {WysiwygEditor} from '../../../widgets';
import {ColorButton} from '../../../button';
import {stateToHTML} from "draft-js-export-html";
import {callApi} from "../../../../api";
import {actionCreator as modalAction} from "../../../../redux/modules/modal";
import {useDispatch} from "react-redux";
import {ContentState, EditorState} from "draft-js";
import {Cookie} from "../../../../common/Cookie";
import htmlToDraft from "html-to-draftjs";

import {Cancel} from "@material-ui/icons";

const options = {
    inlineStyleFn: (styles) => {
        let key = 'color-';
        let color = styles.filter((value) => value.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: color.replace(key, ''),
                },
            };
        }
    },
};

const NoticeDialog1 = (props) => {

    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [realFileName, setRealFileName] = useState([]);
    // 파일 임시 저장.
    const [tempFileIds, setTempFileIds] = useState([]);
    const [tempFileName, setTempFileName] = useState([]);

    const fileHandler = (e) => {
        const newFiles = e.target.files[0];

        e.target.value = "";

        let method = "POST";
        let url = "/board/upload";

        const formData = new FormData();
        formData.append('file', newFiles);
        if (props.boardId) {
            formData.append('boardId', props.boardId);
            formData.append('fileType', 'board');
        }

        callApi(url, method, formData, true).then(function (res) {
            const fileIds = tempFileIds.concat(res.id);
            const fileNames = tempFileName.concat({id: res.id, fileName: newFiles.name, fileUrl: res.file});
            setTempFileIds(fileIds);
            setTempFileName(fileNames);

        }).catch((msg) => {
            console.log(msg);
            alert(msg);
        });
    }


    const saveData = () => {
        let request = {};

        const admin = Cookie.get('admin_user');

        if (props.boardId !== null) {
            request = {
                isOpen: isOpen,
                title: title,
                content: content !== '' ? stateToHTML(content.getCurrentContent(), options) : '',
                tempFileIds: tempFileIds
            }
        } else {
            request = {
                type: props.type,
                user_id: admin.user_id,
                user_name: admin.userName,
                isOpen: isOpen,
                title: title,
                content: content !== '' ? stateToHTML(content.getCurrentContent(), options) : '',
                tempFileIds: tempFileIds
            }
        }

        let method = props.boardId !== null ? "PUT" : "POST";
        let url = props.boardId !== null ? "/board/" + props.boardId : "/board";

        callApi(url, method, request).then(function (res) {
            if (res.success) {
                window.alert((props.boardId !== null ? '수정' : '저장') + '되었습니다.');
                dispatch(modalAction.delModal('board'));
            }
        }).catch((msg) => {
            console.log(msg);
            alert(msg);
        });
    }

    const deleteData = () => {
        if (!props.boardId)
            return false;

        if (window.confirm('삭제 하시면 복구가 불가능합니다.\n삭제 하시겠습니까?')) {
            let url = "/board/" + props.boardId;

            callApi(url, "DELETE").then(function (res) {
                if (res.success) {
                    window.alert('삭제 되었습니다.');
                    dispatch(modalAction.delModal('board'));
                }
            }).catch((msg) => {
                console.log(msg);
                alert(msg);
            });
        }

    }

    useEffect(() => {
        if (props.boardId !== null) {
            callApi('/board/detail/' + props.boardId, 'GET', {}).then((res) => {

                setTitle(res.data.title);
                setRealFileName(res.data.file)

                setContent(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res.data.content))));
                setIsOpen(res.data.isOpen == 1 ? true : false);
            }).catch((e) => {

            });
        }
    }, []);

    const tempDel = (id) => {
        callApi('/board/upload/' + id + "/temp", 'DELETE', {}).then((res) => {
            if (res) {
                const tempIds = tempFileIds.filter(ids => id != ids);
                const temp = tempFileName.filter(file => id != file.id);

                setTempFileName(temp);
                setTempFileIds(tempIds);
            }
        }).catch((e) => {

        });

    }
    const realDel = (id) => {
        callApi('/board/upload/' + id, 'DELETE', {}).then((res) => {
            if (res) {
                const real = realFileName.filter(file => id != file.id);
                setRealFileName(real);
            }

        }).catch((e) => {

        });
    }

    return (
        <div>
            <DetailBody paddingTop="20px">
                <DetailContentBox>
                    <InputBox title="제목" firstBox>
                        <InputText placeholder="입력해주세요" inputValue={title} onChange={setTitle}/>
                    </InputBox>
                    {
                        realFileName.map((file, key) => {
                            return (
                                <InputBox key={file.id} title={"첨부파일" + (key + 1)}>
                                    <a href={file.file_url} target="_self">{file.real_file_name + "." + file.ext}</a>
                                    <Cancel onClick={() => realDel(file.id)}/>
                                </InputBox>
                            )
                        })
                    }
                    {tempFileName.map((file, key) => (
                        <InputBox key={file.id} title={"첨부파일" + (key + 1)}>
                            <a href={file.fileUrl} target="_self">{file.fileName}</a>
                            <Cancel onClick={() => tempDel(file.id)}/>
                        </InputBox>
                    ))}
                    <InputBox title={'첨부파일'}>
                        <input type="file" multiple onChange={fileHandler}/>
                    </InputBox>
                    <WysiwygEditor contents={content} onChange={setContent}/>
                    <InputBox title="사용여부" toggle>
                        <InputToggle toggleChecked={isOpen} onChange={setIsOpen}/>
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now/>
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title={props.boardId !== null ? '수정' : '작성하기'} color="blue"
                         size={props.boardId !== null ? "70%" : "100%"} onClick={saveData}/>
            {
                props.boardId !== null && (<ColorButton title="삭제" color="bluejay" size="30%" onClick={deleteData}/>)
            }

        </div>
    );
};

export default NoticeDialog1;