import React, {useState} from 'react';
import StarBorder from '@material-ui/icons/StarBorder';
import {makeStyles} from '@material-ui/core/styles';

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from "@material-ui/core";
import {Link} from "react-router-dom";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    listSubItem: {
        paddingLeft: theme.spacing(2),
        backgroundColor: '#7d8594',
        '& span': {
            color: '#dfdfdf'
        },
        '&:hover': {
            background: "#878b93",
        },
    },
    listSubItem2: {
        paddingLeft: theme.spacing(4),
        backgroundColor: '#7d8594',
        '& span': {
            color: '#dfdfdf'
        },
        '&:hover': {
            background: "#878b93",
        },
    },
}));

export const AdminSubMenu = (props) => {
    const classes = useStyles();

    const [opens, setOpens] = useState(props.submenu.length > 0 ? props.submenu.map((s) => false) : []);

    if (props.submenu === undefined || !Array.isArray(props.submenu)) {
        return (
            <div id="menu">
                서브 메뉴 없음.
            </div>
        );
    }

    const listOpen = (idx) => (e) => {
        const newOpens = opens.map((o, oIdx) => {
            if (idx === oIdx)
                return o ? false : true;

            return false;
        });

        setOpens(newOpens);
    }

    return (
        props.submenu.length > 0 && (
            <Collapse in={props.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        props.submenu.map((menu, idx) => (
                            <div key={menu.menuId}>
                                <ListItem button className={classes.listSubItem}
                                          onClick={menu.submenu.length > 0 ? listOpen(idx) : null}
                                          component={Link}
                                          to={menu.submenu.length > 0 ? '#' : menu.link}
                                >
                                    <ListItemIcon>
                                        {/*<StarBorder/>*/}
                                        {/*<img src="/images/icon/notice.png"/>*/}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.text}/>
                                    {
                                        menu.submenu.length > 0 && (
                                            <div>
                                                {opens[idx] ? <ExpandLess style={{color: '#5c5c5c'}}/> :
                                                    <ExpandMore style={{color: '#5c5c5c'}}/>}
                                            </div>
                                        )
                                    }
                                </ListItem>
                                {
                                    menu.submenu.length > 0 && (
                                        <Collapse in={opens[idx]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    menu.submenu.map((submenu, idx) => (
                                                        <ListItem button key={'submenu_' + idx}
                                                                  className={classes.listSubItem2}
                                                                  component={Link}
                                                                  to={submenu.link}
                                                        >
                                                            <ListItemIcon>
                                                                {/*<StarBorder/>*/}
                                                            </ListItemIcon>
                                                            <ListItemText primary={submenu.text}/>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Collapse>
                                    )
                                }
                            </div>
                        ))
                    }
                </List>
            </Collapse>
        )
    );
};