import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAddButton = styled(Button)`
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 60px;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    margin-bottom: 20px;
    
    &:hover {
        background-color: #f2f2f2;
    }
`;

const AddButton = ({title, onClick}) => {
    return (
        <StyledAddButton disableRipple onClick={onClick}>+ {title}</StyledAddButton>
    );
};

AddButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func
};

export default AddButton;