import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDownArrowIcon = styled.svg`
    width: 17px;
    height: 10px;
    position: absolute;
    right: ${props => props.right};
    pointer-events: none;
`;

const DownArrowIcon = (props) => {
    return (
        <StyledDownArrowIcon right={props.right}>
            <path fillRule="evenodd"  fill="rgb(62, 71, 92)"
                  d="M16.659,2.062 L8.881,9.840 L8.563,9.523 L8.245,9.840 L0.466,2.062 L1.881,0.648 L8.563,7.330 L15.245,0.648 L16.659,2.062 Z"/>
        </StyledDownArrowIcon>
    );
}

DownArrowIcon.propTypes = {
    right: PropTypes.string
};

DownArrowIcon.defaultProps = {
    right: "23px"
};

export default DownArrowIcon;