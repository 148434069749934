import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DetailContainer from '../detail/DetailContainer';
import DetailHeader from '../detail/DetailHeader';
import DetailBody from '../detail/DetailBody';
import DetailContentBox from '../detail/DetailContentBox';
import ColorButton from "../button/ColorButton";
import InputBox from '../inputs/InputBox';
import InputText from '../inputs/InputText';
import InputDate from '../inputs/InputDate';
import InputToggle from '../inputs/InputToggle';

import styled from 'styled-components';
import TextLink from '../inputs/TextLink';
import InputTextarea from '../inputs/InputTextarea';

const ProfileContainer = styled.div`
    height: 80px;
    margin-bottom: 30px;
    position: relative;
    margin-top: -30px;
`

const ProfileBox = styled.div`
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
`

const ProfileWrap = styled.div`
     position: relative;
     width: 100px;
     height: 100px;
`

const StyledProfile = styled.a`
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px solid #aaa;
    border-radius: 50%;
    overflow: hidden;
`

const StyledProfileIcon = styled.a`
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
`

// 멤버추가
const Template7 = (props) => {
    const [show, setShow] = useState(true);

    const handleClick = () => {
        show ? setShow(false) : setShow(true);
    }

    useEffect(() => {
        setShow(props.show);
    }, [props]);

    return (
        <DetailContainer className={show ? 'show' : ''}>
            <DetailHeader onClick={handleClick} title="멤버추가" />
            <DetailBody>
                <DetailContentBox>
                    <div className="title">개인정보</div>

                    <ProfileContainer>
                        <ProfileBox>
                            <ProfileWrap>
                                <StyledProfile href="">
                                    <img src="/images/test/lim_profile.jpg" alt="lim_profile" />
                                </StyledProfile>
                                <StyledProfileIcon href="">
                                    <img src="/images/icon/profile_icon.png" alt="profile_icon" />
                                </StyledProfileIcon>
                            </ProfileWrap>
                        </ProfileBox>
                    </ProfileContainer>

                    <InputBox title="이름" firstBox>
                        <InputText placeholder="입력해주세요" />
                    </InputBox>
                    <InputBox title="생년월일">
                        <TextLink text="1981년 3월 29일" />
                    </InputBox>
                    <InputBox title="성별" toggle>
                        <InputToggle toggleChecked toggleOnText="'남'" toggleOffText="'여'" />
                    </InputBox>
                </DetailContentBox>
                <DetailContentBox>
                    <div className="title">로그인 정보</div>
                    <InputBox title="핸드폰 번호" firstBox>
                        +82 10-5150-5628
                    </InputBox>
                    <InputBox title="이메일">
                        gozjun@naver.com
                    </InputBox>
                    <InputBox title="비밀번호">
                        <TextLink text="************" />
                    </InputBox>
                    <InputBox title="카카오 로그인" toggle>
                        <InputToggle toggleChecked />
                    </InputBox>
                    <InputBox title="네이버 로그인" toggle>
                        <InputToggle toggleChecked />
                    </InputBox>
                    <InputBox title="페이스북 로그인" toggle>
                        <InputToggle toggleChecked />
                    </InputBox>
                    <InputBox title="구글 로그인" toggle>
                        <InputToggle toggleChecked />
                    </InputBox>
                </DetailContentBox>
                <DetailContentBox>
                    <div className="title">기타정보</div>
                    <InputBox title="비고" textarea>
                        <InputTextarea placeholder="입력해주세요" />
                    </InputBox>
                    <InputBox title="가입경로">
                        펜션나라
                    </InputBox>
                    <InputBox title="가입일">
                        <TextLink text="2020년 3월 29일 / 16시 10분 59초" />
                    </InputBox>
                    <InputBox title="퇴사일">
                        <TextLink text="2021년 4월 29일 / 16시 10분 59초" />
                    </InputBox>
                </DetailContentBox>
                <DetailContentBox>
                    <div className="title">상태정보</div>
                    <InputBox title="사용여부" firstBox toggle>
                        <InputToggle toggleChecked />
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now />
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="저장" color="blue" />
        </DetailContainer>
    );
};

Template7.propTypes = {
    show: PropTypes.bool
};

export default Template7;