import React from 'react';
import styled from 'styled-components';
import RightArrowIcon from '../icons/RightArrowIcon';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
    width: 100%;
    height: 58px;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    background: none;
    position: relative;
`

const TextLink = (props) => {
    return (
        <StyledButton>
            {props.text}
            <RightArrowIcon right={0} />
        </StyledButton>
    );
};

TextLink.propTypes = {
    text: PropTypes.string
};

export default TextLink;