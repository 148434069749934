import React, {useState} from 'react';
import {DetailContainer, DetailHeader, DetailBody, DetailContentBox} from '../../../detail';
import {ColorButton} from '../../../button';
import {InputBox, InputDate} from '../../../inputs';
import styled from 'styled-components';
import {File} from '../../../Communication';

const TextareaWrap = styled.div`
    height: 300px;
    
    & > textarea {
        width: 100%;
        height: 300px;
        resize: none;
        border: 1px solid #dfdfdf;
        outline: none;
        padding: 20px;
        font-size: 18px;
    }
`;

const ReplyDiv = styled.div`
    width: 100%;
    padding: 20px;
    background: #fff;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    margin-top: -1px;
    font-size: 18px;
    
    & > div {
      line-height: 28px;
    }
      
    &.is-file-false {
        border-bottom: 1px solid #dfdfdf;
    }
`;

// 원본메세지
const apiOriginalMessage = {
    text: "[필수정보]<br />- 업체명 : 마린테라스<br />- 수정금액 : 5,000원<br />- 문의 내용 : 수정 부탁드립니다."
}

// 첨부파일
const apiAttachedFile = {
    fileName: "",
    fileUrl: ""
};

// 제휴문의 > 답변쓰기
const PartnershipDialog2 = (props) => {
    const [show, setShow] = useState(props.show);
    const handleClick = () => {
        show ? setShow(false) : setShow(true);
    };

    // 첨부파일
    const [attachedFile, setAttachedFile] = useState(apiAttachedFile);
    // 존재여부
    const isAttachedFile = attachedFile.fileName !== '' ? true : false;


    return (
        <DetailContainer className={show ? 'show' : ''}>
            <DetailHeader onClick={handleClick} title="제휴문의" />
            <DetailBody paddingTop="20px">
                <DetailContentBox>
                    <TextareaWrap>
                        <textarea placeholder="내용을 입력해주세요"></textarea>
                    </TextareaWrap>
                    <ReplyDiv>
                        ----------------------- 원본 메세지 --------------------------
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html: apiOriginalMessage.text}}></div>
                    </ReplyDiv>

                    <File isAttachedFile={isAttachedFile} attachedFile={attachedFile} className={isAttachedFile ? '' : 'is-file-false'} />

                    <InputBox title="등록일">
                        <InputDate now />
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="저장" color="blue" size="70%" />
            <ColorButton title="삭제" color="bluejay" size="30%" />
        </DetailContainer>
    );
};

export default PartnershipDialog2;