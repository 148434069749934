// actions
const SET_LOADING = "SET_LOADING";

// action creators
const setLoading = (loading) => {
    return {
        type: SET_LOADING,
        loading
    };
};

// api actions
export const loadingStatus = (loading) => {
    return (dispatch, getState) => {
        dispatch(setLoading(loading));
    };
};


// Initial State

const initialState = {
    loading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return applySetLoading(state, action);
        default:
            return state;
    }
};

// Reducer Funcitons

const applySetLoading = (state, action) => {
    const {loading} = action;

    return {
        ...state,
        loading
    };
};

// exports
// const actionCreator = {
//     loadStatus
// };
//
// export {actionCreator};

export default reducer;