import React, {useEffect, useState} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import LinkButton from '../button/LinkButton';
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import BasicCodeDialog1 from "../templates/namyang/basicManagement/BasicCodeDialog1";
import {callApi} from "../../api";
import NewList from "../Communication/NewList";


const SortableItem = SortableElement(
    ({value}) => {
        return (
            <LinkButton title={value.title}
                        isOn={value.isOn}
                        isHeadquarters={value.isHeadquarters}
                        thumbnail={value.thumbnail}
            />
        );
    }
);

const SortableList = SortableContainer(
    ({items}) => {
        return (
            <div>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value.id}`} index={index} value={value}/>
                ))}
            </div>
        );
    }
);

const Sortable = (props) => {
    // const [items, setItems] = useState(props.itemList.sort((a, b) => {
    //     return a.order - b.order;
    // }));
    const [items, setItems] = useState([]);

    const onSortEnd = (oldIndex) => {
        const newItems = arrayMove(items, oldIndex['oldIndex'], oldIndex['newIndex']);
        setItems(newItems);
    };

    useEffect(() => {
        setItems(props.itemList);
    }, [])

    console.log('소트');
    console.log(items);

    // if (items.length < 1) {
    //     return (
    //         <div>없음.</div>
    //     );
    // }

    return (
        <SortableList
            items={items}
            onSortEnd={onSortEnd}
            lockAxis='y'
            lockToContainerEdges
            pressDelay={200}
            helperClass="sortstart"
        />
    );
};

Sortable.propTypes = {
    itemList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            order: PropTypes.number,
            title: PropTypes.string,
            isOn: PropTypes.bool,
            thumbnail: PropTypes.string,
            isHeadquarters: PropTypes.bool
        }).isRequired,
    )
};

export default Sortable;

// 소통관리
const StyledSortableListCommunication = styled.div`
    border: 1px solid #dfdfdf;
`;

const SortableItemCommunication = SortableElement(
    ({value, firstBox}) => (
        <NewList value={value} firstBox={firstBox}/>
    )
);

const SortableListCommunication = SortableContainer(
    ({items}) => {
        return (
            <StyledSortableListCommunication>
                {
                    items.map((value, index) => (
                        <SortableItemCommunication
                            key={index}
                            index={index}
                            value={value}
                            firstBox={index === 0 ? true : false}
                        />
                    ))
                }
            </StyledSortableListCommunication>
        )
    }
);

export const SortableCommunication = (props) => {
    const [items, setItems] = useState(props.itemList);

    const onSortEnd = (oldIndex) => {
        const newItems = arrayMove(items, oldIndex['oldIndex'], oldIndex['newIndex']);
        setItems(newItems);
    };

    // console.log('리스트')
    // console.log(props.itemList)
    // console.log(items)
    return (
        <SortableListCommunication
            items={props.itemList}
            onSortEnd={onSortEnd}
            lockAxis="y"
            lockToContainerEdges
            pressDelay={200}
            helperClass="sortstart"
        />
    );
};