import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import Sortable from '../interactions/Sortable';
import DragInfoText from '../texts/DragInfoText';
import Select from '../inputs/Select';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "기초코드",
        link: ""
    }
];

const AccordionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 2,
        title: "직급",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 1,
        title: "부서",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "진행상황",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    }
    ,
    {
        id: 4,
        order: 4,
        title: "재직여부",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    }
    ,
    {
        id: 5,
        order: 5,
        title: "경고문구",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

const codeNameList = [
    {
        id: 1,
        order: 1,
        title: "기획팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 2,
        order: 2,
        title: "운영팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 3,
        order: 3,
        title: "개발팀",
        isOn: true,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 4,
        order: 4,
        title: "디자인팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 5,
        order: 5,
        title: "영업팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    },
    {
        id: 6,
        order: 6,
        title: "촬영팀",
        isOn: false,
        thumbnail: null,
        isHeadquarters: false
    }
];

// 기초코드
const Template3 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="1차 분류" boxCount={2}>
                    <AddButton title="1차 분류추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>

                <Accordion title="코드이름" boxCount={2}>
                    <Select optionList={firstCategory} />
                    <AddButton title="코드추가" />
                    <Sortable itemList={codeNameList} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template3;