import React from 'react';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import App from './shared/App';
import {ConnectedRouter} from 'connected-react-router';
import store, {history} from './redux/configureStore';
import {ToastContainer} from "react-toastify";
import ModalView from "./components/modal/ModalView";
import {Loading as LoadingView} from "./components/Loading";

ReactDom.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <LoadingView/>
            <App/>
            <ModalView/>
            <ToastContainer/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
);