import React, {useState} from 'react';
import PropTypes from 'prop-types';

const InputEmail = (props) => {

    const onChangeValue = e => {
        props.onChange(e.target.value);
    };

    return (
        <input type="email" placeholder={props.placeholder} defaultValue={props.inputValue} onChange={onChangeValue} />
    );
};

InputEmail.defaultProps = {
    placeholder: '___@___',
    inputValue: ''
};

InputEmail.propTypes = {
    placeholder: PropTypes.string,
    inputValue: PropTypes.string
};

export default InputEmail;