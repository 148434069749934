import React, {useEffect, useState, createRef} from 'react';
import {DetailBody, DetailContainer, DetailContentBox, DetailHeader} from '../../../detail';
import PropTypes from 'prop-types';
import {ColorButton, ImageAddButtonWrap, ImageAddButton, AddButton} from '../../../button';
import {
    InputBox,
    InputEmail,
    InputNumber,
    InputSelect,
    InputText,
    InputTextarea,
    TextBox,
    InputToggle,
    InputDate,
    CheckBox,
    CheckBoxContainer
} from '../../../inputs';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import styled from 'styled-components';
import {WysiwygEditor} from '../../../widgets';
import {callApi} from "../../../../api";
import {ImgLoad} from "../../../icons/ImgLoad";

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

import {EditorState, ContentState} from "draft-js"
import {useStateWithCallbackLazy} from "use-state-with-callback";
import {actionCreator as modalAction} from "../../../../redux/modules/modal";
import {useDispatch} from "react-redux";
import {loadingStatus} from "../../../../redux/modules/loading";

// 1차 분류
const firstCategoryList = [
    {
        id: 1,
        order: 1,
        value: "숙박",
        title: "숙박",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "농장",
        title: "농장",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "딸기",
        title: "딸기",
        isOn: true
    }
];


// 펜션 스타일
const pensionCate1 = [
    {
        id: 1,
        order: 1,
        title: '개별바베큐장',
        isChecked: false
    },
    {
        id: 19,
        order: 19,
        title: '바베큐',
        isChecked: false
    },
    {
        id: 2,
        order: 2,
        title: '카페',
        isChecked: false
    },
    {
        id: 3,
        order: 3,
        title: '캠핑',
        isChecked: false
    },
    {
        id: 4,
        order: 4,
        title: '커플',
        isChecked: false
    },
    {
        id: 5,
        order: 5,
        title: '가족',
        isChecked: false
    },
    {
        id: 6,
        order: 6,
        title: '족구',
        isChecked: false
    },
    {
        id: 7,
        order: 7,
        title: '단체',
        isChecked: false
    },
    {
        id: 8,
        order: 8,
        title: '키즈컨셉',
        isChecked: false
    },
    {
        id: 9,
        order: 9,
        title: '잔디운동장',
        isChecked: false
    },
    {
        id: 10,
        order: 10,
        title: '반려동물가능',
        isChecked: false
    },
    {
        id: 11,
        order: 11,
        title: '수영장',
        isChecked: false
    },
    {
        id: 12,
        order: 12,
        title: '풀빌라/풀빌라독채',
        isChecked: false
    },
    {
        id: 20,
        order: 20,
        title: '리버뷰',
        isChecked: false
    },
    {
        id: 13,
        order: 13,
        title: '노래방',
        isChecked: false
    },
    {
        id: 14,
        order: 14,
        title: '독채형',
        isChecked: false
    },
    {
        id: 15,
        order: 15,
        title: '계곡',
        isChecked: false
    },
    {
        id: 16,
        order: 16,
        title: '월풀/스파',
        isChecked: false
    },
    // {
    //     id: 17,
    //     order: 17,
    //     title: '스파',
    //     isChecked: false
    // },
    {
        id: 18,
        order: 18,
        title: '워크샵',
        isChecked: false
    },
    {
        id: 21,
        order: 21,
        title: '협회인증',
        isChecked: false
    },
];

const regionList = [
    {
        id: 2,
        order: 2,
        value: "수동",
        title: "수동면",
        isChecked: false
    },
    {
        id: 3,
        order: 3,
        value: "오남",
        title: "오남읍",
        isChecked: false
    },
    {
        id: 4,
        order: 4,
        value: "조안",
        title: "조안면",
        isChecked: false
    },
    {
        id: 5,
        order: 5,
        value: "진접",
        title: "진접읍",
        isChecked: false
    },
    {
        id: 6,
        order: 7,
        value: "화도",
        title: "화도읍",
        isChecked: false
    }
];


// 펜션 스타일
const pensionCate2 = [];

// 펜션 테마
const pensionCate3 = [];


// 상태정보
const pensionStateList = [
    {
        id: 1,
        order: 1,
        value: "대기",
        title: "대기",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "승인",
        title: "승인",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "보류",
        title: "보류",
        isOn: true
    }
];


// 이미지 드래그 스타일
const SortableListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 10px);
    
     @media (max-width: 720px) {    
        width: 100%;
        
        & > div:nth-child(even) {
            margin: 0 0 20px 10px; 
        }
        
        & > div:nth-child(odd) {
            margin: 0 10px 20px 0; 
        }
    }
`;

const SortableItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    user-select: none;
    
    position: relative;
    margin: 10px;
    
    &:before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    width: calc(33.33% - 20px);

    @media (max-width: 720px) {    
        width: calc(50% - 20px);
    }

    @media (max-width: 576px) {    
        width: 100%;
        margin: 10px 0 !important;
    }
`;

const SortableItemInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: grab;
    overflow:hidden;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`;

const SortableMenuButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    
    & span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3e475c;
        display: inline-block;
        margin: 0 1.5px;
        z-index: 3;
        pointer-events: none;
    }
`;

const SortableMenu = styled.div`
    display: none;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50px;
    right: 20px;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    
    & .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      z-index: 5;
      user-select: none;
    }
    
    & .menu-item:hover {
      background-color: #dfdfdf !important;
    }
`;

const TextBoxContainer = styled.div`
    margin-bottom: 20px;
`;


const options = {
    inlineStyleFn: (styles) => {
        let key = 'color-';
        let color = styles.filter((value) => value.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: color.replace(key, ''),
                },
            };
        }
    },
};

const CompanyDialog1 = (props) => {

    const dispatch = useDispatch();


    // 이미지 order 오름차순 정렬
    const [imageList, setImageList] = useState([]);

    // 이미지 파일 form
    // this.inputOpenFileRef = React.createRef()
    const [inputOpenFile, setInputOpenFile] = useState(() => createRef());
    const [inputOpenFileModify, setInputOpenFileModify] = useState(() => createRef());
    // 이미지 추가
    const handleClickImageAdd = (event) => {
        console.log('image add click');
        inputOpenFile.current.click();
    };

    // 수정용 파일 아이디 생성.
    const [modifyFile, setModifyFile] = useStateWithCallbackLazy({id: null, fileType: null});

    // 이미지 수정
    const handleClickImageModify = (id, fileType) => {

        setModifyFile({id: id, fileType: fileType}, (state) => {
            inputOpenFileModify.current.click();
        });
        // inputOpenFileModify.current.click();
    }

    // 이미지 삭제
    const handleClickImageDelete = (id, fileType) => {
        // 삭제 처리 api call

        callApi('/company/upload/' + id + '/' + fileType, 'DELETE', {}).then((res) => {
            setImageList(imageList.filter(list => list.id !== id && list.type !== fileType));
            console.log(res);
            console.log(imageList);
        });

        // 컴포넌트 초기화
        // setSrc(null);
        // setIsSrc(false);
        // setShowMenu(false);
    }

    // 파일 임시 저장.
    const [tempFileIds, setTempFileIds] = useState([]);

    const imgDelay = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve("success");
            }, 400);
        });
    }

    // 이미지 파일 업로드
    const handleChangeImageFile = (event) => {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.files.length > 0) {
            dispatch(loadingStatus(true));
            const maxOrder = Math.max(...imageList.map(o => o.order), 0) + 1;

            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('order', maxOrder);
            if (props.companyId)
                formData.append('companyId', props.companyId);

            callApi('/company/upload/image', 'POST', formData, true).then((res) => {
                if (!res)
                    return;

                let tempFile = {
                    id: res.id, // 자동증가값 (db) 이미지만 파일 컨트롤 할때 필요하므로 직접 만듬
                    order: maxOrder, // 마지막 order 가져오기?
                    src: res.thumb, // 이미지 fullPath    '/images/test/' + file.name
                    alt: '이미지_' + res.id,
                    fileType: res.fileType // temp 임시 파일 / image 리얼 파일 (수정시 사용)
                };

                let tempImageList = [];
                const tempFileId = res.id;

                imgDelay().then(res => {
                    if (res === 'success') {
                        setTempFileIds(tempFileIds.concat(tempFileId));
                        setImageList(imageList => tempImageList.concat(...imageList, tempFile));
                    }

                    dispatch(loadingStatus(false));
                });
            }).catch((err) => {
                dispatch(loadingStatus(false));
                console.log(err);
            });
        }
    };

    // 이미지 파일 수정
    const handleChangeImageModify = (event) => {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.files.length > 0) {
            dispatch(loadingStatus(true));
            const maxOrder = Math.max(...imageList.map(o => o.order), 0) + 1;

            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('fileType', modifyFile.fileType);
            if (props.companyId)
                formData.append('companyId', props.companyId);

            console.log(formData);

            callApi('/company/upload/' + modifyFile.id + '/' + (props.companyId ? 'image' : 'temp'), 'POST', formData, true).then((res) => {

                let tempFile = {
                    id: res.id, // 자동증가값 (db) 이미지만 파일 컨트롤 할때 필요하므로 직접 만듬
                    order: maxOrder, // 마지막 order 가져오기?
                    src: res.thumb, // 이미지 fullPath    '/images/test/' + file.name
                    alt: '이미지_' + res.id,
                    fileType: props.companyId ? 'image' : 'temp' // temp 임시 파일 / image 리얼 파일 (수정시 사용)
                };

                imgDelay().then(res => {
                    if (res === 'success') {
                        setTempFileIds(tempFileIds.map(id => {
                            return id === modifyFile.id ? tempFile.id : id;
                        }));
                        setImageList(imageList.map(list => {
                            return list.id === modifyFile.id ? tempFile : {...list};
                        }));
                    }

                    dispatch(loadingStatus(false));
                });
            }).catch((err) => {
                console.log(err);
                dispatch(loadingStatus(false));
            });
        }
    };

    // 이미지 드래그 (Sortable)
    const onSortStart = () => {
        document.body.style.cursor = 'grabbing';
    };

    const onSortEnd = (oldIndex) => {
        const newItems = arrayMove(imageList, oldIndex['oldIndex'], oldIndex['newIndex']);
        setImageList(newItems);
        const imgList = newItems.map(item => item.id);

        setTempFileIds(imgList);

        // 변경
        // if(props.companyId !== undefined){
        //     const orderChangeUrl = "/company/image_order_change/" + props.companyId;
        //
        //     callApi(orderChangeUrl, 'POST', imgList).then((res) => {
        //
        //     }).catch((err) => {
        //         alert('이미지 정렬에 실패했습니다.');
        //     });
        // }

        document.body.style.cursor = 'default';
    };

    const shouldCancelStart = (event) => {
        // 메뉴 버튼 클릭 시 드래그 이벤트 취소
        if ((event.target.className).indexOf('sortablemenu') !== -1) {
            return true;
        }

        // 메뉴 수정, 삭제 버튼 클릭 시 드래그 이벤트 취소
        if ((event.target.className).indexOf('menu-item') !== -1) {
            return true;
        }
    };

    const handleClickMenu = (event) => {
        // setShowMenu(showMenu ? false : true);

        // 열려있는 메뉴 닫기
        let allShowClass = document.getElementsByClassName('showmenu');
        let length = allShowClass.length;

        let clickId = event.target.id.replace('sortablemenu', 'showmenu');
        for (let i = 0; i < length; i++) {
            if (allShowClass[i].id === clickId) {
                // 열려있는 메뉴 클릭 시 닫기
                if (document.getElementById(clickId).style.display === 'flex') {
                    allShowClass[i].style.display = "none";
                } else {
                    document.getElementById(clickId).style.display = "flex";
                }
            } else {
                allShowClass[i].style.display = "none";
            }
        }
    };

    const SortableItem = SortableElement(
        ({value}) => (
            <SortableItemContainer>
                <SortableItemInner>
                    <ImgLoad src={value.src} alt={value.alt}/>
                    {/*<img src={value.src} alt={value.alt}/>*/}
                    <SortableMenuButton onClick={handleClickMenu} id={'sortablemenu' + value.id}
                                        className="sortablemenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </SortableMenuButton>
                    <SortableMenu id={'showmenu' + value.id} className="showmenu">
                        <div className="menu-item"
                             onClick={() => handleClickImageModify(value.id, value.fileType)}>수정
                        </div>
                        <div className="menu-item"
                             onClick={() => handleClickImageDelete(value.id, value.fileType)}>삭제
                        </div>
                    </SortableMenu>
                </SortableItemInner>
            </SortableItemContainer>
        )
    );

    const SortableList = SortableContainer(
        ({items}) => {

            return (
                <SortableListContainer>
                    {items.map((value, index) => (
                        <SortableItem key={index} index={index} value={value}/>
                    ))}
                    <input ref={inputOpenFile} type="file" style={{display: "none"}}
                           onChange={handleChangeImageFile}/>
                    <input ref={inputOpenFileModify} type="file" style={{display: "none"}}
                           onChange={handleChangeImageModify}/>
                    <ImageAddButton onClick={handleClickImageAdd}/>
                </SortableListContainer>
            )
        }
    );

    // 1차 분류
    const [firstCategory, setFirstCategory] = useState('숙박');
    const handleChangeFirstCategory = (event) => {
        setFirstCategory(event.target.value);
    };

    // 테마 카테고리 (체크박스)
    const [categoryList1, setCategoryList1] = useState(pensionCate1);
    const [categoryList2, setCategoryList2] = useState(pensionCate2);
    const [categoryList3, setCategoryList3] = useState(pensionCate3);
    const handleChangeCate = (type) => (event) => {

        switch (type) {
            case 'cate1':
                setCategoryList1(
                    categoryList1.map(list => list.id == event.target.getAttribute('data-id') ? {
                        ...list,
                        isChecked: event.target.checked
                    } : list)
                );
                break;
            case 'cate2':
                setCategoryList2(
                    categoryList2.map(list => list.id == event.target.getAttribute('data-id') ? {
                        ...list,
                        isChecked: event.target.checked
                    } : list)
                );
                break;
            case 'cate3':
                setCategoryList3(
                    categoryList3.map(list => list.id == event.target.getAttribute('data-id') ? {
                        ...list,
                        isChecked: event.target.checked
                    } : list)
                );
                break;
            default:
                break
        }
    };

    // 상태정보
    const [pensionState, setPensionState] = useState("대기");
    const handleChangePensionState = (event) => {
        setPensionState(event.target.value);
    };

    // 모바일일 경우 이미지 pressDelay 값 설정
    const [pressDelay, setPressDealy] = useState(0);
    useEffect(() => {
        if (window.innerWidth < 577) {
            setPressDealy(200);
        }

        if (props.companyId !== null) {
            dispatch(loadingStatus(true));
            callApi('/company/detail/' + props.companyId, 'GET', {}).then((res) => {

                setFirstCategory(res.type);

                if (res.cate1.length > 0) {
                    const cate1 = categoryList1.map(list => {
                        let isChecked = false;
                        res.cate1.forEach((cate) => {
                            if (cate.category == list.title)
                                isChecked = true;
                        });
                        return {...list, ...{isChecked: isChecked}};
                    });

                    setCategoryList1(cate1);
                }

                if (res.cate2.length > 0) {
                    const cate2 = categoryList2.map(list => {
                        let isChecked = false;
                        res.cate2.forEach((cate) => {
                            if (cate.category == list.title)
                                isChecked = true;
                        });
                        return {...list, ...{isChecked: isChecked}};
                    });

                    setCategoryList2(cate2);
                }

                if (res.cate3.length > 0) {
                    const cate3 = categoryList3.map(list => {
                        let isChecked = false;
                        res.cate3.forEach((cate) => {
                            if (cate.category == list.title)
                                isChecked = true;
                        });
                        return {...list, ...{isChecked: isChecked}};
                    });

                    setCategoryList3(cate3);
                }

                if (res.region.length > 0) {
                    const newRegion = region.map(list => {
                        let isChecked = false;
                        res.region.forEach((val) => {
                            if (val == list.title)
                                isChecked = true;
                        });
                        return {...list, ...{isChecked: isChecked}};
                    });

                    setRegion(newRegion);
                }

                setCompanyName(res.companyName);
                setHomepage(res.homepage);
                setReservationLink(res.reservationLink);
                setPhone(res.phone);
                setEmail(res.email);
                setAddress(res.address);
                setAddressDetail(res.addressDetail);
                setLocation(res.location);
                setDescContents(res.desc);
                setParking(res.parking);
                setInDate(res.inDate);
                setOutDate(res.outDate);
                setFarmInfo(res.farmInfo);
                setPensionState(res.status);
                setPrice(res.price);
                setSalePrice(res.salePrice);
                setUse(res.use === 1 ? true : false);
                setUseBest(res.useBest === 1 ? true : false);
                // console.log(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res.descDetail))));

                const { contentBlocks, entityMap } = htmlToDraft(
                    res.descDetail.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') // Delete <figure> tag that causes DraftJS error
                );
                const contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                );

                setDescDetail(EditorState.createWithContent(contentState));
//<figure>&nbsp;</figure>
                setImageList(
                    res.images.map((img) => {
                        return {
                            id: img.id,
                            order: img.order,
                            src: img.thumb_url,
                            alt: '이미지_' + res.id,
                            fileType: 'image'
                        }
                    })
                );

                dispatch(loadingStatus(false));
            }).catch((err) => {
                dispatch(loadingStatus(false));
            });
        }
    }, []);

    /*
    * Form State
    * */

    const [companyName, setCompanyName] = useState('');
    const [homepage, setHomepage] = useState('');
    const [reservationLink, setReservationLink] = useState('');
    const [descContents, setDescContents] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [parking, setParking] = useState('');
    const [inDate, setInDate] = useState('');
    const [outDate, setOutDate] = useState('');
    const [price, setPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [descDetail, setDescDetail] = useState('');
    const [farmInfo, setFarmInfo] = useState('');
    const [use, setUse] = useState(false);
    const [useBest, setUseBest] = useState(false);

    // 지역명
    const [region, setRegion] = useState(regionList);

    const handleChangeRegion = (event) => {
        setRegion(
            region.map(list => list.id == event.target.getAttribute('data-id') ? {
                ...list,
                isChecked: event.target.checked
            } : list)
        );
    };

    // const contentBlock = htmlToDraft(html);
    // if (contentBlock) {
    //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //     editorStateInitial = EditorState.createWithContent(contentState);
    // }
    //
    // const [editorState, setEditorState] = React.useState(editorStateInitial);

    // 전송
    const saveData = () => {

        dispatch(loadingStatus(true));

        const imageOrderIds = imageList.map(item => item.id);

        const request = {
            type: firstCategory,
            status: pensionState,
            use: use,
            useBest: useBest,
            cate1: categoryList1.filter(list => list.isChecked),
            cate2: categoryList2.filter(list => list.isChecked),
            cate3: categoryList3.filter(list => list.isChecked),
            region: region.filter(list => list.isChecked).map(list => list.title).join('|'),
            companyName: companyName,
            homepage: homepage,
            reservationLink: reservationLink,
            email: email,
            desc: descContents,
            address: address,
            addressDetail: addressDetail,
            phone: phone,
            location: location,
            parking: parking,
            inDate: inDate,
            outDate: outDate,
            price: price,
            salePrice: salePrice,
            farmInfo: farmInfo,
            descDetail: descDetail ? stateToHTML(descDetail.getCurrentContent(), options) : '',
            tempFileIds: tempFileIds.sort((a, b) => {
                // order 오름차순 정렬
                return a.order - b.order;
            }),
            imageOrder: imageOrderIds
        }

        console.log(request);

        let method = props.companyId !== null ? "PUT" : "POST";
        let url = props.companyId !== null ? "/company/" + props.companyId : "/company";

        callApi(url, method, request).then(function (res) {
            if (res.success) {
                window.alert((props.companyId !== null ? '수정' : '저장') + '이 완료되었습니다.');
                dispatch(modalAction.delModal('companyInfo1'));
            }

            dispatch(loadingStatus(false));
        }).catch((msg) => {
            // alert(msg);
            dispatch(loadingStatus(false));
        });
    }

    const deleteData = () => {
        if (!props.companyId)
            return false;

        if (window.confirm('삭제 하시면 복구가 불가능합니다.\n삭제 하시겠습니까?')) {
            let url = "/company/" + props.companyId;
            dispatch(loadingStatus(true));
            callApi(url, "DELETE").then(function (res) {
                if (res.success) {
                    window.alert('삭제 되었습니다.');
                    dispatch(modalAction.delModal('companyInfo1'));
                }

                dispatch(loadingStatus(false));
            }).catch((msg) => {
                // alert(msg);

                dispatch(loadingStatus(false));
            });
        }
    }

    return (
        <div>
            <DetailBody>
                <DetailContentBox>
                    <div className="title">펜션 스타일</div>
                    <CheckBoxContainer>
                        {
                            categoryList1.map((value, index) => {
                                return (
                                    <CheckBox key={index} id={value.id} title={value.title}
                                              onChange={handleChangeCate('cate1')}
                                              checked={value.isChecked}/>
                                )
                            })
                        }
                    </CheckBoxContainer>
                </DetailContentBox>

                <DetailContentBox>
                    <div className="title">지역분류</div>
                    <CheckBoxContainer>
                        {
                            region.map((value, index) => {
                                return (
                                    <CheckBox key={index} id={value.id} title={value.title}
                                              onChange={handleChangeRegion}
                                              checked={value.isChecked}/>
                                )
                            })
                        }
                    </CheckBoxContainer>
                </DetailContentBox>

                <DetailContentBox>
                    <div className="title">이미지</div>
                    <ImageAddButtonWrap>
                        <SortableList
                            items={imageList}
                            onSortStart={onSortStart}
                            onSortEnd={onSortEnd}
                            shouldCancelStart={shouldCancelStart}
                            axis='xy'
                            lockToContainerEdges
                            helperClass="SortableHelper"
                            pressDelay={pressDelay}
                        />
                    </ImageAddButtonWrap>
                </DetailContentBox>

                <DetailContentBox>
                    <div className="title">기본정보</div>
                    {/*<InputBox title="지역분류" firstBox>*/}
                    {/*    <InputSelect optionList={regionList} onSelectChange={handleChangeRegion} placeholder={'선택'}*/}
                    {/*                 value={region}/>*/}
                    {/*</InputBox>*/}
                    <InputBox title="업체명" firstBox>
                        <InputText placeholder="입력해주세요" inputValue={companyName} onChange={setCompanyName}/>
                    </InputBox>
                    <InputBox title="실시간달력">
                        <InputText placeholder="http:// 를 포함하여 입력하세요." inputValue={reservationLink}
                                   onChange={setReservationLink}/>
                    </InputBox>
                    <InputBox title="홈페이지">
                        <InputText placeholder="http:// 를 포함하여 입력하세요." inputValue={homepage}
                                   onChange={setHomepage}/>
                    </InputBox>
                    <InputBox title="설명" textarea>
                        <InputTextarea placeholder="입력해주세요" contents={descContents} onChange={setDescContents}/>
                    </InputBox>
                    <InputBox title="주소">
                        <InputText placeholder="입력해주세요" inputValue={address} onChange={setAddress}/>
                    </InputBox>
                    <InputBox title="상세주소">
                        <InputText placeholder="입력해주세요" inputValue={addressDetail} onChange={setAddressDetail}/>
                    </InputBox>
                    <InputBox title="연락처">
                        <InputNumber subType="phone" placeholder="입력해주세요" inputValue={phone} onChange={setPhone}/>
                    </InputBox>
                    {/*<InputBox title="이메일">*/}
                    {/*    <InputEmail inputValue={email} onChange={setEmail}/>*/}
                    {/*</InputBox>*/}
                    {/*<InputBox title="오시는길" textarea>*/}
                    {/*    <InputTextarea placeholder="방문객이 쉽게 찾도록 위치에 대한 상세한 설명 및 교통편을 입력해주세요." contents={location}*/}
                    {/*                   onChange={setLocation}/>*/}
                    {/*</InputBox>*/}
                    {/*<InputBox title="주차정보" textarea>*/}
                    {/*    <InputTextarea placeholder="입력해주세요" contents={parking} onChange={setParking}/>*/}
                    {/*</InputBox>*/}
                    <InputBox title="입실시간">
                        <InputText placeholder="입력해주세요" inputValue={inDate} onChange={setInDate}/>
                    </InputBox>
                    <InputBox title="퇴실시간">
                        <InputText placeholder="입력해주세요" inputValue={outDate} onChange={setOutDate}/>
                    </InputBox>
                    <InputBox title="금액">
                        <InputText placeholder="입력해주세요" inputValue={price} onChange={setPrice}/>
                    </InputBox>
                    <InputBox title="할인금액">
                        <InputText placeholder="입력해주세요" inputValue={salePrice} onChange={setSalePrice}/>
                    </InputBox>
                </DetailContentBox>

                <DetailContentBox>
                    <div className="title">상세정보</div>
                    <WysiwygEditor contents={descDetail} onChange={setDescDetail}/>
                </DetailContentBox>

                {/*<DetailContentBox>*/}
                {/*    <div className="title">농장 체험정보</div>*/}
                {/*    <InputBox title="체험정보">*/}
                {/*        <InputText placeholder="입력시 농장 체험 분류로 적용됩니다." inputValue={farmInfo} onChange={setFarmInfo}/>*/}
                {/*    </InputBox>*/}
                {/*</DetailContentBox>*/}


                <DetailContentBox>
                    <div className="title">상태정보</div>
                    <InputBox title="상태" firstBox>
                        <InputSelect optionList={pensionStateList} onSelectChange={handleChangePensionState}
                                     value={pensionState}/>
                    </InputBox>
                    <InputBox title="사용여부" toggle>
                        <InputToggle toggleChecked={use} onChange={setUse}/>
                    </InputBox>
                    <InputBox title="상위 12개" toggle>
                        <InputToggle toggleChecked={useBest} onChange={setUseBest}/>
                    </InputBox>
                    <InputBox title="등록일">
                        <InputDate now/>
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="저장" color="blue" size="70%" onClick={saveData}/>
            <ColorButton title="삭제" color="bluejay" size="30%" onClick={deleteData}/>
        </div>
    );
};

CompanyDialog1.propTypes = {
    show: PropTypes.bool
};

export default CompanyDialog1;