import React, {useEffect, useMemo, useState} from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Accordion from "../components/widgets/Accordion";
import Select from "../components/inputs/Select";
import styled from "styled-components";


/* 페이징 */
import Divider from '@material-ui/core/Divider';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';

import InputText from "../components/inputs/InputText";
import MaterialSelect from "@material-ui/core/Select";
import Template3 from "../components/templates/Template3";
import ColorButton from "../components/button/ColorButton";

/*API*/
import {callApi, versionCheck} from "../api";
import {ItemList, ItemCardList} from "../components/list/ItemList";

const AccordionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


const tableHeaderValues = ['1차 분류', '2차 분류', '사업자', '업체명', '웹사이트', '주소', '연락처', '상태', '사용여부', '등록일'];
const tableBodyValues = [
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
    ['숙박', '펜션', '펜션나라', '마린테라스', '웹', '주소', '010-1111-1111', '승인', 'on', '2020-10-10'],
];

const List = () => {

    const [size, setSize] = useState(1);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(null);

    const secondCategory = [
        {id: 1, title: '10개씩 보기', value: 10},
        {id: 2, title: '20개씩 보기', value: 20},
        {id: 3, title: '50개씩 보기', value: 50},
        {id: 4, title: '100개씩 보기', value: 100},
        {id: 5, title: '200개씩 보기', value: 200}
    ];

    useEffect(() => {

        // console.log(versionCheck());
    }, []);

    return (
        <AccordionContainer>
            <Accordion title="검색결과" boxCount={1}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    marginBottom: 20
                }}>
                    <Select optionList={secondCategory} defaultValue={size} onChange={setSize}
                            style={{marginBottom: 0, minWidth: '40%', maxWidth: 160}}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontSize: 18
                    }}>
                        <KeyboardArrowLeftIcon onClick={() => setPage(page < 10 ? 1 : page - 10)}/>
                        <InputText inputValue={page} onChange={setPage}
                                   style={{fontSize: 18, width: 60, height: 60, textAlign: 'center'}}/> / 140
                        <KeyboardArrowRightIcon onClick={() => setPage(page + 10)}/>
                    </div>
                </div>
                <Divider/>
                    <ItemList tableHeaderValues={tableHeaderValues} tableBodyValues={tableBodyValues}/>
                    <ItemCardList tableHeaderValues={tableHeaderValues} tableBodyValues={tableBodyValues}/>
                <Divider/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'top',
                    marginBottom: 30,
                    marginTop: 30
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontSize: 18
                    }}>
                        <KeyboardArrowLeftIcon onClick={() => setPage(page < 10 ? 1 : page - 10)}/>
                        <InputText inputValue={page} onChange={setPage}
                                   style={{fontSize: 18, width: 60, height: 60, textAlign: 'center'}}/> / 140
                        <KeyboardArrowRightIcon onClick={() => setPage(page + 10)}/>
                    </div>
                </div>
                <ColorButtonWrap>
                    <ColorButton title="검색" size="70%" color="blue"/>
                    <ColorButton title="초기화" size="30%" color="bluejay"/>
                </ColorButtonWrap>
            </Accordion>
        </AccordionContainer>
    );
};


const ColorButtonWrap = styled.div`
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -21px;
    margin-bottom: -20px;
`;

export default List;