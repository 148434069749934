import React, {useEffect} from 'react';
import '../css/index.css';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {actionCreator as modalAction} from '../redux/modules/modal';
import CompanyDialog1 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog1';
import CompanyDialog2 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog2';
import CompanyDialog3 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog3';
import CompanyDialog4 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog4';
import FacilityServiceDialog1 from '../components/templates/namyang/CompanyManagement/FacilityServiceDialog1';
import GuideDialog1 from '../components/templates/namyang/CompanyManagement/GuideDialog1';
import MainClassification from '../components/templates/namyang/PlatformManagement/MainClassification';
import MainClassificationDialog1 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog1';
import MainClassificationDialog2 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog2';
import MainClassificationDialog3 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog3';
import Notice from '../components/templates/namyang/CommunicationManagement/Notice';
import Faq from '../components/templates/namyang/CommunicationManagement/Faq';
import Qna from '../components/templates/namyang/CommunicationManagement/Qna';
import RegAndChange from '../components/templates/namyang/CommunicationManagement/RegAndChange';
import Partnership from '../components/templates/namyang/CommunicationManagement/Partnership';
import Gallery from '../components/templates/namyang/CommunicationManagement/Gallery';
import NoticeDialog1 from '../components/templates/namyang/CommunicationManagement/NoticeDialog1';
import FaqDialog1 from '../components/templates/namyang/CommunicationManagement/FaqDialog1';
import FaqDialog2 from '../components/templates/namyang/CommunicationManagement/FaqDialog2';
import QnaDialog1 from '../components/templates/namyang/CommunicationManagement/QnaDialog1';
import QnaDialog2 from '../components/templates/namyang/CommunicationManagement/QnaDialog2';
import QnaDialog3 from '../components/templates/namyang/CommunicationManagement/QnaDialog3';
import RegAndChangeDialog1 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog1';
import RegAndChangeDialog2 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog2';
import RegAndChangeDialog3 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog3';
import PartnershipDialog1 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog1';
import PartnershipDialog2 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog2';
import PartnershipDialog3 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog3';
import GalleryDialog1 from '../components/templates/namyang/CommunicationManagement/GalleryDialog1';
import GalleryDialog2 from '../components/templates/namyang/CommunicationManagement/GalleryDialog2';
import Template1 from "../components/templates/Template1";
import Template2 from "../components/templates/Template2";
import Template3 from "../components/templates/Template3";
import Template4 from "../components/templates/Template4";
import Template5 from "../components/templates/Template5";
import Template6 from "../components/templates/Template6";
import Template7 from "../components/templates/Template7";
import Template8 from "../components/templates/Template8";
import Template9 from "../components/templates/Template9";
import Template10 from "../components/templates/Template10";
import Template11 from "../components/templates/Template11";
import Template12 from "../components/templates/Template12";
import Template13 from "../components/templates/Template13";
import BasicCodeDialog1 from "../components/templates/namyang/basicManagement/BasicCodeDialog1";
import CompanyInfo from "../components/templates/namyang/CompanyManagement/CompanyInfo";
import CompanyInfoDialog1 from "../components/templates/namyang/CompanyManagement/CompanyInfoDialog1";

const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
`;

const Test = () => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //      dispatch(modalAction.showModal({component: <CompanyDialog1 show />, show: true}));
    // }, []);

    return (
        <MainContainer>
            {/*<Template1/>*/}
            {/*<Template2/>*/}
            {/*<Template3/>*/}
            {/*<Template4/>*/}
            {/*<Template5/>*/}
            {/*<Template6/>*/}
            {/*<Template7 show/>*/}
            {/*<Template8/>*/}
            {/*<Template9/>*/}
            {/*<Template10/>*/}
            {/*<Template11/>*/}
            {/*<Template12/>*/}
            {/*<Template13/>*/}
            {/*<BasicCodeDialog1/>*/}
            {/*<CompanyInfo/>*/}
            <CompanyInfoDialog1 show/>
        </MainContainer>

    );
};

export default Test;