import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RightArrowIcon from "../icons/RightArrowIcon";

const StyledColorButton = styled.button`
    width: ${props => props.size || '100%'};
    height: 60px;
    font-size: 20px;
    color: #fff;
    background-color: ${props => props.color || '#fff'};
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`

const ColorButton = (props) => {
    const colorList = {
        'blue': '#16a0ff',
        'bluejay': '#0f5684'
    }

    return (
        <StyledColorButton size={props.size} color={colorList[props.color]} onClick={props.onClick}>
            {props.title} {props.arrowIcon ? <RightArrowIcon type="colorButton" /> : ''}
        </StyledColorButton>
    );
};

ColorButton.propTypes = {
    title: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'bluejay']),
    size: PropTypes.string,
    arrowIcon: PropTypes.bool,

};

export default ColorButton;

export const ColorButtonWrap = styled.div`
    margin-left: -20px;
    margin-right: -21px;
    margin-bottom: -20px;
    margin-top: 30px;
`;