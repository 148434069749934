import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
    display: none;
    width: 100%;
    padding: 20px 20px 50px;
    background-color: #fff;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    
    & > div {
        height: 100px;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
    }
    
    & .download-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 60px;
        background: #16a0ff;
        text-decoration: none;
        color: #fff;
        font-size: 18px;
    }
    
    &.is-file {
        display: block;
    }
`;

const File = (props) => {
    return (
        <StyledDiv className={props.isAttachedFile ? 'is-file' : ''}>
            <div>
                <div>{props.attachedFile.fileName}</div>
                <div>
                    <a href={props.attachedFile.fileUrl} download className="download-btn">다운로드</a>
                </div>
            </div>
        </StyledDiv>
    );
};

File.propTypes = {
    isAttachedFile: PropTypes.bool,
    attachedFile: PropTypes.object
}

export default File;