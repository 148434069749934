import React, {useEffect, useState} from 'react';
import Breadcrumb from '../menus/Breadcrumb';
import {Accordion, AccordionContainer} from '../widgets';
import {InputText, InputToggle} from '../inputs';
import {ColorButton, ColorButtonWrap} from '../button';
import Select from "../inputs/Select";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {callApi} from "../../api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {red} from "@material-ui/core/colors";
import styled from "styled-components";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import CompanyInfoDialog1 from "../templates/namyang/CompanyManagement/CompanyInfoDialog1";
import MemberView from "./MemberView";
import {loadingStatus} from "../../redux/modules/loading";

const breadcrumbData = [
    {
        title: "회원",
        link: ""
    },
    {
        title: "신청회원",
        link: ""
    }
];


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    }
}))(TableCell);


const MemberList = (props) => {

    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal, []);

    const showMemberAddForm = () => {
        dispatch(modalAction.addModal({
            id: 'memberinfo',
            title: '회원정보',
            component: <MemberView id="memberinfo" memberId={null}/>
        }));
    }

    const [list, setList] = useState([]);


    const [size, setSize] = useState(1);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const pageCategory = [
        {id: 1, title: '12개씩 보기', value: 12},
        {id: 2, title: '24개씩 보기', value: 24},
        {id: 3, title: '48개씩 보기', value: 48},
        {id: 4, title: '90개씩 보기', value: 90},
        {id: 5, title: '180개씩 보기', value: 180}
    ];

    const modifyMember = (id) => {
        dispatch(modalAction.addModal({
            id: 'memberinfo',
            title: '회원정보수정',
            component: <MemberView id="memberinfo" memberId={id}/>
        }));
    }


    useEffect(() => {

        const request = {
            page: page,
            size: pageCategory.find(cate => cate.id == size).value
        };

        if (modal.modal.length < 1) {
            dispatch(loadingStatus(true));
            callApi('/member', 'GET', request).then(function (res) {
                let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
                setList(res.list);
                setTotalPage(newTotalPage);
                dispatch(loadingStatus(false));
            }).catch((msg) => {
                console.log(msg);
                // alert(msg);
                dispatch(loadingStatus(false));
            });
        }
    }, [modal, size, page]);


    const pageSelect = (type) => {

        let newPage = 0;
        if (type == 'right') {
            newPage = page + 1;
        } else {
            newPage = page > 0 ? page - 1 : 1;
        }

        setPage(newPage);
    }

    const changeStatus = (id) => (e) => {

        const thisItem = list.find((item) => item.id === id);

        callApi('/member/'+id+'/status', 'POST', {status: thisItem.status === 1 ? 0 : 1}).then((res) => {
            const newList = list.map((item) => {
                return item.id === id ? {...item, status: item.status === 1 ? 0 : 1} : item;
            });
            setList(newList);
        }).catch((e) => {
            console.log(e);
        })


    }

    return (
        <div>
            <Breadcrumb data={breadcrumbData}/>

            <AccordionContainer>
                <Accordion title="검색결과" boxCount={1}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        marginBottom: 20
                    }}>
                        <Select optionList={pageCategory} defaultValue={size} onChange={setSize}
                                style={{marginBottom: 0, minWidth: '40%', maxWidth: 160}}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={setPage}
                                       style={{
                                           fontSize: 18,
                                           width: 60,
                                           height: 60,
                                           textAlign: 'center'
                                       }}/> / {totalPage}
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>
                    <Divider/>

                    <TableContainer style={{marginTop: 30, marginBottom: 30}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>No.</StyledTableCell>
                                    <StyledTableCell>아이디</StyledTableCell>
                                    <StyledTableCell>성명</StyledTableCell>
                                    <StyledTableCell>상태</StyledTableCell>
                                    <StyledTableCell>가입일</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((items, key) => {
                                    return (
                                        <TableRow key={'listItem_' + key}>
                                            <StyledTableCell align={'left'}>{items.id}</StyledTableCell>
                                            <StyledTableCell align={'left'}
                                                             onClick={() => modifyMember(items.id)}>{items.user_id}</StyledTableCell>
                                            <StyledTableCell align={'left'}
                                                             onClick={() => modifyMember(items.id)}>{items.user_name}</StyledTableCell>
                                            <StyledTableCell align={'left'}>
                                                <InputToggle
                                                    toggleChecked={items.status === 1 ? true : false}
                                                    onChange={changeStatus(items.id)}/>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align={'left'}>{moment(items.insert_dt).format('YYYY-MM-DD HH:mm:ss')}</StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Divider/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'top',
                        marginBottom: 30,
                        marginTop: 30
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={setPage}
                                       style={{
                                           fontSize: 18,
                                           width: 60,
                                           height: 60,
                                           textAlign: 'center'
                                       }}/> / {totalPage}
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>
                    <ColorButtonWrap>
                        <ColorButton title="다음페이지" size="70%" color="blue"/>
                        <ColorButton title="회원추가" size="30%" color="bluejay" onClick={showMemberAddForm}/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default MemberList;