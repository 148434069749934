import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {CSVLink} from 'react-csv';

class CSVDownload extends PureComponent {
    constructor(props) {
        super(props);
        this.exportBtn = React.createRef();
    }

    static propTypes = {
        filename: PropTypes.string,
        headers: PropTypes.array,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        data: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]).isRequired
    }

    static defaultProps = {
        children: null,
        filename: 'generatedBy_react-csv.csv'
    }

    componentDidMount() {
        if (
            this.exportBtn
            && this.exportBtn.current
            && this.exportBtn.current.link
            && this.exportBtn.current.link.click
            && typeof this.exportBtn.current.link.click == 'function'
        ) {
            this.exportBtn.current.link.click();
        }
    }

    render() {
        const {headers, data, filename, children} = this.props;

        return (
            <CSVLink
                headers={headers}
                data={data}
                filename={filename}
                ref={this.exportBtn}
            >
                {children}
            </CSVLink>
        );
    }
}

export default CSVDownload;