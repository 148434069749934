// actions
const SET_MODAL = "SET_MODAL";

// action creators
const setModal = (modal) => {
    return {
        type: SET_MODAL,
        modal
    };
};

// api actions
const addModal = (newModal) => {
    return (dispatch, getState) => {
        const {modal} = getState();
        let modals = [];
        if(modal.modal.length < 1) {
            modals.push(newModal);
        } else {
            let findModal = modal.modal.find((m) => m.id === newModal.id);
            modals = findModal ? modal.modal.map((m) => m.id === newModal.id ? newModal : m) : modal.modal.concat(newModal);
        }
        dispatch(setModal(modals));
    };
};

const delModal = () => {
    // console.log(modal);
    return (dispatch, getState) => {
        const {modal} = getState();
        let modals = []
        dispatch(setModal(modals.concat(modal.modal.filter((v, i) => (modal.modal.length - 1) !== i))));
    };
};

// Initial State

const initialState = {
    modal: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL:
            return applySetModal(state, action);
        default:
            return state;
    }
};

// Reducer Funcitons

const applySetModal = (state, action) => {
    const {modal} = action;

    return {
        ...state,
        modal
    };
};

// exports
const actionCreator = {
    addModal,
    delModal
};

export {actionCreator};

export default reducer;