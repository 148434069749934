import React from 'react';
import Cookies from "universal-cookie";

const ck = new Cookies();

const defaultOption = (option) => {
    const o = {path: '/'};
    return Object.keys(option).length === 0 || option.constructor !== Object ? o : {...o, ...option};
}

export const Cookie = {
    set: (key, value, option = {}) => {
        console.log(defaultOption(option));
        return ck.set(key, value, defaultOption(option));
    },
    get: (key) => {
        // console.log(key);
        return ck.get(key);
    },
    remove: (key, value, option = {}) => {
        return ck.remove(key, defaultOption(option));
    },
    getAll: (key) => {
        return ck.getAll();
    }
}