import React, {useEffect, useState} from 'react';
import {Skeleton} from '@material-ui/lab';
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import noImage from '../../assets/images/noimage.jpg';


const StyledSkeleton = styled(Skeleton)`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
`;

export const ImgLoad = (props) => {
    const [load, setLoad] = useState(false);

    const onLoad = (e) => {
        setLoad(true);
    }

    const defaultSrc = (event) => {
        event.target.src = noImage;
    }

    const imgRender = React.useMemo(() => {
        return (
            <img {...props}
                 style={{width: '100%', height: '100%', objectFit: 'cover'}}
                 onError={defaultSrc}
                 onLoad={onLoad}/>
        )
    }, [props.src]);

    useEffect(() => {
    }, []);

    // const imgRender = () => {
    //     return (
    //         <img {...props}
    //              style={{width: '100%', height: '100%', objectFit: 'cover'}}
    //              onLoad={onLoad}/>
    //     )
    // };

    return (
        <div style={{display: 'flex', flex: 1}}>
            <div style={{
                width: '100%',
                overflow: 'hidden',
            }}>
                {
                    !load && (
                        <StyledSkeleton variant="rect"/>
                    )
                }
                {imgRender}
            </div>
        </div>
    );
}