import React, {useEffect} from 'react';
import '../css/index.css';
import styled from "styled-components";

import BasicCode from '../components/templates/namyang/basicManagement/BasicCode';
import BasicCodeDialog1 from '../components/templates/namyang/basicManagement/BasicCodeDialog1';
import BasicCodeDialog2 from '../components/templates/namyang/basicManagement/BasicCodeDialog2';
import HeadOffice from '../components/templates/namyang/basicManagement/HeadOffice';
import Terms from '../components/templates/namyang/basicManagement/Terms';
import TermsDialog1 from '../components/templates/namyang/basicManagement/TermsDialog1';
import UserMenu from '../components/templates/namyang/basicManagement/UserMenu';
import UserMenuDialog1 from '../components/templates/namyang/basicManagement/UserMenuDialog1';
import UserMenuDialog2 from '../components/templates/namyang/basicManagement/UserMenuDialog2';
import BusinessInfo from '../components/templates/namyang/BusinessManagement/BusinessInfo';
import BusinessInfoDialog1 from '../components/templates/namyang/BusinessManagement/BusinessInfoDialog1';
import Category from '../components/templates/namyang/CompanyManagement/Category';
import CompanyInfo from '../components/templates/namyang/CompanyManagement/CompanyInfo';
import CompanyInfoDialog1 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog1';
import CompanyInfoDialog2 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog2';
import CompanyInfoDialog3 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog3';
import CompanyInfoDialog4 from '../components/templates/namyang/CompanyManagement/CompanyInfoDialog4';
import FacilityService from '../components/templates/namyang/CompanyManagement/FacilityService';
import FacilityServiceDialog1 from '../components/templates/namyang/CompanyManagement/FacilityServiceDialog1';
import Guide from '../components/templates/namyang/CompanyManagement/Guide';
import GuideDialog1 from '../components/templates/namyang/CompanyManagement/GuideDialog1';
import MainClassification from '../components/templates/namyang/PlatformManagement/MainClassification';
import MainClassificationDialog1 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog1';
import MainClassificationDialog2 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog2';
import MainClassificationDialog3 from '../components/templates/namyang/PlatformManagement/MainClassificationDialog3';
import Notice from '../components/templates/namyang/CommunicationManagement/Notice';
import Faq from '../components/templates/namyang/CommunicationManagement/Faq';
import Qna from '../components/templates/namyang/CommunicationManagement/Qna';
import RegAndChange from '../components/templates/namyang/CommunicationManagement/RegAndChange';
import Partnership from '../components/templates/namyang/CommunicationManagement/Partnership';
import Gallery from '../components/templates/namyang/CommunicationManagement/Gallery';
import NoticeDialog1 from '../components/templates/namyang/CommunicationManagement/NoticeDialog1';
import FaqDialog1 from '../components/templates/namyang/CommunicationManagement/FaqDialog1';
import FaqDialog2 from '../components/templates/namyang/CommunicationManagement/FaqDialog2';
import QnaDialog1 from '../components/templates/namyang/CommunicationManagement/QnaDialog1';
import QnaDialog2 from '../components/templates/namyang/CommunicationManagement/QnaDialog2';
import QnaDialog3 from '../components/templates/namyang/CommunicationManagement/QnaDialog3';
import RegAndChangeDialog1 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog1';
import RegAndChangeDialog2 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog2';
import RegAndChangeDialog3 from '../components/templates/namyang/CommunicationManagement/RegAndChangeDialog3';
import PartnershipDialog1 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog1';
import PartnershipDialog2 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog2';
import PartnershipDialog3 from '../components/templates/namyang/CommunicationManagement/PartnershipDialog3';
import GalleryDialog1 from '../components/templates/namyang/CommunicationManagement/GalleryDialog1';
import GalleryDialog2 from '../components/templates/namyang/CommunicationManagement/GalleryDialog2';

const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;

const Main = () => {
    // useEffect(() => {
    //     dispatch(modalAction.showModal({component: <MainClassificationDialog3 show/>, show: true}));
    // }, []);

    return (
        <MainContainer>
            {/*<BasicCode/>*/}
            {/*<BasicCodeDialog1 show/>*/}
            {/*<BasicCodeDialog2 show/>*/}
            {/*<HeadOffice show/>*/}
            {/*<Terms/>*/}
            {/*<TermsDialog1 show/>*/}
            {/*<UserMenu/>*/}
            {/*<UserMenuDialog1 show/>*/}
            {/*<UserMenuDialog2 show/>*/}
            {/*<BusinessInfo/>*/}
            {/*<BusinessInfoDialog1 show/>*/}
            {/*<Category/>*/}
            {/*<CompanyInfo/>*/}
            {/*<CompanyInfoDialog1 show/>*/}
            {/*<CompanyInfoDialog2 show/>*/}
            {/*<CompanyInfoDialog3 show/>*/}
            {/*<CompanyInfoDialog4 show/>*/}
            {/*<FacilityService/>*/}
            {/*<FacilityServiceDialog1 show/>*/}
            {/*<Guide/>*/}
            {/*<GuideDialog1 show/>*/}
            {/*<MainClassification/>*/}
            {/*<MainClassificationDialog1 show/>*/}
            {/*<MainClassificationDialog2 show/>*/}
            {/*<MainClassificationDialog3 show/>*/}
            {/*<Notice/>*/}
            {/*<Faq/>*/}
            {/*<Qna/>*/}
            {/*<RegAndChange/>*/}
            {/*<Partnership/>*/}
            {/*<Gallery/>*/}
            {/*<NoticeDialog1 show/>*/}
            {/*<FaqDialog1 show/>*/}
            {/*<FaqDialog2 show/>*/}
            {/*<QnaDialog1 show/>*/}
            {/*<QnaDialog2 show/>*/}
            {/*<QnaDialog3 show/>*/}
            {/*<RegAndChangeDialog1 show/>*/}
            {/*<RegAndChangeDialog2 show/>*/}
            {/*<RegAndChangeDialog3 show/>*/}
            {/*<PartnershipDialog1 show/>*/}
            {/*<PartnershipDialog2 show/>*/}
            {/*<PartnershipDialog3 show/>*/}
            {/*<GalleryDialog1 show/>*/}
            {/*<GalleryDialog2 show/>*/}
        </MainContainer>
    );
};

export default Main;