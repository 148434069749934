import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
    width: 100%;
    height: 60px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #000;
    font-size: 18px;
    user-select: none;
    padding: ${props => props.img ? "0 20px 0 100px" : "0 20px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    
    &.overlap-div {
        margin-top: -1px;
    }
`;

const StyledButton = styled.button`
    width: 40px;
    height: 40px;
    background: none;
    border-radius: 50%;
    border: 1px solid #dfdfdf;
    outline: none;
    cursor: pointer;
`;

const StyledImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid #dfdfdf;

    & img {
        width: 80px;
        height: 60px;
    }
`;

const TextBox = (props) => {

    let image;
    if (props.img) {
        image = <StyledImage><img src={props.img} alt={props.text} /></StyledImage>;
    } else {
        image = '';
    }

    return (
        <StyledDiv className={props.firstBox ? "" : "overlap-div"} img={props.img}>
            {image}
            <div>{props.text}</div>
            <StyledButton onClick={props.onClick}>
                <svg
                    width="9px" height="9px">
                    <image  x="0px" y="0px" width="9px" height="9px"  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJAgMAAACd/+6DAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACVBMVEU+R1w+R1z////3e847AAAAAXRSTlMAQObYZgAAAAFiS0dEAmYLfGQAAAAHdElNRQfkCRURNw3YhCMmAAAAIklEQVQI12MIYHRgCGF1YBAVZWBgDWFgYAyA0CA+SBwoDwBCpwOsfmLsqAAAAABJRU5ErkJggg==" />
                </svg>
            </StyledButton>
        </StyledDiv>
    );
};

TextBox.propTypes = {
    firstBox: PropTypes.bool,
    text: PropTypes.string,
    img: PropTypes.string
};

export default TextBox;

export const TextBoxContainer = styled.div`
    margin-bottom: 20px;
`;