import React, {useEffect, useState} from 'react';
import '../../css/index.css';
import styled from "styled-components";

import BasicCode from '../../components/templates/namyang/basicManagement/BasicCode';
import {callApi} from "../../api";

const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;

const BasicCodePage = () => {


    return (
        <MainContainer>
            <BasicCode/>
        </MainContainer>
    );
};

export default BasicCodePage;