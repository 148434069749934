import React from 'react';
import styled from 'styled-components';

const StyledDragText = styled.div`
    width: 100%;
    height: 56px;
    font-size: 16px;
    color: #666;
    
    & div {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
`;

const DragInfoText = () => {
    return (
        <StyledDragText>
            <div>※ 드래그로 순서를 변경할 수 있습니다.</div>
        </StyledDragText>
    );
}

export default DragInfoText;