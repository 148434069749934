import React, {useState} from 'react';
import {DetailContainer, DetailHeader, DetailBody, DetailContentBox} from '../../../detail';
import {InputBox, InputText, InputDate, InputEmail, InputNumber, InputSelect, InputTextarea} from '../../../inputs';
import {ColorButton} from '../../../button';
import styled from 'styled-components';
import {File} from '../../../Communication';

// 문의유형
const apiInquiryTypeList = [
    {
        id: 1,
        order: 1,
        value: "업체등록",
        title: "업체등록",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "업체수정",
        title: "업체수정",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "요금수정",
        title: "요금수정",
        isOn: true
    }
];

// 문의내용
const apiInquiryContents = "[필수정보]<br />- 업체명 : 마린테라스<br />- 수정금액 : 5,000원<br />- 문의 내용 : 수정 부탁드립니다.";

const EditableDiv = styled.div`
    width: 100%;
    height: 300px;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px;
    outline: none;
    font-size: 18px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    
    &.is-file-false {
        border-bottom: 1px solid #dfdfdf;
    }
`;

// 첨부파일
const apiAttachedFile = {
    fileName: '수정요청서.pdf',
    // fileName: '',
    fileUrl: '/images/test/pension_image.jpg'
};

// 질문과 답변 > 미답변
const QnaDialog1 = (props) => {
    const [show, setShow] = useState(props.show);
    const handleClick = () => {
        show ? setShow(false) : setShow(true);
    };

    // 문의유형
    const [inquiryType, setInquiryType] = useState("");
    const handleChangeInquiryType = (event) => {
        setInquiryType(event.target.value);
    };

    // 문의내용
    const [inquiryContents, setInquiryContents] = useState(apiInquiryContents);

    // 첨부파일
    const [attachedFile, setAttachedFile] = useState(apiAttachedFile);

    // 첨부파일 존재여부
    const isAttachedFile = attachedFile.fileName !== '' ? true : false;

    return (
        <DetailContainer className={show ? 'show' : ''}>
            <DetailHeader onClick={handleClick} title="질문과 답변" />
            <DetailBody paddingTop="20px">
                <DetailContentBox>
                    <InputBox title="답변 받을 메일" firstBox>
                        <InputEmail />
                    </InputBox>
                    <InputBox title="핸드폰 번호">
                        <InputNumber subType="phone" placeholder="입력해주세요" />
                    </InputBox>
                    <InputBox title="문의유형">
                        <InputSelect
                            optionList={apiInquiryTypeList}
                            value={inquiryType}
                            onSelectChange={handleChangeInquiryType}
                            placeholder="선택해주세요"
                        />
                    </InputBox>
                    <InputBox title="문의제목">
                        <InputText placeholder="입력해주세요" />
                    </InputBox>

                    <EditableDiv dangerouslySetInnerHTML={{__html: inquiryContents}} contentEditable={true} className={isAttachedFile ? '' : 'is-file-false'}></EditableDiv>

                    <File isAttachedFile={isAttachedFile} attachedFile={attachedFile} />

                    <InputBox title="등록일">
                        <InputDate now />
                    </InputBox>
                </DetailContentBox>
            </DetailBody>
            <ColorButton title="답변하기" color="blue" size="70%" />
            <ColorButton title="삭제" color="bluejay" size="30%" />
        </DetailContainer>
    );
};

export default QnaDialog1;