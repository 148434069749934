import React, {useEffect, useState} from 'react';
import '../../css/index.css';
import styled from "styled-components";
import BusinessInfo from "../../components/templates/namyang/BusinessManagement/BusinessInfo";
const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;

const Business = (props) => {

    return (
        <MainContainer>
            <BusinessInfo/>
        </MainContainer>
    );
};

export default Business;