import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AccordionBox = styled.div`
        position: relative;
        margin: 20px 10px;
        width: 100%;
        ${props => {
                if (props.boxCount === 2) {
                    return `
                        flex: 0 0 calc(50% - 20px);
                        max-width: calc(50% - 20px);
                        `; 
                } else if (props.boxCount === 1) {
                    return `
                        flex: 0 0 calc(100% - 20px);
                        max-width: calc(100% - 20px);
                        `;
                } else {
                    return `
                        flex: 0 0 calc(33.33% - 20px);
                        max-width: calc(33.33% - 20px);
                        `;
                }
            }
        };
        transition: 0.4s;
        
        @media (max-width: 1500px) {
            ${props => {
                    if (props.boxCount === 2) {
                        return `
                            flex: 0 0 calc(50% - 20px);
                            max-width: calc(50% - 20px);
                            `;
                    } else if (props.boxCount === 1) {
                        return `
                            flex: 0 0 calc(100% - 20px);
                            max-width: calc(100% - 20px);
                            `;
                    } else {
                        return `
                            flex: 0 0 calc(50% - 20px);
                            max-width: calc(50% - 20px);
                            `;
                    }
                }
            };
        }
        
        @media (max-width: 1016px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 20px 0;
        }
`;

const AccordionButton = styled.button`
        background-color: #7d8594;
        color: #fff;
        cursor: pointer;
        padding: 10px 0 10px 20px;
        width: 100%;
        height: 60px;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.3s;
        font-size: 20px;
        line-height: 20px;
        position: relative;
        display: flex;
        align-items: center;
        
        & .i-icon {
            font-family: "바탕" !important;
            font-size: 20px;
            color: #000;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            background-color: #fff;
            padding-left: 13px;
            padding-top: 6px;
            margin-left: 20px;
            box-shadow: 3px 1px 7px 0px rgba(0, 0, 0, 0.15);
        }
        
        & .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background-color: #3e475c;
            position: absolute;
            top: 0;
            right: 0;
        }
        
        & svg {
            display: none;
        }
        
        & svg.active {
            display: block;
        }   
        
        & > img {
            margin-left: 20px;
        }
`;

// api 호출시 부모가 다시 랜더될때 높이부분을 기존걸로 가져오는 오류 해결해야함.
const AccordionPanel = styled.div`
        background-color: #dce1e7;
        overflow: hidden;
        box-sizing: border-box;
        
        padding: 20px;
        // height: ${props => props.height}px;
        transition: height 0.4s, padding 0.4s;
        
        &.inactive {
            height: 0;
            padding: 0 20px;
        }
        
`;

const Accordion= (props) => {
    const [isToggleOn, setIsToggleOn] = useState(true);

    const handleClick = () => {
        setIsToggleOn(!isToggleOn);
    };

    const [accordionPanelHeight, setAccordionPanelHeight] = useState();

    const accordionPanel = useRef();
    useEffect(() => {
        setAccordionPanelHeight(accordionPanel.current.offsetHeight);
    }, []);

    return (
        <AccordionBox boxCount={props.boxCount}>
            <AccordionButton onClick={handleClick}>
                {props.title}

                {props.isHeadquarters ? <span className="i-icon">i</span> : ''}

                <span className="toggle">
                    <svg
                        width="16px" height="9px" className={isToggleOn ? '' : 'active'}>
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M16.002,7.623 L14.605,9.008 L8.000,2.462 L1.395,9.008 L-0.002,7.623 L7.686,0.003 L8.000,0.315 L8.314,0.003 L16.002,7.623 Z"/>
                    </svg>
                    <svg
                        width="17px" height="10px" className={isToggleOn ? 'active' : ''}>
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M16.659,2.062 L8.880,9.840 L8.562,9.522 L8.244,9.840 L0.466,2.062 L1.880,0.648 L8.562,7.330 L15.244,0.648 L16.659,2.062 Z"/>
                    </svg>
                </span>
            </AccordionButton>
            <AccordionPanel className={isToggleOn ? 'active' : 'inactive'} ref={accordionPanel} height={accordionPanelHeight}>
                {props.children}
            </AccordionPanel>
        </AccordionBox>
    );
};

Accordion.propTypes = {
    title: PropTypes.string,
    isHeadquarters: PropTypes.bool,
    boxCount: PropTypes.number
};

Accordion.defaultProps = {
    boxCount: 3
};

export default Accordion;

const SearchAccordionButton = styled.div`
        background-color: #7d8594;
        color: #fff;
        padding: 10px 0 10px 20px;
        width: 100%;
        height: 60px;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.3s;
        font-size: 20px;
        line-height: 20px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        & svg {
            display: none;
        }
        
        & svg.active {
            display: block;
        }   
        
        & > img {
            margin-left: 20px;
        }
        
        & .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background-color: #3e475c;
            position: absolute;
            top: 0;
            right: 0;
        }
`;

const ViewButton = styled.button`
    width: 30px;
    height: 30px;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    
    & svg:first-child {
        display: block;
    }
    
    &:hover svg:last-child {
        display: block;
    }
    
    &:hover svg:first-child {
        display: none;
    }
    
    &.active {
        & svg:first-child {
            display: none;
        }
    
        & svg:last-child {
        display: block;
        }
    }
`;

const ListViewButton = styled(ViewButton)`
    position: absolute;
    right: 135px;
`;

const IconViewButton = styled(ViewButton)`
    position: absolute;
    right: 75px;
`;

export const SearchAccordion = (props) => {
    const [isToggleOn, setIsToggleOn] = useState(true);

    const handleClick = () => {
        setIsToggleOn(!isToggleOn);
    };

    // View Type
    const [viewType, setViewType] = useState("list");

    const handleListViewClick = (event) => {
        event.stopPropagation();
        console.log('list');
        setViewType("list");
    };

    const handleIconViewClick = (event) => {
        event.stopPropagation();
        console.log('icon');
        setViewType("icon");
    };

    return (
        <AccordionBox boxCount={props.boxCount}>
            <SearchAccordionButton onClick={handleClick}>
                {props.title}

                <ListViewButton onClick={handleListViewClick} className={viewType === "list" ? "active" : ""}>
                    <svg
                        width="30px" height="30px">
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M-0.000,29.999 L-0.000,16.531 L30.000,16.531 L30.000,29.999 L-0.000,29.999 ZM-0.000,-0.001 L30.000,-0.001 L30.000,13.374 L-0.000,13.374 L-0.000,-0.001 Z"/>
                    </svg>

                    <svg
                        width="30px" height="30px">
                        <path fillRule="evenodd"  fill="rgb(22, 160, 255)"
                              d="M-0.000,30.000 L-0.000,16.531 L30.000,16.531 L30.000,30.000 L-0.000,30.000 ZM-0.000,-0.001 L30.000,-0.001 L30.000,13.375 L-0.000,13.375 L-0.000,-0.001 Z"/>
                    </svg>
                </ListViewButton>

                <IconViewButton onClick={handleIconViewClick} className={viewType === "icon" ? "active" : ""}>
                    <svg
                        width="31px" height="30px">
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M17.241,30.000 L17.241,16.531 L30.156,16.531 L30.156,30.000 L17.241,30.000 ZM17.241,-0.001 L30.156,-0.001 L30.156,13.375 L17.241,13.375 L17.241,-0.001 ZM-0.000,16.524 L12.937,16.524 L12.937,30.000 L-0.000,30.000 L-0.000,16.524 ZM-0.000,-0.001 L12.937,-0.001 L12.937,13.360 L-0.000,13.360 L-0.000,-0.001 Z"/>
                    </svg>

                    <svg
                        width="31px" height="30px">
                        <path fillRule="evenodd"  fill="rgb(22, 160, 255)"
                              d="M17.241,29.999 L17.241,16.531 L30.156,16.531 L30.156,29.999 L17.241,29.999 ZM17.241,-0.001 L30.156,-0.001 L30.156,13.374 L17.241,13.374 L17.241,-0.001 ZM-0.000,16.524 L12.937,16.524 L12.937,29.999 L-0.000,29.999 L-0.000,16.524 ZM-0.000,-0.001 L12.937,-0.001 L12.937,13.360 L-0.000,13.360 L-0.000,-0.001 Z"/>
                    </svg>
                </IconViewButton>

                <span className="toggle">
                    <svg
                        width="16px" height="9px" className={isToggleOn ? '' : 'active'}>
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M16.002,7.623 L14.605,9.008 L8.000,2.462 L1.395,9.008 L-0.002,7.623 L7.686,0.003 L8.000,0.315 L8.314,0.003 L16.002,7.623 Z"/>
                    </svg>
                    <svg
                        width="17px" height="10px" className={isToggleOn ? 'active' : ''}>
                        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                              d="M16.659,2.062 L8.880,9.840 L8.562,9.522 L8.244,9.840 L0.466,2.062 L1.880,0.648 L8.562,7.330 L15.244,0.648 L16.659,2.062 Z"/>
                    </svg>
                </span>
            </SearchAccordionButton>
            <AccordionPanel className={isToggleOn ? 'active' : ''}>
                {props.children}
            </AccordionPanel>
        </AccordionBox>
    );
};

export const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
