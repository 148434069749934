import styled from 'styled-components';

const DetailContentBox = styled.div`
    padding-bottom: 60px;

    & .title {
        font-size: 20px;
        margin-bottom: 30px;
        cursor: default;
    }
`

export default DetailContentBox;