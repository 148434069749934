// actions
const SET_USER = "SET_USER";
const SET_TOKEN = "SET_TOKEN";

// action creators

const setToken = (token) => {
    return {
        type: SET_TOKEN,
        token
    };
};

const setUser = (user) => {
    return {
        type: SET_USER,
        user
    };
};

// api actions
const getUser = (user) => {
    return (dispatch, getState) => {
        dispatch(setUser(user));
    };
};

const getToken = () => {
    return (dispatch, getState) => {
        // api 로 토큰 받아올 예정.
        // dispatch(setToken());
    };
};

// Initial State

const initialState = {
    token: null,
    user: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return applySetUser(state, action);
        case SET_TOKEN:
            return applySetToken(state, action);
        default:
            return state;
    }
};

// Reducer Funcitons

const applySetUser = (state, action) => {
    const {user} = action;

    return {
        ...state,
        user
    };
};

const applySetToken = (state, action) => {
    const {token} = action;

    return {
        ...state,
        token
    };
};

// exports
const actionCreator = {
    getUser
};

export {actionCreator};

export default reducer;