import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

const StyledRightArrowIcon = styled.svg`
    width: 11px;
    height: 20px;
    position: absolute;
    right: ${props => props.right + 'px'};
    margin-left: 0;
    pointer-events: none;
    
    ${props => props.type === 'colorButton' && css`
        position: relative;
        height: 18px;
        right: auto;
        margin-left: 20px;
    `}
`;

const RightArrowIcon = (props) => {
    const fillColor = props.type === 'colorButton' ? 'rgb(255, 255, 255)' : 'rgb(62, 71, 92)';

    return (
        <StyledRightArrowIcon type={props.type} right={props.right}>
            <path fillRule="evenodd"  fill={fillColor}
                  d="M10.153,8.568 L2.375,16.346 L0.961,14.932 L7.643,8.250 L0.961,1.568 L2.375,0.154 L10.153,7.932 L9.835,8.250 L10.153,8.568 Z"/>
        </StyledRightArrowIcon>
    );
};

RightArrowIcon.propTypes = {
    type: PropTypes.string,
    right: PropTypes.number
};

RightArrowIcon.defaultProps = {
    right: 23
};

export default RightArrowIcon;