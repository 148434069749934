import React from 'react';
import styled from 'styled-components';
import {EditorState, convertToRaw, Modifier} from "draft-js";
import {Editor} from 'react-draft-wysiwyg';

import {stateFromHTML} from "draft-js-import-html";
import sanitizeHtml from 'sanitize-html';


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorContainer = styled.div`
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    background-color: #fff;
    
    & .rdw-editor-toolbar {
      border-bottom: 1px solid #dfdfdf;
    }
    
    & .rdw-editor-main {
      padding: 0 20px;
      min-height: 300px;
      font-size: 18px;
    }
`;

const getSelectedBlock = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockStartKey = selection.getStartKey();

    return contentState.getBlockMap().get(blockStartKey);
}

const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
) => {
    try {


        const selectedBlock = getSelectedBlock(editorState);
        if (selectedBlock && selectedBlock.getType() === "code") {
            const contentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                text,
                editorState.getCurrentInlineStyle()
            );
            onChange(
                EditorState.push(editorState, contentState, "insert-characters")
            );
            return true;
        } else if (html) {
            // const fixedHTML = html.replace(/<img.*>/gi, "\n");
            let fixedHTML = sanitizeHtml(html)
                .replace(/(<\/?)img((?:\s+.*?)?>)/g, "")
                .replace(/(<\/?)figure((?:\s+.*?)?>)/g, "");
            const blockMap = stateFromHTML(fixedHTML).getBlockMap();
            const newState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap
            );
            onChange(EditorState.push(editorState, newState, "insert-fragment"));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};


const WysiwygEditor = (props) => {

    return (
        <EditorContainer>
            <Editor placeholder="내용을 입력해주세요" handlePastedText={handlePastedText}
                    editorState={props.contents ? props.contents : EditorState.createEmpty()}
                    onEditorStateChange={props.onChange}/>
        </EditorContainer>
    );
};

export default WysiwygEditor;