import React, {useEffect, useState} from 'react';
import '../../css/index.css';
import styled from "styled-components";
import Breadcrumb from "../../components/menus/Breadcrumb";
import {Accordion, AccordionContainer} from "../../components/widgets";
import {SortableCommunication} from "../../components/interactions/Sortable";
import {ColorButton, ColorButtonWrap} from "../../components/button";
import {actionCreator as modalAction} from "../../redux/modules/modal";
import NoticeDialog1 from "../../components/templates/namyang/CommunicationManagement/NoticeDialog1";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../components/inputs/Select";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {InputText} from "../../components/inputs";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {useParams} from "react-router";
import {callApi} from "../../api";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const MainContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
`;


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 18,
        border: 2,
        borderStyle: 'solid',
        borderColor: '#F0F0F0',
        verticalAlign: 'middle'
    }
}))(TableCell);

const Fdiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const tableHeaderValues = ['No', '제목', '작성자', '등록일', '조회수'];

const breadcrumbData = [
    {
        title: "게시판",
        link: ""
    },
    {
        title: "공지사항",
        link: ""
    }
];


// 질문과 답변
const apiQnaList = [
    {
        id: 1,
        order: 1,
        title: '통유리 밖에서 내부가 보이는지 궁금해요. 통유리 밖에서 내부가 보이는지 궁금해요',
        text: 'ㅁㄴㄹㅁㄴㅇㄹㅁㄴㅇㄹㅁㄴㄹ',
        isOn: true,
        date: '2020-09-10',
        answer: []
    },
];

const Notice = (props) => {

    const dispatch = useDispatch();

    const [size, setSize] = useState(1);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const [list, setList] = useState([]);

    const pageCategory = [
        {id: 1, title: '12개씩 보기', value: 12},
        {id: 2, title: '24개씩 보기', value: 24},
        {id: 3, title: '48개씩 보기', value: 48},
        {id: 4, title: '90개씩 보기', value: 90},
        {id: 5, title: '180개씩 보기', value: 180}
    ];

    const pageSelect = (type) => {

        let newPage = 0;
        if (type == 'right') {
            newPage = page + 1;
        } else {
            newPage = page > 0 ? page - 1 : 1;
        }

        setPage(newPage);
    }

    const showNoticeDialog = () => {
        dispatch(modalAction.addModal({
            id: 'board',
            title: '공지사항 작성',
            component: <NoticeDialog1 id="board" type="notice" boardId={null}/>
        }));
    }

    const showNoticeModifyDialog = (id) => {
        dispatch(modalAction.addModal({
            id: 'board',
            title: '공지사항 수정',
            component: <NoticeDialog1 id="board" type="notice" boardId={id}/>
        }));
    }

    const modal = useSelector(state => state.modal, []);

    useEffect(() => {

        const request = {
            page: page,
            size: pageCategory.find(cate => cate.id == size).value
        };

        if (modal.modal.length < 1) {
            callApi('/board/notice', 'GET', request).then((res) => {

                if (res.success) {
                    let newTotalPage = Math.ceil(res.totalCount / pageCategory.find(cate => cate.id == size).value);
                    setList(res.list);
                    setTotalPage(newTotalPage)
                }
            }).catch((msg) => {
                console.log(msg);
            });
        }

    }, [modal, size, page]);


    return (
        <div>
            <Breadcrumb data={breadcrumbData}/>
            <AccordionContainer>
                <Accordion title="공지사항" boxCount={1}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        marginBottom: 20
                    }}>
                        <Select optionList={pageCategory} defaultValue={size} onChange={setSize}
                                style={{marginBottom: 0, minWidth: '40%', maxWidth: 160}}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={setPage}
                                       style={{
                                           fontSize: 18,
                                           width: 60,
                                           height: 60,
                                           textAlign: 'center'
                                       }}/> / {totalPage}
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>

                    <TableContainer style={{marginTop: 30, marginBottom: 30}}>
                        <Table>
                            <colgroup>
                                <col width="8%"/>
                                <col width="*"/>
                                <col width="15%"/>
                                <col width="20%"/>
                                <col width="10%"/>
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {tableHeaderValues.map((item, key) => {
                                        return (
                                            <StyledTableCell key={key}>
                                                <Fdiv>
                                                    {item}
                                                    {/*<UpDown/>*/}
                                                </Fdiv>
                                            </StyledTableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((items, key) => {
                                    return (
                                        <TableRow key={'listItem_' + key} onClick={() => showNoticeModifyDialog(items.id)}>
                                            <StyledTableCell align={'center'}>{items.number}</StyledTableCell>
                                            <StyledTableCell align={'left'}>{items.title}</StyledTableCell>
                                            <StyledTableCell align={'center'}>{items.writer}</StyledTableCell>
                                            <StyledTableCell align={'center'}>{items.date}</StyledTableCell>
                                            <StyledTableCell align={'center'}>{items.hit}</StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*<SortableCommunication itemList={list}/>*/}

                    <ColorButtonWrap>
                        <ColorButton title="다음페이지" size="70%" color="blue"/>
                        <ColorButton title="작성" size="30%" color="bluejay" onClick={showNoticeDialog}/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Notice;