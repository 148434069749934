import ReactLoading from "react-loading";
import React, {useEffect, useContext, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

export const Loading = () => {

    const {loading} = useSelector(store => store.Loading);


    return loading && (
        <div style={{
            position: 'fixed',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            zIndex: '1300'
        }}>
            <div style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap'
            }}>
                <ReactLoading type='spin' color="#000"/>
            </div>
            <div style={{
                position: 'absolute',
                backgroundColor: '#FFF',
                opacity: 0.5,
                width: '100%',
                height: '100%',
            }}>
            </div>
        </div>
    );
}