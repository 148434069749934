import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HeadquartersTitle from "../texts/HeadquartersTitle";

const SearchResultContainer = styled.div`
    height: 60px;
    background-color: ${props => props.isChecked ? "#fff" : "#efefef"};
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    
    & .search-result-checkbox-wrap {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        pointer-events: none;
        
        label {
            width: 30px;
            height: 30px;
        }
        
        input {
            display: none;
        }
    }
    
    & .search-result-thumbnail {
        height: 58px;
        overflow: hidden;
        user-select: none;
        
        img {
            width: 80px;
            height: 60px;
        }
    }
    
    & .search-result-title {
        width: 100%;
        height: 100%;
        padding-left: ${props => props.img ? "20px" : "0"};
        display: flex;
        align-items: center;
        user-select: none;
    }
        
    margin-top: ${props => props.firstBox ? "" : "-1px"};
`;

const SearchResult = (props) => {

    let checkboxIcon = "";
    if (props.isChecked) {
        checkboxIcon = <svg
            width="30px" height="30px">
            <image  x="0px" y="0px" width="30px" height="30px"  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAQMAAAAB/jzhAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABlBMVEUWoP/////edOc8AAAAAWJLR0QB/wIt3gAAAAd0SU1FB+QJGQ82B30kyrsAAAA3SURBVAjXY2AgGrCBCH4QIQ8i7EBEDQMDY8MPBgbmgx+AxOMHQO73A0Dx/w1Aoh6uTIZ4W8AAANPhCfLobdHDAAAAAElFTkSuQmCC" />
        </svg>;
    }

    let img = "";
    if (props.img) {
        img = <img src={props.img} alt={props.title} />;
    }

    return (
        <SearchResultContainer firstBox={props.firstBox} isChecked={props.isChecked} img={props.img} onClick={props.onClick}>
            <div className="search-result-checkbox-wrap">
                <label>
                    {checkboxIcon}
                    {/*<input type="checkbox" />*/}
                </label>
            </div>
            <div className="search-result-thumbnail">
                {img}
            </div>
            <div className="search-result-title">
                {props.isHeadquarters ? <HeadquartersTitle /> : ""}
                {props.title}
            </div>
        </SearchResultContainer>
    );
};

SearchResult.propTypes = {
    firstBox: PropTypes.bool,
    isChecked: PropTypes.bool,
    title: PropTypes.string,
    img: PropTypes.string,
    isHeadquarters: PropTypes.bool
};

export default SearchResult;