import React from 'react';
import styled from 'styled-components';
import LeftArrowIcon from "../icons/LeftArrowIcon";

const StyledButton = styled.button`
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
`

const StyledDiv = styled.div`
      width: 100%;
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #dfdfdf;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      
      ${StyledButton} {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
      }
`;

const DetailHeader = (props) => {
    return (
        <StyledDiv>
            <StyledButton onClick={props.onClick}>
                <LeftArrowIcon />
            </StyledButton>
            {props.title}
        </StyledDiv>
    );
};

export default DetailHeader;