import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import RightArrowIcon from "../icons/RightArrowIcon";
import PropTypes from 'prop-types';
import HeadquartersTitle from '../texts/HeadquartersTitle';

const StyledLinkButton = styled(Button)`
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 60px;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    font-size: 18px;
    justify-content: flex-start;
    padding-left: 20px;
    margin-bottom: -1px;
    text-transform: none;
    
    &:hover {
        background-color: #f2f2f2;
    }
    
    &.off {
        color: #aaa;
    }
    
    & > span > img {
        margin-left: -20px;
        margin-right: 20px;
    }
    
    &.sortstart {
      border: 2px solid deepskyblue;
      background: rgba(135, 206, 250, 0.8);
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      cursor: grabbing;
      pointer-events: auto !important;
    }
`;

const LinkTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
`;

const LinkButton = (props) => {
    const [isThumbnail, setIsThumbnail] = useState(false);

    useEffect(() => {
        if (props.thumbnail != null) {
            setIsThumbnail(true);
        }
    }, [props.thumbnail]);

    const Thumbnail = () => {
        return <img src={props.thumbnail} alt={props.title} />;
    };

    const handleClick = () => {
        console.log('click!');
    }

    return (
        <StyledLinkButton onClick={props.handleClick} disableRipple className={props.isOn ? '' : 'off'}>
            {isThumbnail ? <Thumbnail /> : ''}
            {props.isHeadquarters ? <HeadquartersTitle /> : ''}
            <LinkTitle>{props.title}</LinkTitle> <RightArrowIcon />
        </StyledLinkButton>
    );
};

LinkButton.propTypes = {
    title: PropTypes.string,
    isOn: PropTypes.bool,
    thumbnail: PropTypes.string,
    isHeadquarters: PropTypes.bool,
    handleClick: PropTypes.func,
};

export default LinkButton;