import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../menus/Breadcrumb';
import Accordion from '../widgets/Accordion';
import AddButton from '../button/AddButton';
import DragInfoText from '../texts/DragInfoText';
import Sortable from '../interactions/Sortable';

const breadcrumbData = [
    {
        title: "기본관리",
        link: ""
    },
    {
        title: "보관함",
        link: ""
    },
    {
        title: "휴일",
        link: ""
    }
];

const AccordionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const firstCategory = [
    {
        id: 1,
        order: 1,
        title: "2020.08.15 : 광복절",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 2,
        order: 2,
        title: "2020.08.17 : 임시공휴일",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 3,
        order: 3,
        title: "2020.09.30 : 추석",
        isOn: true,
        thumbnail: null,
        isHeadquarters: true
    },
    {
        id: 4,
        order: 4,
        title: "2020.10.31 : 추석",
        isOn: false,
        thumbnail: null,
        isHeadquarters: true
    }
];

// 보관함 > 휴일
const Template11 = () => {
    return (
        <div>
            <Breadcrumb data={breadcrumbData} />
            <AccordionContainer>
                <Accordion title="휴일설정" isHeadquarters boxCount={1}>
                    <AddButton title="휴일추가" />
                    <Sortable itemList={firstCategory} />
                    <DragInfoText />
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default Template11;