import React, {useState} from 'react';
import Breadcrumb from '../../../menus/Breadcrumb';
import styled from 'styled-components';
import {
    InputBox,
    InputSelect,
    InputText,
    InputNumber,
    TextLink,
    InputBoxContainer,
    InputBoxWrap
} from '../../../inputs';
import {ColorButton, ColorButtonWrap} from '../../../button';
import {Accordion, AccordionContainer} from '../../../widgets';
import Select from "../../../inputs/Select";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Divider from "@material-ui/core/Divider";

import {actionCreator as modalAction} from "../../../../redux/modules/modal";
import {ItemCardList, ItemList} from "../../../list/ItemList";
import {callApi} from "../../../../api";
import BusinessInfoDialog1 from "./BusinessInfoDialog1";
import Template2 from "../../Template2";
import {useDispatch} from "react-redux";

const breadcrumbData = [
    {
        title: "사업자 관리",
        link: ""
    },
    {
        title: "사업자 정보",
        link: ""
    }
];

// 비즈니스 상태
const businessStateList = [
    {
        id: 1,
        order: 1,
        value: "선택",
        title: "선택",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "대기",
        title: "대기",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "승인",
        title: "승인",
        isOn: true
    },
    {
        id: 4,
        order: 4,
        value: "보류",
        title: "보류",
        isOn: true
    }
];

// 사용여부
const onOffList = [
    {
        id: 1,
        order: 1,
        value: "전체",
        title: "전체",
        isOn: true
    },
    {
        id: 2,
        order: 2,
        value: "on",
        title: "on",
        isOn: true
    },
    {
        id: 3,
        order: 3,
        value: "off",
        title: "off",
        isOn: true
    }
];


const tableHeaderValues = ['1차 분류', '2차 분류', '사업자', '업체명', '웹사이트', '주소', '연락처', '상태', '사용여부', '등록일'];
const tableBodyValues = {
    totalCount: 1,
    page: 1,
    pageSize: 2,
    list: [
        {
            id: 1,
            status: '승인',
            isOpen: 1,
            type1: '숙박',
            type2: '펜션',
            channel: '펜션나라',
            businessName: '마린테라스',
            address: '경기도 안산시 고잔동 123',
            phone: '010-1111-2222',
            tel: '02-1111-2222',
            homepage: 'http://pensionnara.co.kr',
            mainImages: {
                thumb: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/file/cat.jpg',
                image: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/cat.jpg',
            },
            images: [
                {
                    thumb: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/file/cat.jpg',
                    image: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/cat.jpg'
                },
                {
                    thumb: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/file/dog.jpg',
                    image: 'http://ec2-13-125-245-241.ap-northeast-2.compute.amazonaws.com/v1/dog.jpg'
                }
            ],
            insertDt: '2020-10-10',
        }
    ]
};

const BusinessInfo = () => {
    // 비즈니스 상태
    const [businessState, setBusinessState] = useState("선택");
    const handleChangeBusinessState = (event) => {
        setBusinessState(event.target.value);
    };

    // 사용여부
    const [onOff, setOnOff] = useState("전체");
    const handleChangeOnOff = (event) => {
        setOnOff(event.target.value);
    };

    const [search, setSearch] = useState({
        companyName:'',
        // companyName:'',
    })

    const dispatch = useDispatch();

    const businessAdd = (data) => {
        console.log(data);
    }

    const showBusinessInfoDialog = () => {
        dispatch(modalAction.addModal({id: 'businessInfo1', title:'업체추가', component: <BusinessInfoDialog1 onClick={businessAdd}/>}));
    }


    const [size, setSize] = useState(1);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(null);

    const pageCategory = [
        {id: 1, title: '10개씩 보기', value: 10},
        {id: 2, title: '20개씩 보기', value: 20},
        {id: 3, title: '50개씩 보기', value: 50},
        {id: 4, title: '100개씩 보기', value: 100},
        {id: 5, title: '200개씩 보기', value: 200}
    ];

    const pageSelect = (type) => {

        let newPage = page;
        if (type == 'right') {
            newPage = page + 1;
        } else {
            newPage = page > 0 ? page - 1 : 1;
        }

        let params = {
            'page': newPage,
            'size': size,
            'order': order,
        }

        callApi('/business', 'GET', params, '').then((res) => {

        });

        setPage(page + 10);
    }


    return (
        <div>
            <Breadcrumb data={breadcrumbData}/>
            <AccordionContainer>
                <Accordion title="검색" boxCount={1}>
                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="상호(법인명)">
                                <InputText placeholder="입력해주세요"/>
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="사업자번호">
                                <InputNumber subType="businessNumber"/>
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer>
                        <InputBoxWrap>
                            <InputBox title="상태">
                                <InputSelect optionList={businessStateList} value={businessState}
                                             onSelectChange={handleChangeBusinessState}/>
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                            <InputBox title="사용여부">
                                <InputSelect optionList={onOffList} value={onOff} onSelectChange={handleChangeOnOff}/>
                            </InputBox>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <InputBoxContainer style={{marginBottom: "50px"}}>
                        <InputBoxWrap>
                            <InputBox title="등록일">
                                <TextLink text="20.06.09 - 20.06.10"/>
                            </InputBox>
                        </InputBoxWrap>

                        <InputBoxWrap>
                        </InputBoxWrap>
                    </InputBoxContainer>

                    <ColorButtonWrap>
                        <ColorButton title="검색" size="70%" color="blue"/>
                        <ColorButton title="초기화" size="30%" color="bluejay"/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>

            <AccordionContainer>
                <Accordion title="검색결과" boxCount={1}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        marginBottom: 20
                    }}>
                        <Select optionList={pageCategory} defaultValue={size} onChange={setSize}
                                style={{marginBottom: 0, minWidth: '40%', maxWidth: 160}}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => pageSelect('left')}/>
                            <InputText inputValue={page} onChange={setPage}
                                       style={{fontSize: 18, width: 60, height: 60, textAlign: 'center'}}/> / 140
                            <KeyboardArrowRightIcon onClick={() => pageSelect('right')}/>
                        </div>
                    </div>
                    <Divider/>
                    <ItemList tableHeaderValues={tableHeaderValues} tableBodyValues={tableBodyValues.list}/>
                    <ItemCardList tableHeaderValues={tableHeaderValues} tableBodyValues={tableBodyValues.list}/>
                    <Divider/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'top',
                        marginBottom: 30,
                        marginTop: 30
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 18
                        }}>
                            <KeyboardArrowLeftIcon onClick={() => setPage(page < 10 ? 1 : page - 10)}/>
                            <InputText inputValue={page} onChange={setPage}
                                       style={{fontSize: 18, width: 60, height: 60, textAlign: 'center'}}/> / 140
                            <KeyboardArrowRightIcon onClick={() => setPage(page + 10)}/>
                        </div>
                    </div>
                    <ColorButtonWrap>
                        <ColorButton title="다음페이지" size="70%" color="blue"/>
                        <ColorButton title="업체추가" size="30%" color="bluejay" onClick={showBusinessInfoDialog}/>
                    </ColorButtonWrap>
                </Accordion>
            </AccordionContainer>
        </div>
    );
};

export default BusinessInfo;
