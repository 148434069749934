import React from 'react';

const LeftArrowIcon = () => {
    return (
        <svg
            width="17px" height="30px">
            <path fillRule="evenodd"  fill="rgb(62, 71, 92)"
                  d="M16.985,27.864 L14.864,29.985 L0.015,15.136 L0.151,15.000 L0.015,14.864 L14.864,0.015 L16.985,2.136 L4.121,15.000 L16.985,27.864 Z"/>
        </svg>
    );
}

export default LeftArrowIcon;