import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTextarea = styled.textarea`
   width: 100%;
   height: 100%;
   font-size: 18px;
   resize: none;
   border: none;
   outline: none;
`

const InputTextarea = (props) => {

    const onChangeValue = e => {
        props.onChange(e.target.value);
    };

    return (
        <StyledTextarea placeholder={props.placeholder} value={props.contents} onChange={onChangeValue} />
    );
};

// InputTextarea.propTypes = {
//     placeholder: PropTypes.string,
//     contents: PropTypes.string
// };

// InputTextarea.defaultProps = {
//     placeholder: "입력하세요"
// };

export default InputTextarea;